import { CircularProgress, Grid, TableCell, TableRow } from '@mui/material'
import React from 'react'

const CircularLoader = ({ loaderBackgroundColor, loaderColor, height = "100%", variant = "div", rows, cols, color = "primary", ...rest }) => {

    if (variant === "table") {
        return (
            <TableRow rowSpan={rows} sx={{ height: `${rows * 20}px` }}>
                <TableCell sx={{ padding: "12px 16px;" }} colSpan={cols}>
                    <CircularProgress sx={{ display: "block", margin: "auto", color: loaderColor }} color={color} {...rest} />
                </TableCell>
            </TableRow>
        )
    } else {
        return (<Grid style={{ backgroundColor: loaderBackgroundColor, height: height, display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
            <CircularProgress sx={{ color: loaderColor }} color={color} {...rest} />
        </Grid>)
    }
}

export default CircularLoader;
