import { formDataToObject } from "../utils";

const CryptoJS = require("crypto-js");
const secretKey = process.env.REACT_APP_CRYPTO_JS_SECRET_KEY;

export function encryptData(data) {
    try {
        const jsonData = JSON.stringify(data);
        const encryptedData = CryptoJS.AES.encrypt(jsonData, secretKey).toString();
        return encryptedData;
    } catch (error) {
        console.error(error)
        return null
    }
}
export function decryptData(encryptedData, isJson = true) {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
        if (isJson) {
            const originalData = (JSON.parse(decryptedData));
            return originalData;
        }
        else {
            return decryptedData;
        }
    } catch (error) {
        console.error(error)
        return null
    }
}

export const getEncryptedFormData = (data) => {
    const _jsonFormData = formDataToObject(data);
    const formData = encryptData(_jsonFormData);
    return formData;
}

export const getEncryptedObject = (data) => {
    const formData = encryptData(data);
    return formData;
}