/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { Grid, Typography, Modal, Box } from "@mui/material";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { base64ToBlob, convertToBase64, generateUniqueId, titleCase } from "../../utils";
import { useAlert } from "../../hook/useAlert";
import PDFViewer from "../PDFViewer";
import ICONS from "../../constants/icons";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  padding: "16px 16px 6px",
  backgroundColor: "#F7F7F7",
  borderRadius: 3,
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  flex: "0 0 55px",
  maxWidth: 55,
  height: 55,
  margin: " 0 auto 10px",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
  "WebkitUserDrag": "none",
};

const focusedStyle = {
  borderColor: " #808080"
};

const acceptStyle = {
  borderColor: "#517c58"
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const FileDropZone = ({
  onDrop,
  initialFiles = [],
  fileURLs = [],
  readOnly = false,
  maxFiles = 5,
  maxTotalSize = 10 * 1000000,
  onDeleteImage,
  isConvertToBase64 = false
}) => {
  const showAlert = useAlert();
  const maxFileSize = 5 * 1000000;
  const [acceptedFilesList, setAcceptedFilesList] = useState(initialFiles || []);
  const [previewFile, setPreviewFile] = useState(null);

  useEffect(() => {
    if (!!fileURLs?.length) {
      const convertToFile = fileURLs?.filter(value => !!value)?.map((url) => ({
        name: url?.substring(url.lastIndexOf("/") + 1),
        preview: url,
        unique_id: generateUniqueId(),
      }));

      setAcceptedFilesList((prevFiles) => [...prevFiles, ...convertToFile]);
    }
  }, [fileURLs]);

  useEffect(() => {
    return () => acceptedFilesList?.forEach((file) => URL.revokeObjectURL(file?.preview));
  }, []);

  const fileSizeValidator = (file, type = "file") => {
    const allowedFormats = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
    if (type === "validator") {
      if (!allowedFormats.includes(file.type)) {
        showAlert("Invalid file format. Allowed formats are JPEG, JPG, PNG, and PDF.", "error");
        return {
          code: "invalid-format",
          message: "Invalid file format.",
        };
      }
    } else {
      const allowedFormats = [".jpeg", ".jpg", ".png", ".pdf"];
      const isValidFormat = allowedFormats.some(format => file.name?.toLowerCase()?.endsWith(format));
      if (!isValidFormat) {
        showAlert("Invalid file format. Allowed formats are JPEG, JPG, PNG, and PDF.", "error");
        return {
          code: "invalid-format",
          message: "Invalid file format.",
        };
      }
      if (file?.size > maxFileSize) {
        showAlert("File size exceeds 5MB limit.", "error");
        return {
          code: "size-too-large",
          message: "File size exceeds 5MB limit.",
        };
      }
    }
    return null;
  };

  const totalSizeValidator = (newFiles) => {
    const totalSize = acceptedFilesList?.reduce((acc, file) => acc + file.size, 0);
    const newTotalSize = newFiles?.reduce((acc, file) => acc + file.size, 0);
    return totalSize + newTotalSize > maxTotalSize;
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject, isFocused } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".pdf"],
    },
    onDrop: async (newlyAddedFiles) => {
      const invalidFiles = newlyAddedFiles
        .map(file => fileSizeValidator(file))
        .filter(error => error !== null);

      if (invalidFiles.length > 0) return;

      if (totalSizeValidator(newlyAddedFiles)) {
        showAlert("Total size exceeds the limit. Max 10MB allowed.", "error");
        return;
      }

      const updatedFiles = [];

      for (const file of newlyAddedFiles) {
        if (isConvertToBase64) {
          let base64 = null;
          if (file instanceof Blob || file instanceof File) {
            base64 = await convertToBase64(file);
          } else {
            base64 = file?.base64;
          }
          let fileObject = {
            name: file?.name,
            size: file?.size,
            type: file?.type,
            preview: URL.createObjectURL(file),
            unique_id: generateUniqueId(),
            base64,
          };
          updatedFiles.push(fileObject);
        } else {
          updatedFiles.push(Object.assign(file, {
            preview: URL.createObjectURL(file),
            unique_id: generateUniqueId(),
          }));
        }
      }
      const limitedFiles = [...acceptedFilesList, ...updatedFiles]?.filter((item) => item && (item !== null || item !== "null"))?.slice(0, maxFiles);
      setAcceptedFilesList(limitedFiles);
      onDrop(limitedFiles);
    },
    validator: (file) => fileSizeValidator(file, "validator"),
    maxFiles,
  });

  const handleDeleteFile = (file) => {
    const updatedFiles = acceptedFilesList?.filter((item) => item.unique_id !== file.unique_id)?.filter((item) => item && (item !== null || item !== "null"));
    setAcceptedFilesList(updatedFiles);
    onDrop(updatedFiles);
    if (!!fileURLs?.length) {
      onDeleteImage(file);
    }
  };

  const openPreview = (file) => {
    if (readOnly) {
      setPreviewFile(file);
    }
  };

  const closePreview = () => {
    setPreviewFile(null);
  };

  const thumbs = acceptedFilesList?.map((file, index) => (
    <Box
      key={index}
      sx={{
        display: "inline-flex",
        flexDirection: "column",
        borderRadius: 2,
        backgroundColor: "#ffffff",
        marginBottom: "10px",
        marginRight: "10px",
        width: "112px",
        padding: "15px 15px !important",
        position: "relative",
        "&:hover": {
          ".MuiIconButton-colorError": {
            opacity: "1",
          },
        },
      }}
    >
      <div style={thumbInner}>
        {file?.name?.toLowerCase()?.includes(".pdf") ?
          <Box
            style={{
              ...img, cursor: readOnly ? "pointer" : null,
            }}
            onClick={() => openPreview(file)}>
            {ICONS.PDFIcon}
          </Box>
          :
          <img
            alt="attachment"
            src={file?.base64 ? URL.createObjectURL(base64ToBlob(file?.base64, file?.type)) : file?.preview}
            style={{
              ...img, cursor: readOnly ? "pointer" : null,
            }}
            onClick={() => openPreview(file)}
          />}
      </div>
      {!readOnly ? <IconButton
        aria-label="delete"
        color="error"
        p={0}
        sx={{
          borderRadius: "0px",
          position: "absolute",
          top: 1,
          right: 1,
          width: "20px",
          height: "20px",
          opacity: { xs: 1, lg: '0' },
          padding: "0px !important",
        }}
        onClick={() => handleDeleteFile(file)}
      >
        <DeleteIcon sx={{ fontSize: "20px" }} />
      </IconButton> : null}
      <Typography
        color="dark.800"
        fontSize={12}
        fontWeight={500}
        sx={{
          lineHeight: "16px",
          letterSpacing: "0.17",
          width: "100%",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {titleCase(file?.name)}
      </Typography>
    </Box>
  ));



  const style = useMemo(() => {
    const borderColor = isFocused ? focusedStyle.borderColor : isDragAccept ? acceptStyle.borderColor : isDragReject ? rejectStyle.borderColor : "rgba(81, 95, 124, 0.25)";
    
    return {
      border: `1px dashed ${borderColor}`,
      ...(isFocused || isDragAccept || isDragReject ? {} : {
        borderColor: "rgba(81, 95, 124, 0.25)"
      }),
      cursor: "pointer",
      padding: "10px 8px",
      borderRadius: "4px",
      "&:hover": {
        borderColor: "primary.main",
      },
    };
  }, [isFocused, isDragAccept, isDragReject]);  

  return (
    <Box>
      {!readOnly && (
        <Grid
          item
          {...getRootProps({ className: "dropzone" })}
          style={style}
          display="flex"
          alignItems="center"
        >
          <input {...getInputProps()} />
          <i
            style={{
              height: "40px",
              maxWidth: "40px",
              flex: "0 0 40px",
              padding: "10px",
            }}
          >
            <UploadFileOutlinedIcon color="primary" sx={{ fontSize: "20px" }} />
          </i>
          <Box ml={1}>
            <Typography
              color="primary"
              fontSize={14}
              fontWeight={400}
              mr={0.5}
              sx={{
                display: "inline-block",
                lineHeight: "24px",
                letterSpacing: "0.15px",
                textDecoration: "underline",
              }}
            >
              Click to upload
            </Typography>
            <Typography
              color="dark.800"
              fontSize={14}
              fontWeight={400}
              sx={{
                display: "inline-block",
                lineHeight: "24px",
                letterSpacing: "0.15px",
              }}
            >
              or drag and drop
            </Typography>
            <Typography
              color="dark.800"
              fontSize={11}
              fontWeight={400}
              mt={0.5}
              sx={{
                display: "block",
                lineHeight: "15px",
                letterSpacing: "0.17px",
              }}
            >
              PDF, PNG, JPG (max. 5MB per file)
            </Typography>
          </Box>
        </Grid>
      )}

      {thumbs?.length ? <aside style={thumbsContainer}>{thumbs}</aside> : null}

      {/* Modal for image preview */}
      <Modal
        open={Boolean(previewFile)}
        onClose={closePreview}
        aria-labelledby="image-preview"
        aria-describedby="image-preview-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxWidth: '80vw',
          maxHeight: '80vh',
          overflow: 'auto'
        }}>
          {/* <Box sx={{ mb: 5, textAlign: "right" }}>
            <Button
              variant="outlined"
              startIcon={<GetAppIcon />}
              onClick={() => downloadFile(previewFile)}
            >
              Download
            </Button>
          </Box> */}
          {previewFile?.name?.toLowerCase()?.includes(".pdf") ? <PDFViewer url={previewFile?.preview} /> :
            <img src={previewFile?.preview} alt="preview" style={{ maxWidth: '100%' }} />
          }
        </Box>
      </Modal>

    </Box>
  );
};

export default FileDropZone;
