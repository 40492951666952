import React from "react";
import { Button as MUIButton, CircularProgress, Box } from "@mui/material";
import { getInputId } from "../../utils";

const Button = ({
  variant = "contained",
  color = "primary",
  onClick,
  children,
  isButtonLoading,
  disabled,
  sx,
  id,
  name,
  ...rest
}) => {
  return (
    <MUIButton
      variant={variant}
      color={color}
      onClick={onClick}
      disabled={isButtonLoading || disabled}
      {...rest}
      sx={sx}
      id={id || getInputId("button")}
      name={name || getInputId("button")}
    >
      {isButtonLoading ? (
        <Box display="flex" alignItems="center">
          {children}
          <CircularProgress
            size={18}
            color="inherit"
            style={{ marginLeft: 10 }}
          />
        </Box>
      ) : (
        children
      )}
    </MUIButton>
  );
};

export default Button;
