/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ICONS from "../../../../../constants/icons";
import TextEditor from "../../../../../components/TextEditor";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import FileDropZone from "../../../../../components/FileDropZone";
import { styled } from "@mui/styles";
import { getDefaultInOutTime, LEAVE_DURATION_TYPE, SYSTEM_GENERATED_LEAVE_DURATION_TYPE } from "../../../../../constants/default-values";
import SingleDatePicker from "../../../../../components/SingleDatePicker";
import axiosInstance from "../../../../../axios";
import API from "../../../../../axios/api";
// import TimePicker from "../../../../../components/TimePicker";
import moment from "moment";
import { useAlert } from "../../../../../hook/useAlert";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { filterUniqueLeaveData, getApplyDateTime, getDayName, getFormattedDate, getInputId, getReportingAuthority, showInformationAuthority, sortArrayByKey, titleCase } from "../../../../../utils";
import Button from "../../../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import URLS from "../../../../../routes/urls";
import MultipleDatePickerWithButton from "../../../../../components/MultipleDatePickerWithButton";
import Breadcrumb from "../../../../../components/Breadcrumb";
import TimePickerClock from "../../../../../components/TimePickerClock";
import DesktopMacRoundedIcon from '@mui/icons-material/DesktopMacRounded';
import AutocompleteSelect from "../../../../../components/AutocompleteSelect";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "16px",
    borderRadius: "5px",
    background: "#3F5C76",
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const EditLeaveDetails = ({
  setIsAppliedLeave,
  setLeaveData,
  setAutoGeneratedLeaveDays,
  autoGeneratedLeaveDays,
  leaveData,
  currentUser,
  leaveSnapshot,
  organizationUserList,
  userLeaveTypes,
  setDeletedDays,
  deletedDays,
  setDeletedImages,
  deletedImages,
}) => {
  let specialCredits = leaveSnapshot?.length > 0 ? leaveSnapshot?.find((snap) => Object.keys(snap).includes("specialCredits")) : null;
  const RequestedLeaveType = userLeaveTypes?.find(value => value?.user_leave_type_setting_id === leaveData?.user_leave_type_setting_id);
  const showAlert = useAlert();
  const classes = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const [tempAddNewLeaves, setTempAddNewLeaves] = useState([]);
  const [isLoading, setIsLoading] = useState({
    formSubmitting: false,
    formButtonDisable: false,
    halfTimeNotEntered: false
  })
  const [errors, setErrors] = useState({
    leave_days: {},
    description: '',
    informed_authorities: '',
    user_leave_type_setting_id: '',
  });
  const [sortType, setSortType] = useState("asc");

  useEffect(() => {
    if (!!leaveData) {
      if (!leaveData?.description) setIsLoading(prev => ({ ...prev, formButtonDisable: true }));
      let leavedays = leaveData?.leave_days;
      let updatedErrors = { ...errors.leave_days };
      leavedays?.map((leaveDetail) => {
        let isHalfDay = Object.keys(LEAVE_DURATION_TYPE)[2] !== leaveDetail?.leave_duration
        let leave_duration_time =
          Object.keys(LEAVE_DURATION_TYPE)[0] === leaveDetail?.leave_duration
            ? "in_time"
            : "out_time"
        let leave_date = leaveDetail?.leave_date;
        if (!leaveData?.isAutoGenerated && isHalfDay && (!leaveDetail?.[leave_duration_time])) {
          updatedErrors[`${leave_date}`] = { [`time-${leave_date}`]: `Required` };
          // setIsLoading(prev => ({ ...prev, halfTimeNotEntered: true }));
        }
        return leaveDetail;
      })
      setErrors((prev) => ({ ...prev, leave_days: updatedErrors, }));
    }
  }, [])

  useEffect(() => {
    let sortedArray = sortArrayByKey(leaveData?.leave_days, sortType, 'leave_date');
    setLeaveData((prev) => ({
      ...prev,
      leave_days: sortedArray
    }));
  }, [sortType])

  const filterLeaveDays = (array) => {
    return array?.filter(value => !!value && Object.keys(value)?.includes('isAutoGenerated') ? !value?.isAutoGenerated : true);
  };

  const handleSubmitLeaveSummary = async (body) => {
    try {
      const response = await axiosInstance.post(
        API.applyLeaveSummary(currentUser?.id),
        body
      );
      if (response.status === 200) {
        return response?.data?.result;
      } else {
        showAlert(response?.response?.data?.message, "error");
      }
      return;
    } catch (error) {
      console.error("Error:", error);
      showAlert(error?.response?.data?.message, "error");
      return;
    }
  };

  const handleSubmitLeaveSummaryWithoutCredit = async (body) => {
    try {
      const response = await axiosInstance.post(
        API.applyLeaveSummaryWithoutCredit(currentUser?.id),
        body
      );
      if (response.status === 200) {
        return response?.data?.result;
      } else {
        showAlert(response?.response?.data?.message, "error");
      }
      return;
    } catch (error) {
      console.error("Error:", error);
      showAlert(error?.response?.data?.message, "error");
      return;
    }
  }

  const handleChangeAddRemoveLeaves = async (type, leaveIndex, leaveDetail) => {
    if (type === "remove") {
      setDeletedDays((prev) => ([...prev, leaveDetail]));
      let updatedLeaveDays = leaveData?.leave_days?.map((item, index) => {
        let obj = {
          ...item,
          leave_date: item?.leave_date,
          leave_duration: item?.leave_duration,
          paid: item?.paid,
          unpaid: item?.unpaid,
          id: item?.id || null,
          is_changed: item?.is_changed,
          rules: item?.rules,
          specialRequest: item?.specialRequest,
          isAutoGenerated: item?.isAutoGenerated,
          match: item?.match
        }
        if (leaveIndex === index) {
          obj.is_deleted = true;
        }
        if (!obj.id) {
          delete obj.id;
        }
        return obj;
      });
      const payload = {
        user_leave_type_setting_id: leaveData?.user_leave_type_setting_id,
        leave_days: filterLeaveDays([...updatedLeaveDays, ...deletedDays?.map((day) => ({ ...day, leave_date: day?.leave_date, is_deleted: true }))]),
        club_leave_count: leaveData?.club_leave_count || 0,
        remaining_days: (leaveData?.remaining_days) || 0,
        is_edit: true,
        leave_application_id: parseInt(params?.appId),
        is_leave_type_changed: leaveData?.is_leave_type_changed,
      };
      let response;
      if (specialCredits?.allow_special_credits) {
        response = await handleSubmitLeaveSummary(payload);
      }
      else {
        response = await handleSubmitLeaveSummaryWithoutCredit(payload)
      }

      if (!!response) {
        setLeaveData((prev) => ({
          ...prev,
          club_leave_count: response?.club_leave_count || 0,
          leave_days: response?.leave_days,
          remaining_days: response?.remaining_days || 0,
          remaining_credits: response?.remaining_credits,
        }));
        let data = response?.leave_days?.map(value => ({ ...value, is_changed: false }))?.filter((value) => !!value?.isAutoGenerated)
        setAutoGeneratedLeaveDays(prev => [...prev, ...data])
        setTempAddNewLeaves([]);
      }

    } else {
      try {
        let response;
        const addLeavesData = tempAddNewLeaves?.map((dates) => getFormattedDate(new Date(dates), "YYYY-MM-DD"));
        if (addLeavesData?.length > 0) {
          let updatedLeaveDays = [
            ...filterLeaveDays(leaveData?.leave_days)?.map(item => ({
              ...item,
              leave_date: item?.leave_date,
              leave_duration: item?.leave_duration,
              paid: item?.paid,
              unpaid: item?.unpaid,
              id: item?.id,
              is_changed: item?.is_changed,
              rules: item?.rules,
              specialRequest: item?.specialRequest,
              match: item?.match
            })),
            ...addLeavesData?.map((date) => ({
              leave_date: date,
              leave_duration: "full",
              paid: 0,
              unpaid: 0,
              is_changed: false,
              rules: [],
              specialCredits: [],
            }))];
          const payload = {
            user_leave_type_setting_id: leaveData?.user_leave_type_setting_id,
            leave_days: filterLeaveDays([...updatedLeaveDays, ...deletedDays?.map((day) => ({ ...day, leave_date: day?.leave_date, is_deleted: true }))]),
            club_leave_count: leaveData?.club_leave_count || 0,
            remaining_days: leaveData?.remaining_days || 0,
            is_edit: true,
            leave_application_id: parseInt(params?.appId),
            is_leave_type_changed: leaveData?.is_leave_type_changed,
          };
          if (specialCredits?.allow_special_credits) {
            response = await handleSubmitLeaveSummary(payload);
          }
          else {
            response = await handleSubmitLeaveSummaryWithoutCredit(payload)
          }
          if (!!response) {
            setLeaveData((prev) => ({
              ...prev,
              club_leave_count: response?.club_leave_count || 0,
              leave_days: response?.leave_days,
              remaining_days: response?.remaining_days || 0,
              remaining_credits: response?.remaining_credits,
            }));
            setTempAddNewLeaves([]);
            let data = response?.leave_days?.map(value => ({ ...value, is_changed: false }))?.filter((value) => !!value?.isAutoGenerated)
            setAutoGeneratedLeaveDays(prev => [...prev, ...data])
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleChangeSelectedDate = async (
    newSelectedDate,
    index,
    leaveDetail
  ) => {
    try {
      let response;
      let updatedLeaveDays = filterLeaveDays(leaveData?.leave_days)?.map((item, i) => i === index ? {
        ...item,
        leave_date: getFormattedDate(new Date(newSelectedDate), "YYYY-MM-DD"),
        leave_duration: leaveDetail?.leave_duration,
        paid: leaveDetail?.paid,
        unpaid: leaveDetail?.unpaid,
        id: leaveDetail?.id,
        is_changed: true,
        rules: item?.rules,
        specialRequest: item?.specialRequest,
        match: item?.match
      } : {
        ...item,
        leave_date: item?.leave_date,
        leave_duration: item?.leave_duration,
        paid: item?.paid,
        unpaid: item?.unpaid,
        id: item?.id,
        is_changed: item?.is_changed,
        rules: item?.rules,
        specialRequest: item?.specialRequest,
        match: item?.match
      });

      const payload = {
        user_leave_type_setting_id: leaveData?.user_leave_type_setting_id,
        leave_days: filterLeaveDays([...updatedLeaveDays, ...deletedDays?.map((day) => ({ ...day, leave_date: day?.leave_date, is_deleted: true }))]),
        club_leave_count: leaveData?.club_leave_count || 0,
        remaining_days: leaveData?.remaining_days || 0,
        is_edit: true,
        leave_application_id: parseInt(params?.appId),
        is_leave_type_changed: leaveData?.is_leave_type_changed,
      };
      if (specialCredits?.allow_special_credits) {
        response = await handleSubmitLeaveSummary(payload);
      }
      else {
        response = await handleSubmitLeaveSummaryWithoutCredit(payload)
      }
      if (!!response) {
        setLeaveData((prev) => ({
          ...prev,
          club_leave_count: response?.club_leave_count || 0,
          leave_days: response?.leave_days,
          remaining_days: response?.remaining_days,
          remaining_credits: response?.remaining_credits,
        }));
        setTempAddNewLeaves([]);
        let data = response?.leave_days?.map(value => ({ ...value, is_changed: false }))?.filter((value) => !!value?.isAutoGenerated)
        setAutoGeneratedLeaveDays(prev => [...prev, ...data])
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getTotalCreditUsedCount = (leave_array) => {
    let totalCreditUsed = 0;
    leave_array?.forEach((leave) => {
      totalCreditUsed += leave?.specialRequest?.length || 0;
    });
    return totalCreditUsed || 0;
  };

  const isValidDateFormat = (date) => {
    return new Date(date).toString() !== "Invalid date";
  };

  const handelTimeChange = (time, index, leaveDetail, leave_date) => {
    let updatedErrors = { ...errors.leave_days };
    if (!time || !isValidDateFormat(time)) {
      updatedErrors[`${leave_date}`] = { [`time-${leave_date}`]: `Required` };
      setIsLoading(prev => ({ ...prev, halfTimeNotEntered: true }));
    } else {
      delete updatedErrors[`${leave_date}`];

      let leavedays = leaveData?.leave_days;
      let isHalfDayTime = false;
      leavedays?.map((leave, leaveIndex) => {
        if (leaveIndex !== index) {
          let isHalfDay = Object.keys(LEAVE_DURATION_TYPE)[2] !== leave?.leave_duration
          let leave_duration_time =
            Object.keys(LEAVE_DURATION_TYPE)[0] === leave?.leave_duration
              ? "in_time"
              : "out_time"

          if (isHalfDay && !leave?.[leave_duration_time]) {
            isHalfDayTime = true;
          }
        }
        return leave;
      });
      setIsLoading(prev => ({ ...prev, halfTimeNotEntered: isHalfDayTime }));
    }
    setErrors((prev) => ({ ...prev, leave_days: updatedErrors, }));
    const updatedLeaveDetails = {
      ...leaveData,
      leave_days: leaveData?.leave_days?.map(
        (item, i) => {
          if (i === index) {
            let object = {
              ...item,
              [leaveDetail
                ?.leave_duration ===
                Object.keys(LEAVE_DURATION_TYPE)[0]
                ? "in_time"
                : "out_time"]:
                !!time && isValidDateFormat(time) ? new Date(time) : null,
            };
            if (object?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[0]) {
              delete object?.out_time;
            }
            else if (object?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[1]) {
              delete object?.in_time;
            } else {
              delete object?.in_time;
              delete object?.out_time;
            }
            return object;
          }
          return item;
        }
      ),
    };
    setLeaveData(updatedLeaveDetails);
  }

  const handleValidateTime = (index, leaveDetail, leave_date) => {
    let leave_duration_time =
      Object.keys(LEAVE_DURATION_TYPE)[0] === leaveDetail?.leave_duration
        ? "in_time"
        : "out_time"
    if (!leaveDetail[`${leave_duration_time}`]) {
      let updatedErrors = { ...errors?.leave_days };
      updatedErrors[`${leave_date}`] = { [`time-${leave_date}`]: `Required` };
      setErrors((prev) => ({ ...prev, leave_days: updatedErrors, }));
    }
  }

  const handleChangeLeaveType = async (event, index, leaveDetail, leave_date) => {
    let initialValue = new Date();
    initialValue.setHours(14);
    initialValue.setMinutes(0);
    initialValue.setSeconds(0);

    let response;
    let updatedLeaveDays = filterLeaveDays(leaveData?.leave_days)?.map((item, i) => {
      if (i === index) {
        if (item?.leave_duration === "full") {
          if (item?.in_time) {
            delete item?.in_time
          }
          if (item?.out_time) {
            delete item?.out_time
          }
        }
        return {
          ...item,
          leave_duration: event.target.value,
          leave_date: item?.leave_date,
          paid: item?.paid,
          unpaid: item?.unpaid,
          id: item?.id,
          rules: item?.rules,
          specialRequest: item?.specialRequest,
          is_changed: true,
        }
      } else {
        if (item?.leave_duration !== "full") {
          if (item?.in_time) {
            delete item?.out_time
          }
          if (item?.out_time) {
            delete item?.in_time
          }
        }
        return {
          ...item,
          leave_duration: item?.leave_duration,
          leave_date: item?.leave_date,
          paid: item?.paid,
          unpaid: item?.unpaid,
          id: item?.id,
          is_changed: item?.is_changed,
          rules: item?.rules,
          specialRequest: item?.specialRequest,
        }
      }
    });
    const payload = {
      user_leave_type_setting_id: leaveData?.user_leave_type_setting_id,
      leave_days: filterLeaveDays([...updatedLeaveDays, ...deletedDays?.map((day) => ({ ...day, leave_date: day?.leave_date, is_deleted: true }))]),
      club_leave_count: leaveData?.club_leave_count || 0,
      remaining_days: leaveData?.remaining_days || 0,
      is_edit: true,
      leave_application_id: parseInt(params?.appId),
      is_leave_type_changed: leaveData?.is_leave_type_changed,
    };
    if (specialCredits?.allow_special_credits) {
      response = await handleSubmitLeaveSummary(payload);
    }
    else {
      response = await handleSubmitLeaveSummaryWithoutCredit(payload);
    }
    if (response?.leave_days?.length > 0) {
      // let halfTimeAvailable = false;
      let updatedLeaveDays = response?.leave_days?.map((item, i) => {
        if (i === index) {
          let { inTime, outTime } = getDefaultInOutTime();
          if (item?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[0]) {
            // item['in_time'] = moment(initialValue).format("HH:mm");
            item['in_time'] = inTime;
            delete item?.out_time;
            // halfTimeAvailable = true;
          }
          else if (item?.leave_duration === Object.keys(LEAVE_DURATION_TYPE)[1]) {
            // item['out_time'] = moment(initialValue).format("HH:mm");
            item['out_time'] = outTime;
            delete item?.in_time;
            // halfTimeAvailable = true;
          } else {
            delete item?.in_time;
            delete item?.out_time;
          }
          return item;
        }
        // if ((Object.keys(item)?.includes('in_time') && !item?.in_time) || (Object.keys(item)?.includes('out_time') && !item?.out_time)) halfTimeAvailable = true;
        return item;
      });
      // setIsLoading(prev => ({ ...prev, halfTimeNotEntered: halfTimeAvailable }));
      let leave_duration_time =
        Object.keys(LEAVE_DURATION_TYPE)[0] === leaveDetail?.leave_duration
          ? "in_time"
          : "out_time"

      if (!leaveDetail[`${leave_duration_time}`]) {
        let updatedErrors = { ...errors?.leave_days };
        delete updatedErrors[`${leave_date}`];
        setErrors((prev) => ({ ...prev, leave_days: updatedErrors, }));
      }

      if (!!response) {
        setLeaveData((prev) => ({
          ...prev,
          club_leave_count: response?.club_leave_count || 0,
          leave_days: updatedLeaveDays,
          remaining_days: response?.remaining_days || 0,
          remaining_credits: response?.remaining_credits,
        }));
        let data = response?.leave_days?.map(value => ({ ...value, is_changed: false }))?.filter((value) => !!value?.isAutoGenerated)
        setAutoGeneratedLeaveDays(prev => [...prev, ...data])
      }
    }
  };

  const isValidateData = () => {
    let isEmpty = !leaveData?.description || leaveData?.description
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length === '' || errors?.description?.length;

    if (!errors?.description?.length && isEmpty) {
      setErrors((prev) => ({
        ...prev,
        description: 'Reason Required'
      }))
    } else {
      let reasonLength = leaveData?.description
        ?.replace(/&nbsp;/g, ' ')
        ?.replace(/<(.|\n)*?>/g, '')
        ?.trim()?.length;

      isEmpty = reasonLength < 50 || reasonLength > 500;
      setErrors((prev) => ({
        ...prev,
        description: reasonLength ? (reasonLength < 50 || reasonLength > 500) ? 'Atleast 50 and up to 500 characters required' : ''
          : 'Reason Required'
      }))
    }
    if (leaveData?.is_auto_generated) {
      if (leaveData?.informed_authorities?.length === 0) {
        setErrors((prev) => ({
          ...prev,
          informed_authorities: 'Informed authorities required'
        }))
      }
      if (!leaveData?.user_leave_type_setting_id) {
        setErrors((prev) => ({
          ...prev,
          user_leave_type_setting_id: 'Leave type required'
        }))
      }
    }
    setIsLoading((prev) => ({ ...prev, formButtonDisable: (isEmpty || Object.keys(errors.leave_days)?.length) }))
    return !!!Object.keys(errors.leave_days)?.length && !isEmpty;
  }

  const handleSubmitLeaveApplication = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, formSubmitting: true }))
      function convertObjectToFormData(obj) {
        const formData = new FormData();

        function findDeletedElements(array1, array2) {
          if (!array2?.length)
            return [];
          let deletedElements = [];
          array1?.filter(value => !!value)?.forEach(element => {
            let found = array2?.some(item => item?.name === element?.split('/').pop());
            if (!found) {
              deletedElements.push(element);
            }
          });
          return deletedElements;
        }

        const filteredRef = obj?.attachment_references?.filter(value => value !== "null" || value !== null);
        const deletedElements = findDeletedElements(filteredRef, obj?.attachments);
        filteredRef?.forEach((attachment, index) => {
          if (!deletedElements.includes(attachment))
            formData.append(`attachment_references[${index}]`, attachment);
        });
        obj?.attachments?.filter((value) => !!value?.base64)?.forEach((attachment, index) => {
          if (!!attachment?.size) {
            formData.append(`attachments[${index}][name]`, attachment?.name);
            formData.append(`attachments[${index}][size]`, attachment?.size);
            formData.append(`attachments[${index}][type]`, attachment?.type);
            formData.append(`attachments[${index}][base64]`, attachment?.base64);
            formData.append(`attachments[${index}][unique_id]`, attachment?.unique_id);
            formData.append(`attachments[${index}][extension]`, `.${attachment?.name?.split('.').pop()}`);
          }
        });
        deletedImages?.forEach((attachment, index) => {
          formData.append(`delete_attachment[${index}]`, attachment?.preview);
        });
        formData.append(
          "user_leave_type_setting_id",
          obj.user_leave_type_setting_id
        );
        formData.append(
          "is_leave_type_changed",
          obj.is_leave_type_changed
        );
        formData.append(
          "club_leave_count",
          obj.club_leave_count || 0
        );
        formData.append(
          "is_edit",
          true
        );
        formData.append(
          "org_year_id",
          currentUser?.organization?.work_type_settings?.[0]?.OrgYear?.id
        );
        formData.append(
          "setting_start_date",
          currentUser?.organization?.work_type_settings?.[0]?.OrgYear?.setting_start_date
        );
        formData.append(
          "setting_end_date",
          currentUser?.organization?.work_type_settings?.[0]?.OrgYear?.setting_end_date
        );
        formData.append(
          "leave_application_id",
          parseInt(params?.appId)
        );
        formData.append("description", obj.description);
        formData.append("apply_date", obj?.apply_date);
        obj.informed_authorities?.forEach((authority, index) => {
          const RequestedToUserDetails = getReportingAuthority(organizationUserList, leaveData?.informed_authorities?.[0]);
          formData.append(`informed_authorities[${index}]`, RequestedToUserDetails?.id || null);
        });
        let filteredData = [...filterLeaveDays(obj?.leave_days), ...filterUniqueLeaveData(autoGeneratedLeaveDays)];
        filteredData?.forEach((leaveDay, index) => {
          formData.append(
            `leave_days[${index}][leave_date]`,
            leaveDay?.leave_date
            // convertDateFromFormat(leaveDay?.leave_date)
          );
          if (!!leaveDay?.id) {
            formData.append(
              `leave_days[${index}][id]`, leaveDay?.id || null);
          }
          formData.append(
            `leave_days[${index}][leave_duration]`,
            leaveDay.leave_duration
          );
          formData.append(
            `leave_days[${index}][credit_used]`,
            leaveDay?.rules?.length || 0
          );
          formData.append(
            `leave_days[${index}][match]`,
            leaveDay?.match
          );
          if (!!leaveDay?.id) {
            formData.append(
              `leave_days[${index}][is_changed]`,
              leaveDay?.is_changed || false
            );
          }
          leaveDay?.specialRequest?.forEach((specialReq, specialReqIndex) => {
            formData.append(
              `leave_days[${index}][special_request][${specialReqIndex}]`,
              specialReq
            );
          });
          if (leaveDay?.in_time) {
            formData.append(
              `leave_days[${index}][in_time]`,
              // leaveDay?.in_time
              moment(leaveDay?.in_time).format("HH:mm")
            );
          }
          if (leaveDay?.out_time) {
            formData.append(
              `leave_days[${index}][out_time]`,
              // leaveDay?.out_time
              moment(leaveDay?.out_time).format("HH:mm")
            );
          }
          formData.append(
            `leave_days[${index}][paid]`,
            leaveDay?.paid
          );
          formData.append(
            `leave_days[${index}][unpaid]`,
            leaveDay?.unpaid
          );
          if (!!leaveDay?.isAutoGenerated) {
            formData.append(
              `leave_days[${index}][isAutoGenerated]`,
              leaveDay?.isAutoGenerated || false
            );
          }
        });

        deletedDays?.filter(value => !!value?.id)?.forEach((leaveDay, index) => {
          let updatedIndex = filteredData?.length + index;
          formData.append(
            `leave_days[${updatedIndex}][leave_date]`,
            leaveDay?.leave_date
            // convertDateFromFormat(leaveDay?.leave_date)
          );
          if (!!leaveDay?.id) {
            formData.append(
              `leave_days[${updatedIndex}][id]`, leaveDay?.id || null);
          }
          formData.append(
            `leave_days[${updatedIndex}][leave_duration]`,
            leaveDay.leave_duration
          );
          formData.append(
            `leave_days[${updatedIndex}][credit_used]`,
            leaveDay?.rules?.length || 0
          );
          if (!!leaveDay?.id) {
            formData.append(
              `leave_days[${updatedIndex}][is_changed]`,
              leaveDay?.is_changed || false
            );
          }
          leaveDay?.specialRequest?.forEach((specialReq, specialReqIndex) => {
            formData.append(
              `leave_days[${updatedIndex}][special_request][${specialReqIndex}]`,
              specialReq
            );
          });
          if (leaveDay?.in_time) {
            formData.append(`leave_days[${updatedIndex}][in_time]`, leaveDay?.in_time);
          }
          if (leaveDay?.out_time) {
            formData.append(
              `leave_days[${updatedIndex}][out_time]`,
              leaveDay?.out_time
            );
          }
          formData.append(
            `leave_days[${updatedIndex}][paid]`,
            leaveDay?.paid
          );
          formData.append(
            `leave_days[${updatedIndex}][unpaid]`,
            leaveDay?.unpaid
          );
          if (!!leaveDay?.isAutoGenerated) {
            formData.append(
              `leave_days[${updatedIndex}][isAutoGenerated]`,
              leaveDay?.isAutoGenerated || false
            );
          }
          formData.append(
            `leave_days[${updatedIndex}][is_deleted]`,
            true
          );
        });

        formData.append("remaining_days", leaveData?.remaining_days || 0);

        return formData;
      }
      if (isValidateData()) {
        const formData = convertObjectToFormData(leaveData);
        const config = {
          headers: { "content-type": "multipart/form-data" },
        };

        const response = await axiosInstance.put(
          API.updateLeaveById(params?.userId, params?.appId),
          formData,
          config
        );
        if (response.status === 200) {
          showAlert(response?.data.message);
          navigate(`${URLS.Leaves}?view=list&tabValue=1&groupBy=Status&status=active`)
        } else {
          showAlert(response?.response?.data?.message, "error");
        }
      }
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }))
    } catch (error) {
      console.error("Error:", error);
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }))
      showAlert(error?.response?.data?.message, "error");
    }
  };

  const handleChangeClick = () => {
    let updatedLeaveDetails = {
      ...leaveData,
      leave_days: filterLeaveDays(leaveData?.leave_days),
    };
    setLeaveData(updatedLeaveDetails);
    setTimeout(() => {
      setIsAppliedLeave(false);
    }, 0);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLeaveData(prev => {
      let _value = null;
      if (name === "informed_authorities") {
        _value = [value];
      } else {
        _value = value;
      }
      return {
        ...prev,
        [name]: _value,
        is_leave_type_changed: name === "user_leave_type_setting_id" && leaveData?.user_leave_type_setting_id !== _value ? true : false,
        leave_days: leaveData?.leave_days?.map((day) => ({
          ...day,
          is_changed: name === "user_leave_type_setting_id" && leaveData?.user_leave_type_setting_id !== _value ? true : false
        }))
      };
    });
    handleBlur(event);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
    let updatedErrors = { ...errors };
    if (['leave_days'].includes(name)) {
      if (!!value?.length) {
        updatedErrors[name] = "";
      } else {
        updatedErrors[name] = "Required";
      }
    } else {
      if (!!value) {
        updatedErrors[name] = "";
      } else {
        updatedErrors[name] = "Required";
      }
    }
    setErrors(updatedErrors)
  };

  const handleBackClick = () => {
    navigate(-1);
  }

  const handleDeleteImage = async (file) => {
    if (!file?.size) {
      setDeletedImages(prev => [...prev, file])
    }
  }

  const getRemainingCreditsData = () => {
    return leaveSnapshot?.find((data) => Object.keys(data)?.includes('specialCredits'))
  }

  const getMatchData = (leaveDetail) => {
    let usedSpecialRequest = [...leaveDetail?.specialRequest];
    const isExistRule = (creditListData) => {
      return usedSpecialRequest?.includes(creditListData?.id)
    }

    return (
      <>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography color="white" mb="0">
                {/* {leaveDetail?.match === 'fully' ? 'Match' : leaveDetail?.match === "true" ?
                  `${leaveDetail?.specialRequest?.length} credit will be used` : ' Request Status'} */}
                Request Status
              </Typography>
              <List
                sx={{
                  padding: 0,
                  // marginLeft: '25px',
                  li: {
                    listStyle: "decimal",
                    display: "list-item",
                    paddingLeft: '5px',
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '17px',
                    paddingBottom: '0px',
                    marginBottom: '0px'
                  },
                  'li:last-child': {
                    marginBottom: '0px'
                  }
                }}
              >
                {leaveDetail?.match === 'fully' ?
                  getRemainingCreditsData()?.specialCreditsList?.length ?
                    getRemainingCreditsData()?.specialCreditsList?.filter(data => data?.leave_credit)?.map((creditListData, index) => (
                      <ListItem sx={{ listStyle: 'none !important', paddingLeft: '0 !important', 'svg': { display: 'block', } }} key={index}>
                        <i style={{ background: '#ffffff', height: '18px', width: '18px', borderRadius: '50%', marginRight: '10px', display: 'inline-block', verticalAlign: 'Top' }}>
                          {ICONS.CheckIcon}
                        </i>{titleCase(creditListData?.name)}</ListItem>
                    )) : null : getRemainingCreditsData()?.specialCreditsList?.filter(data => data?.leave_credit)?.map((creditListData, index) => (
                      <ListItem sx={{ listStyle: 'none !important', paddingLeft: '0 !important', 'svg': { display: 'block', } }} key={index}>
                        <i style={{ background: '#ffffff', height: '18px', width: '18px', borderRadius: '50%', marginRight: '10px', display: 'inline-block', verticalAlign: 'Top' }}>
                          {isExistRule(creditListData) ? ICONS.CrossIcon : ICONS.CheckIcon}
                        </i>{titleCase(creditListData?.name)}</ListItem>
                    ))}
              </List>
            </React.Fragment>
          }
        >
          <i
            style={{
              height: 20,
              width: 20,
              display: "inline-block",
              verticalAlign: "middle",
            }}
          >
            {leaveDetail?.match === 'fully' ? <CheckCircleRoundedIcon
              color="primary"
              sx={{ fontSize: 20 }}
            /> : <ErrorOutlineRoundedIcon
              color={leaveDetail?.match === "true" ? "primary" : "error"}
              sx={{ fontSize: 20 }}
            />}
          </i>
        </HtmlTooltip>
      </>
    )
  }

  const handleChangeDescription = (value) => {
    let tempVal = value;
    const isEmpty = !tempVal || tempVal?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim() === '';

    let reasonLength = value
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length;

    setIsLoading((prev) => ({ ...prev, formButtonDisable: (reasonLength < 50 || reasonLength > 500) }))
    setLeaveData({
      ...leaveData,
      description: value,
    })
    setErrors((prev) => ({
      ...prev,
      description: !isEmpty ? '' : 'Reason Required'
    }))
  }

  const handleBlurDescription = (value) => {
    let reasonLength = leaveData?.description
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length;
    setIsLoading((prev) => ({ ...prev, formButtonDisable: (reasonLength < 50 || reasonLength > 500) }))

    setErrors((prev) => ({
      ...prev,
      description: reasonLength ? (reasonLength < 50 || reasonLength > 500) ? 'Atleast 50 and up to 500 characters required' : ''
        : 'Reason Required'
    }))
  }

  const getReasonLength = () => {
    return leaveData?.description
      ?.replace(/&nbsp;/g, ' ')
      ?.replace(/<(.|\n)*?>/g, '')
      ?.trim()?.length;
  }

  const isAllFutureDateInclude = (leaveDays = leaveData?.leave_days) => {
    return leaveDays?.every(leaves => leaves?.future_date)
  }

  const getMinDate = () => {
    let date = new Date(leaveData?.not_in_current_year ?
      currentUser?.organization?.work_type_settings[0]?.OrgYear?.setting_end_date :
      currentUser?.organization?.work_type_settings[0]?.OrgYear?.setting_start_date);
    if (leaveData?.not_in_current_year) date.setDate(date.getDate() + 1);
    return getFormattedDate(date);
  }

  return (
    <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
      <Breadcrumb isBack={true} title={"My Leaves"}
        pageTitle={'Leave Detail'}
        isPathShow={false}
        onBackClick={() => handleBackClick()} />
      <Box px={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} width={'100%'} sx={{ overflowY: 'auto' }}>
        <Box>

          <Box px={3} pt={3} pb={2} mb={3} borderRadius={'4px'} sx={{ background: "#F5F8FA" }}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
              <Box display="flex" alignItems="center" flex={"1"}>
                {leaveData?.is_auto_generated ?
                  <Box sx={{ maxWidth: '34px', width: '34', height: '34px', flex: '0 0 34px', background: '#3F5C76', borderRadius: '100%' }} mr={1} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <DesktopMacRoundedIcon sx={{ fontSize: '18px', color: '#ffffff' }} />
                  </Box> : null}
                <Chip label="Pending" variant="outlined" color={"orangetc"} sx={{ mr: 1, display: "inline-flex" }} />
                <Typography color="dark.800" fontSize={22} fontWeight={500} lineHeight={"33px"} letterSpacing={"0.17px"}>{leaveData?.is_auto_generated ? "System Generated" : null} {leaveData?.is_auto_generated ? `(${titleCase(RequestedLeaveType?.name)})` : titleCase(RequestedLeaveType?.name)}</Typography>
              </Box>
              {!leaveData?.is_auto_generated ?
                <Box>
                  <Button
                    variant="text"
                    onClick={handleChangeClick}
                    sx={{ textTransform: 'none', }}
                    disabled={isLoading?.formSubmitting}
                  >Change</Button>
                </Box> : null}
            </Box>
            <Box mb={1.5}>
              <Typography
                color="dark.800"
                fontSize={14}
                fontWeight={500}
                lineHeight={"24px"}
                letterSpacing={"0.15px"}
                component={"div"}
              >
                <Typography
                  fontSize={14} fontWeight={700} lineHeight={"24px"} letterSpacing={"0.15px"}
                  display={"inline-block"}
                  mr={0.5}
                >
                  Informed to:
                </Typography>
                {showInformationAuthority(leaveData?.informed_authorities) || "-"}
              </Typography>
              <Typography
                color="dark.800"
                fontSize={14}
                fontWeight={500}
                lineHeight={"24px"}
                letterSpacing={"0.15px"}
                component={"div"}
              >
                <Typography
                  fontSize={14} fontWeight={700} lineHeight={"24px"} letterSpacing={"0.15px"}
                  display={"inline-block"}
                  mr={0.5}
                >
                  Apply on:
                </Typography>
                {getApplyDateTime(leaveData?.createdAt || leaveData?.apply_date)}
              </Typography>
            </Box>
          </Box>

          {leaveData?.is_auto_generated ?
            <Box width={768} sx={{ maxWidth: "100%" }} mb={5}>
              <Grid container display={"flex"} gap={4}>
                <Grid item flex={1}>
                  <FormControl variant="standard" sx={{ m: 0, width: "100%" }}>
                    <InputLabel htmlFor={getInputId("user_leave_type_setting_id", 0)}>
                      Leave Type
                    </InputLabel>
                    <Select
                      id="user_leave_type_setting_id"
                      inputProps={{ id: getInputId("user_leave_type_setting_id", 0) }}
                      name="user_leave_type_setting_id"
                      onChange={handleChange}
                      value={leaveData?.user_leave_type_setting_id}
                      onBlur={handleBlur}
                      label="Leave Type"
                    >
                      {userLeaveTypes?.map(
                        ({ name, user_leave_type_setting_id }) => (
                          <MenuItem value={user_leave_type_setting_id} key={user_leave_type_setting_id}>
                            {name}
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {!!errors?.user_leave_type_setting_id ?
                      <FormHelperText sx={{ color: "red" }}>
                        {errors?.user_leave_type_setting_id}
                      </FormHelperText>
                      : null}
                  </FormControl>
                </Grid>
                <Grid item flex={1}>
                  <FormControl
                    className={classes.margin}
                    variant="standard"
                    sx={{
                      margin: "0px !important",
                      width: "100%",
                      // "div ": { marginTop: "2px !important" },
                    }}
                  >
                    <AutocompleteSelect
                      onChange={handleChange}
                      onBlur={handleBlur}
                      options={sortArrayByKey(organizationUserList, 'asc', 'name')}
                      isOptionEqualToValue={(option, value) => getReportingAuthority(organizationUserList, leaveData?.informed_authorities?.[0]) ?? {}}
                      value={getReportingAuthority(organizationUserList, leaveData?.informed_authorities?.[0])}
                      label={"Request To"}
                      name="informed_authorities"
                      id="informed_authorities"
                      disableClearable={!getReportingAuthority(organizationUserList, leaveData?.informed_authorities?.[0])}
                    />
                    {!!errors?.informed_authorities ?
                      <FormHelperText sx={{ color: "red" }}>
                        {errors?.informed_authorities}
                      </FormHelperText>
                      : null}
                  </FormControl>
                </Grid>
              </Grid>
            </Box> :
            null}

          <TableContainer
            component={Paper}
            sx={{ border: "1px solid #E0E0E0", boxShadow: "none", mb: 3 }}
          >
            <Table aria-label="simple table">
              <TableHead sx={{ background: "rgba(0, 0, 0, 0.01)" }}>
                <TableRow
                  sx={{
                    th: {
                      color: "dark.800",
                      fontWeight: 500,
                      fontSize: 14,
                      lineHeight: "24px",
                      letterSpacing: "0.15px",
                      padding: "6px 16px",
                      whiteSpace: 'nowrap',
                    },
                  }}
                >
                  <TableCell>
                    <TableSortLabel
                      active={true}
                      direction={sortType}
                      onClick={() => setSortType((prev) => prev === 'asc' ? 'desc' : 'asc')}
                    >
                      Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sx={{ width: 115, maxWidth: 115, minWidth: 115 }}
                  >Day</TableCell>
                  <TableCell>Type</TableCell>
                  {/* <TableCell width={160} align="center">
                    <span style={{ width: '50px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>Paid</span>|<span style={{ width: '50px', display: 'inline-block', marginLeft: '4px' }}>Unpaid</span>
                  </TableCell> */}
                  {specialCredits?.allow_special_credits ?
                    <>
                      <TableCell width={120}>
                        Match
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography fontSize={16} fontWeight={500} color="white" mb={1} lineHeight={"24px"} letterSpacing={"0.17px"}>
                                Match
                              </Typography>
                              <Typography fontSize={12} fontWeight={500} color="white" lineHeight={"18px"} maxWidth={286}>
                                When submitting your request, please be aware of the conditions that might use your credits for different situation.
                              </Typography>
                            </React.Fragment>
                          }
                        >
                          <i
                            style={{
                              width: "18px",
                              height: "18px",
                              display: "inline-block",
                              verticalAlign: "middle",
                              marginLeft: "3px",
                              color: "#3F5C76",
                            }}
                          >
                            {ICONS.Info}
                          </i>
                        </HtmlTooltip>
                      </TableCell>
                      <TableCell width={110}>Credit</TableCell>
                    </>
                    : null}
                  {!leaveData?.is_auto_generated ?
                    <TableCell width={90} align="center">
                      Remove
                    </TableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {leaveData?.leave_days?.map((leaveDetail, index, leaveArray) => (
                  <TableRow
                    key={index}
                    sx={{
                      td: {
                        color: "dark.800",
                        fontWeight: 400,
                        fontSize: 14,
                        lineHeight: "24px",
                        letterSpacing: "0.15px",
                        padding: "7px 16px",
                        border: 0,
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      },
                    }}
                  >
                    <TableCell
                      sx={{ width: 160, maxWidth: 160, minWidth: 160 }}
                    >
                      {leaveData?.is_auto_generated ?
                        <Typography>{getFormattedDate(leaveDetail?.leave_date)}</Typography>
                        : <Grid item className="calendar-date-picker"
                          sx={{
                            'input': {
                              width: '100%',
                              height: 30,
                              border: 'none !important',
                              boxShadow: 'none !important',
                              borderRadius: '0px',
                              paddingBottom: '6px',
                              paddingLeft: '28px',
                              borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                              margin: '0',
                              position: 'relative',
                              background: 'transparent',
                              color: 'dark.800',
                              fontSize: 14,
                              fontWeight: 400,
                              lineHeight: '24px',
                              letterSpacing: '0.15px'
                            },
                            'input:disabled': {
                              color: 'rgb(170, 170, 170)',
                              borderColor: 'rgba(118, 118, 118, 0.3) !important',
                            },
                            'input:disabled:hover': {
                              color: 'rgb(170, 170, 170)',
                              borderColor: 'rgba(118, 118, 118, 0.3) !important',
                            },
                            'input:hover': {
                              borderBottomColor: '#333333 !important',
                            },
                            'input:focus': {
                              borderBottomColor: '#047FE0 !important',
                            },
                            '.rmdp-container': {
                              minWidth: '100px',
                            },
                          }}
                        >
                          <div style={{ height: 1 }}>
                            <CalendarTodayIcon
                              sx={{
                                fontSize: 20,
                                marginBottom: '-5px',
                                marginLeft: '2px',
                                color: ((Object.keys(leaveDetail)?.includes('isAutoGenerated') && leaveDetail?.isAutoGenerated)) ? "rgb(170, 170, 170) !important" : "#3F5C76",
                              }}
                            />
                          </div>
                          <SingleDatePicker
                            extraDisableDates={leaveData?.leave_days?.map(leaveDetail => getFormattedDate(leaveDetail?.leave_date))}
                            value={getFormattedDate(leaveDetail?.leave_date)}
                            onChange={(value) =>
                              handleChangeSelectedDate(value, index, leaveDetail)
                            }
                            isMultiple={false}
                            disabled={((Object.keys(leaveDetail)?.includes('isAutoGenerated') && leaveDetail?.isAutoGenerated) || false)}
                          />
                        </Grid>}
                    </TableCell>
                    {leaveData?.is_auto_generated ?
                      <TableCell
                        color="dark.800"
                        fontWeight={400}
                        fontSize={14}
                        lineheight="24px"
                        letterSpacing="0.15px"
                      >
                        <Typography>{getDayName(getFormattedDate(leaveDetail?.leave_date))}</Typography>
                      </TableCell> : <TableCell>
                        <Typography>{getDayName(getFormattedDate(leaveDetail?.leave_date))}</Typography>
                      </TableCell>}
                    <TableCell
                      sx={{
                        padding: "0px 16px !important"
                      }}
                    >
                      {leaveData?.is_auto_generated && leaveDetail?.leave_duration === "full" ?
                        <Typography>{leaveDetail?.leave_duration?.toLowerCase() === "full" ? "Full" : "Half"} Day</Typography> :
                        <Grid item sx={{ display: 'flex' }}>
                          <Grid item maxWidth={{ xs: 130, xl: 170 }} width={{ xs: 130, xl: 170 }} mr={2}>
                            <FormControl
                              size="small"
                              variant="standard"
                              fullWidth
                              sx={{
                                height: "30px",
                                width: { xs: 130, xl: 170 },
                                ".MuiInputBase-root": {
                                  height: "100%",
                                },
                              }}
                            >
                              <Select
                                inputProps={{ id: `leave_duration_${index}` }}
                                value={leaveDetail?.leave_duration || ""}
                                onChange={(event) => {
                                  handleChangeLeaveType(event, index, leaveDetail, leaveDetail?.leave_date)
                                }}
                                height="44px"
                                disabled={((Object.keys(leaveDetail)?.includes('isAutoGenerated') && leaveDetail?.isAutoGenerated) || false)}
                              >
                                {Object.entries(leaveData?.is_auto_generated ? SYSTEM_GENERATED_LEAVE_DURATION_TYPE : LEAVE_DURATION_TYPE).map(
                                  (leaveType, index) => (
                                    <MenuItem key={index} value={leaveType[0]}>
                                      {leaveType[1]}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item maxWidth={{ xs: 130, xl: 170 }} width={{ xs: 130, xl: 170 }}>
                            {leaveDetail?.leave_duration ===
                              Object.keys(LEAVE_DURATION_TYPE)[0] ||
                              leaveDetail?.leave_duration ===
                              Object.keys(LEAVE_DURATION_TYPE)[1] ? (
                              <Grid item
                                sx={{
                                  '.rmdp-container': {
                                    minWidth: { xs: '130px !important', xl: '170px !important' },
                                  },
                                  'input': {
                                    width: '100%',
                                    height: 30,
                                    border: 'none !important',
                                    boxShadow: 'none !important',
                                    borderRadius: '0px',
                                    paddingBottom: '6px',
                                    paddingLeft: '28px',
                                    borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                                    margin: '0',
                                    position: 'relative',
                                    background: 'transparent',
                                    color: 'dark.800',
                                    fontSize: 14,
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                    letterSpacing: '0.15px'
                                  },
                                  'input:hover': {
                                    borderBottomColor: '#333333 !important',
                                  },
                                  'input:focus': {
                                    borderBottomColor: '#047FE0 !important',
                                  },
                                }}
                              >
                                <div style={{ height: 1 }}>
                                  <ScheduleIcon
                                    sx={{
                                      fontSize: 20,
                                      color: '#3F5C76',
                                      marginBottom: '-9px',
                                      marginLeft: '1px',
                                    }}
                                  />
                                </div>
                                <TimePickerClock
                                  value={leaveDetail?.in_time || leaveDetail?.out_time}
                                  placeholder={leaveDetail?.leave_duration === 'first_half' ? "Time to Come" : "Time to Leave"}
                                  onChange={(time) => {
                                    handelTimeChange(time, index, leaveDetail, leaveDetail?.leave_date)
                                  }}
                                  onClose={() => handleValidateTime(index, leaveDetail, leaveDetail?.leave_date)}
                                />
                                {!!errors?.leave_days?.[`${leaveDetail?.leave_date}`] ?
                                  <div style={{ height: '1px', position: 'relative' }}>
                                    <div style={{ position: 'absolute', top: '100%', right: '0', background: '#E87160', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}>
                                      <span className="error-arrow-top"></span>
                                      <Typography sx={{ color: "white", fontSize: '11px', fontWeight: 400, }}>
                                        {!!errors?.leave_days?.[`${leaveDetail?.leave_date}`]?.[`time-${leaveDetail?.leave_date}`] ? errors?.leave_days?.[`${leaveDetail?.leave_date}`]?.[`time-${leaveDetail?.leave_date}`] : ""}
                                      </Typography>
                                    </div>
                                  </div>
                                  : null}
                              </Grid>
                            ) : null}
                          </Grid>
                        </Grid>}
                    </TableCell>
                    {/* <TableCell width={160} align="center">
                      {(leaveDetail?.future_date || (Number(leaveDetail?.unpaid) === 0 && Number(leaveDetail?.paid) === 0)) ? null :
                        <Grid display="flex" justifyContent="center">
                          <Typography sx={{ width: '50px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>{leaveDetail?.paid}</Typography> |
                          <Typography
                            color="error.main"
                            fontWeight={700}
                            sx={{ width: '50px', display: 'inline-block', textAlign: 'left', marginLeft: '4px' }}
                          >
                            {leaveDetail?.unpaid}
                          </Typography>
                        </Grid>}
                    </TableCell> */}
                    {specialCredits?.allow_special_credits ? <TableCell width={120}>
                      {(leaveDetail?.future_date || leaveData?.not_in_current_year) ? null : getMatchData(leaveDetail)}
                    </TableCell>
                      : null}
                    {specialCredits?.allow_special_credits ?
                      <TableCell
                        width={110}
                        color="dark.800"
                        fontWeight={400}
                        fontSize={14}
                        lineheight="24px"
                        letterSpacing="0.15px"
                      >
                        {(!!leaveDetail?.specialRequest?.length && !leaveDetail?.future_date)
                          ? leaveDetail?.specialRequest?.length
                          : null}
                      </TableCell>
                      : null}
                    {!leaveData?.is_auto_generated ?
                      <TableCell width={90} align="center">
                        <IconButton
                          aria-label="delete"
                          size="small"
                          color="secondary"
                          sx={{ borderRadius: "4px" }}
                          disabled={leaveData?.leave_days?.length <= 1 || leaveDetail?.canDelete === false || !!leaveDetail?.isAutoGenerated}
                          onClick={() =>
                            handleChangeAddRemoveLeaves(
                              "remove",
                              index,
                              leaveDetail
                            )
                          }
                        >
                          <DeleteIcon sx={{ fontSize: 20 }} />
                        </IconButton>
                      </TableCell> : null}
                  </TableRow>
                ))}
                {!leaveData?.is_auto_generated ?
                  <TableRow
                    sx={{
                      td: {
                        padding: "4px 10px",
                        border: 0,
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      },
                    }}
                  >
                    <TableCell colSpan={specialCredits?.allow_special_credits ? 7 : 5}>
                      <MultipleDatePickerWithButton
                        extraDisableDates={leaveData?.leave_days?.map(leaveDetail => getFormattedDate(leaveDetail?.leave_date))}
                        onChange={(dates) => setTempAddNewLeaves(dates)}
                        onClose={() => handleChangeAddRemoveLeaves("add")}
                        selectedDates={tempAddNewLeaves}
                        setSelectedDates={setTempAddNewLeaves}
                        minDate={getMinDate()}
                      />
                    </TableCell>
                  </TableRow> : null}
                {(isAllFutureDateInclude() || leaveData?.not_in_current_year) ? null :
                  <TableRow
                    sx={{
                      td: {
                        padding: "10px 16px",
                        border: 0,
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      },
                    }}
                  >
                    <TableCell colSpan={leaveData?.is_auto_generated ? 2 : 3}></TableCell>
                    <TableCell width={160} align="center">
                      {/* <Grid display="flex" justifyContent="center">
                        <Typography sx={{ width: '50px', display: 'inline-block', textAlign: 'right', marginRight: '4px' }}>{totalPaidCount || 0} </Typography>
                        |
                        <Typography
                          color="error.main"
                          fontWeight={700}
                          sx={{ width: '50px', display: 'inline-block', textAlign: 'left', marginLeft: "4px" }}
                        >
                          {totalUnPaidCount || 0}
                        </Typography>
                      </Grid> */}
                    </TableCell>
                    <TableCell></TableCell>
                    {specialCredits?.allow_special_credits ?
                      <TableCell colSpan={2}>
                        <Typography
                          color="error.main"
                          fontWeight={700}
                          sx={{ marginLeft: "0px" }}
                        >
                          {getTotalCreditUsedCount(leaveData?.leave_days)}{" "}
                          Credit Applied
                        </Typography>
                      </TableCell>
                      : null}
                  </TableRow>}
              </TableBody>
              {(isAllFutureDateInclude() || leaveData?.not_in_current_year) ? null :
                <TableFooter
                  sx={{ 'td': { padding: 0, } }}
                >
                  <TableRow>
                    <TableCell colSpan={leaveData?.is_auto_generated ? 2 : 3}></TableCell>
                    <TableCell>
                      {/* <Grid
                        item
                        width={160}
                        sx={{
                          background: "#DEF3FF",
                          padding: "10px 16px",
                        }}
                      >
                        <Typography
                          color="dark.800"
                          variant="body2"
                          lineHeight="24px"
                          letterSpacing="0.17px"
                          fontWeight={600}
                        >
                          Remaining {RequestedLeaveType?.name?.split(" ")?.map(word => word[0])?.join("")}:{" "}
                          {leaveData?.remaining_days || 0}
                        </Typography>
                      </Grid> */}
                    </TableCell>
                    <TableCell></TableCell>
                    {specialCredits?.allow_special_credits ?
                      <TableCell colSpan={2}>
                        <Grid
                          item
                          width={200}
                          sx={{ background: "#FFF6DF", padding: "10px 16px" }}
                        >
                          <Typography
                            color="dark.800"
                            variant="body2"
                            lineHeight="24px"
                            letterSpacing="0.17px"
                            fontWeight={600}
                          >
                            {/* Remaining Credits: {getTotalRemainingCredits() ?? 0} */}
                            Remaining Credits:  {leaveData?.remaining_credits > 0 ? leaveData?.remaining_credits : 0}
                          </Typography>
                        </Grid>
                      </TableCell>
                      : null}
                  </TableRow>
                </TableFooter>}
            </Table>
          </TableContainer>
          <Box mb={3}>
            <Typography
              variant="body2"
              fontWeight={400}
              sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
            >
              Reason*
            </Typography>
            <TextEditor
              value={leaveData.description}
              onChange={(value) =>
                handleChangeDescription(value)
              }
              onBlur={(value) => handleBlurDescription(value)}
            />
            <div style={{ height: '1px', position: 'relative' }}><div style={{ position: 'absolute', top: '100%', right: '0', background: '#FFF6DF', borderRadius: '3px', padding: '2px 5px', marginTop: '2px' }}><span className=""></span><Typography sx={{ color: "black", fontSize: '11px', fontWeight: 400, }}>{`${getReasonLength() ?? 0} / 500`}</Typography></div></div>
            {!!errors?.description?.length ?
              <Typography
                variant="body2"
                fontWeight={400}
                color={'error'}
                sx={{ lineHeight: "21px", letterSpacing: "0.15", mb: 0.5 }}
              >
                {errors?.description}
              </Typography>
              : null}
          </Box>
          <Box mb={3}>
            <Grid item display="flex" alignItems="center" mb={1}>
              <Typography
                variant="body1"
                color="dark.800"
                fontWeight={400}
                sx={{ lineHeight: "21px", letterSpacing: "0.15px" }}
                mr={1}
              >
                Attachments:
              </Typography>
              <Typography
                color="dark.600"
                fontSize={11}
                fontWeight={400}
                sx={{ lineHeight: "15px", letterSpacing: "0.17px" }}
              >
                (Upto 10MB, upto 5 attachments)
              </Typography>
            </Grid>
            <Box>
              <FileDropZone
                onDrop={(files) =>
                  setLeaveData({
                    ...leaveData,
                    attachments: files,
                  })
                }
                onDeleteImage={handleDeleteImage}
                initialFiles={leaveData?.attachments}
                fileURLs={leaveData?.attachment_references}
                isConvertToBase64={true}
              />
            </Box>
          </Box>
          <Grid item>
            <Button
              disabled={!!Object.keys(errors.leave_days)?.length || errors?.informed_authorities || errors?.user_leave_type_setting_id || !leaveData?.informed_authorities?.length || !leaveData?.user_leave_type_setting_id || isLoading?.formButtonDisable || isLoading?.halfTimeNotEntered}
              variant="contained"
              color="primary"
              size="large"
              sx={{
                color: "white",
                textTransform: "none",
                marginRight: "20px",
                boxShadow: "none",
              }}
              type="button"
              onClick={handleSubmitLeaveApplication}
              isButtonLoading={isLoading.formSubmitting}
            >
              Update
            </Button>
            <Button
              variant="text"
              color="secondary"
              size="large"
              sx={{ textTransform: "none" }}
              onClick={() => navigate(`${URLS.Leaves}?view=list&tabValue=1&groupBy=Status&status=active`)}
              disabled={isLoading.formSubmitting}
            >
              Cancel
            </Button>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default EditLeaveDetails;