import { Box, Typography } from '@mui/material';
import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import noDataImg from '../../assets/images/no-data-found-img.png';
import Button from '../../components/Button';
import { useNavigate } from 'react-router-dom';
import useAuthentication from '../../hook/useAuthentication';
import ClientWrapper from '../Client/ClientWrapper';
import URLS from '../../routes/urls';

const NotFoundComponent = ({ isAuthenticated = false, handleClick, title }) => {

  return (
    <Box height={"100%"} width={"100%"} p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} overflow={"auto"}>
      <Box minHeight={"100%"} width={"100%"} bgcolor={"#FAFBFF"} p={6} display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Box boxShadow={"0px 8px 14px 0px rgba(63, 92, 118, 0.2)"} borderRadius={"12px"} overflow={"hidden"} maxWidth={"642px"} width={"100%"} bgcolor={"white"}>
          <Box bgcolor={"primary.main"} sx={{ padding: "10px" }} display={"flex"} alignItems={"center"} gap={"6px"}>
            <CircleIcon sx={{ color: 'white', fontSize: "6px" }} />
            <CircleIcon sx={{ color: 'white', fontSize: "6px" }} />
            <CircleIcon sx={{ color: 'white', fontSize: "6px" }} />
          </Box>
          <Box pt={8} pb={7} px={4} textAlign={"center"} position={"relative"}>
            <Box position={"relative"} sx={{zIndex: '1'}}>
              <Typography variant='h3' color={"black"} fontSize={28} fontWeight={600} lineHeight={"28px"} mb={4}>{title}</Typography>
              <Typography variant='body1' color={"black"} fontSize={18} fontWeight={500} lineHeight={"22px"} mb={1}>Sorry, we can't find the page you are looking for.</Typography>
              <Typography variant='body2' color={"black"} fontSize={13} fontWeight={500} lineHeight={"22px"}>Either information does not exist or your access is limited.</Typography>
              <Button variant="contained" color="primary" size="large" sx={{ textTransform: 'none', marginTop: "36px" }} onClick={handleClick}>
                {
                  isAuthenticated ? "Go To Dashboard" : "Login To Continue"
                }
              </Button>
            </Box>
            <Box position={"absolute"} bottom={0} left={0} display={"flex"} maxWidth={146}>
              <img alt='404 page' src={noDataImg} style={{ width: '100%' }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const PageNotFound = ({ title = "Page Not Found", showSideBar = true }) => {
  const navigate = useNavigate();
  const { isAuthenticated, getCurrentUser } = useAuthentication();

  const handleClick = () => {
    const currentUser = getCurrentUser();
    const userType = currentUser?.user_type?.toLowerCase();
    if (isAuthenticated()) {
      let URL = userType === "super_admin" ? URLS.AdminDashboard : URLS.Dashboard;
      navigate(URL);
    } else {
      navigate("/");
    }
  };

  return (
    isAuthenticated() ? showSideBar ?
      <ClientWrapper>
        <NotFoundComponent title={title} isAuthenticated={true} handleClick={handleClick} />
      </ClientWrapper> :
      <NotFoundComponent title={title} isAuthenticated={true} handleClick={handleClick} />
      :
      <NotFoundComponent title={title} isAuthenticated={false} handleClick={handleClick} />
  )
}

export default PageNotFound;