/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import useAuthentication from "../../../../hook/useAuthentication";
import styled from "@emotion/styled";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import { useAlert } from "../../../../hook/useAlert";
import Input from "../../../../components/Input";
import { UrlSplitter, convertToBase64, generateUniqueId, getMaxSize, titleCase } from "../../../../utils";
import CircularLoader from "../../../../components/CircularLoader";
import Button from "../../../../components/Button";
import { checkOrganisationUniqueName } from "../../../../axios/service";
import { usePageTitle } from "../../../../hook/useTitle";
import { PROFILE_IMAGE_SIZE, VALID_IMAGE_TYPE } from "../../../../constants/default-values";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});


const AdministratorOrganisation = () => {
    const showAlert = useAlert();
    const { getCurrentUser } = useAuthentication();
    const { setPageTitle } = usePageTitle();
    const currentUser = getCurrentUser();
    const [basicInfoData, setBasicInfoData] = useState({});
    const [previewImage, setPreviewImage] = useState(null);
    const [loading, setLoading] = useState({
        formSubmitting: false,
        pageLoading: true,
    });

    setPageTitle("Organisation");

    useEffect(() => {
        const getOrganisationData = async () => {
            try {
                setLoading((prev) => ({ ...prev, pageLoading: true }));
                const response = await axiosInstance.get(
                    API.organisationBasicInfo(currentUser?.organization_id)
                );
                if (response.status === 200) {
                    setBasicInfoData(response.data.data);
                    const previewProfileImage = response.data?.data?.profile_img;
                    if (previewProfileImage) {
                        setPreviewImage(previewProfileImage);
                    }
                }
                setLoading((prev) => ({ ...prev, pageLoading: false }));
            } catch (error) {
                console.error(error);
                setLoading((prev) => ({ ...prev, pageLoading: false }));
            }
        };
        if (currentUser?.organization_id)
            getOrganisationData();
    }, []);

    const formik = useFormik({
        initialValues: {
            name: "",
            alias: "",
            profile_img: null,
            ...basicInfoData,
            protocol: UrlSplitter(basicInfoData?.sub_domain)?.protocol || "http://",
            sub_domain: UrlSplitter(basicInfoData?.sub_domain)?.subDomain?.toLowerCase(),
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            alias: Yup.string().required("Required"),
            sub_domain: Yup.string()
                .required("Required")
                .max(63, "Subdomain must be at most 63 characters")
                .matches(/\.[a-zA-Z]{2,}$/, "Invalid domain"),
        }),
        onSubmit: async (values) => {
            try {
                setLoading((prev) => ({ ...prev, formSubmitting: true }));
                let sub_domain = values.protocol + values.sub_domain?.toLowerCase();
                const formData = new FormData();
                let base64 = null;
                if (values?.profile_img) {
                    if (values?.profile_img instanceof Blob || values?.profile_img instanceof File) {
                        base64 = await convertToBase64(values?.profile_img);
                    } else {
                        base64 = values?.profile_img?.base64;
                    }
                    formData.append(`profile_img[${0}][name]`, values?.profile_img?.name);
                    formData.append(`profile_img[${0}][size]`, values?.profile_img?.size);
                    formData.append(`profile_img[${0}][type]`, values?.profile_img?.type);
                    formData.append(`profile_img[${0}][base64]`, base64);
                    formData.append(`profile_img[${0}][extension]`, `.${values?.profile_img?.name?.split('.').pop()}`);
                }
                formData.append("name", values.name);
                formData.append("alias", values.alias);
                formData.append("sub_domain", sub_domain);
                formData.append("id", values.id);
                formData.append("active", values.active);
                formData.append("onboarding_status", values.onboarding_status);


                const response = await axiosInstance.put(
                    API.organisationBasicInfo(currentUser?.organization_id),
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );
                if (response.status === 200) {
                    showAlert(response.data.message);
                    setBasicInfoData(response?.data?.data)
                } else {
                    showAlert(response?.response?.data?.message, "error");
                }
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
            } catch (error) {
                showAlert(error?.response?.data?.message, "error");
                setLoading((prev) => ({ ...prev, formSubmitting: false }));
                console.error("Error:", error);
            }
        },
    });

    const handleChangeProfileImage = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (VALID_IMAGE_TYPE.includes(file.type)) {
                if (file.size <= PROFILE_IMAGE_SIZE) {
                    const previewURL = URL.createObjectURL(file);
                    setPreviewImage(previewURL);
                    let _file = Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        unique_id: generateUniqueId(),
                    });
                    formik.setFieldValue(`profile_img`, _file);
                } else {
                    showAlert(`File size exceeds ${getMaxSize(PROFILE_IMAGE_SIZE)}MB limit. Please choose a smaller file.`, "error");
                    formik.setFieldValue("profile_img", null);
                }
            } else {
                showAlert('Only JPEG and PNG file types are allowed.', "error");
                formik.setFieldValue("profile_img", null);
            }
        }
    };

    const handleCheckUniqueOrganisationName = async (name) => {
        try {
            if (name !== basicInfoData?.name) {
                const { isExist } = await checkOrganisationUniqueName(name);
                if (isExist) {
                    formik.setErrors({
                        ...formik.errors,
                        name:
                            "Organization name already exists. Please choose a unique name.",
                    });
                }
                return isExist;
            }
            return false;
        } catch (error) {
            console.error(error);
        }
    };

    const handleCancel = () => {
        formik.resetForm();
        if (basicInfoData?.profile_img) {
            setPreviewImage(basicInfoData?.profile_img);
        } else {
            setPreviewImage(null);
        }
    };

    return (loading.pageLoading ?
        <CircularLoader /> :
        <Box display="flex" flexDirection="column" sx={{ width: "100%" }} p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} overflow="auto">
            <form onSubmit={formik.handleSubmit} className="step-form-1">
                <Stack spacing={4} sx={{ flexBasis: "100%" }}>
                    <Grid item>
                        <Typography
                            variant="body1"
                            color="dark.800"
                            mb={1}
                            sx={{ textTransform: "capitalize" }}
                        >
                            {`${basicInfoData?.name ? `${titleCase(basicInfoData?.name)} Logo` : ""
                                } `}
                        </Typography>
                        <Grid display="flex" alignItems="center">
                            <Box
                                mr={2.5}
                                sx={{
                                    height: "90px",
                                    width: "150px",
                                    background: "rgba(63, 92, 118, 0.09)",
                                    borderRadius: "6px",
                                    overflow: "hidden",
                                }}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    sx={{ height: "100%", width: "100%" }}
                                >
                                    {!!previewImage ?
                                        <img
                                            alt={basicInfoData?.name}
                                            src={previewImage}
                                            className="imgcolver"
                                        /> :
                                        <Box bgcolor="rgba(63, 92, 118, 0.09)" width={180} height={90} display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
                                            <Typography variant="h4" color="#A5B3C1" fontSize={15} lineHeight={"18px"} letterSpacing="0.4px" fontWeight={700} mb={"6px"}>BUSINESS LOGO</Typography>
                                            <Typography variant="body1" color="#A5B3C1" fontSize={10} lineHeight={"12px"} letterSpacing="0.4px" fontWeight={500} >Upload Here</Typography>
                                        </Box>
                                        // <Avatar
                                        //     src="/broken-image.jpg"
                                        //     sx={{
                                        //         width: "100%",
                                        //         height: "100%",
                                        //         borderRadius: "0px",
                                        //         fontSize: "40px",
                                        //     }}
                                        // />
                                    }
                                </Box>
                            </Box>
                            <Button
                                component="label"
                                variant="text"
                                color="primary"
                                fontSize={13}
                                sx={{ textTransform: "none" }}
                            >
                                Change
                                <VisuallyHiddenInput
                                    name="profile_img"
                                    type="file"
                                    onChange={handleChangeProfileImage}
                                    accept="image/*"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid width={"398px"} maxWidth={"100%"}>
                        <Input
                            id="organisation-name"
                            label="Organisation name*"
                            variant="standard"
                            placeholder="organisation name"
                            type="text"
                            name={"name"}
                            fullWidth
                            required={false}
                            {...formik.getFieldProps("name")}
                            value={titleCase(formik?.getFieldProps("name")?.value)}
                            onBlur={(e) => {
                                formik.handleBlur(e);
                                handleCheckUniqueOrganisationName(e.target.value);
                            }}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    <Grid width={"398px"} maxWidth={"100%"}>
                        <Input
                            id="alias"
                            label="Alias"
                            variant="standard"
                            placeholder="alias name"
                            type="text"
                            fullWidth
                            name="alias"
                            {...formik.getFieldProps("alias")}
                            error={formik.touched.alias && Boolean(formik.errors.alias)}
                            helperText={formik.touched.alias && formik.errors.alias}
                        />
                    </Grid>
                    <Grid width={"398px"} maxWidth={"100%"} display="flex">
                        <Grid item>
                            <FormControl
                                variant="standard"
                                sx={{ minWidth: 90, mr: 1.25 }}
                            >
                                <InputLabel htmlFor="protocol-label" required={true}>
                                    Domain
                                </InputLabel>
                                <Select
                                    inputProps={{
                                        id: "protocol-label",
                                    }}
                                    id="protocol-rage"
                                    label="Domain"
                                    name="protocol"
                                    {...formik.getFieldProps("protocol")}
                                >
                                    <MenuItem
                                        value={"https://"}
                                        sx={{
                                            "&:hover": {
                                                background: "rgba(4, 127, 224, 0.1)",
                                            },
                                        }}
                                    >
                                        https://
                                    </MenuItem>
                                    <MenuItem
                                        value={"http://"}
                                        sx={{
                                            "&:hover": {
                                                background: "rgba(4, 127, 224, 0.1)",
                                            },
                                        }}
                                    >
                                        http://
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sx={{ flexBasis: "100%", mt: 2 }}>
                            <Input
                                id="website"
                                label=""
                                variant="standard"
                                placeholder="your domain"
                                type="text"
                                name="sub_domain"
                                fullWidth
                                {...formik.getFieldProps("sub_domain")}
                                value={formik.getFieldProps("sub_domain")?.value?.toLowerCase()}
                                error={
                                    formik.touched.sub_domain &&
                                    Boolean(formik.errors.sub_domain)
                                }
                                helperText={
                                    formik.touched.sub_domain && formik.errors.sub_domain
                                }
                            />
                        </Grid>
                    </Grid>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mt={4}
                    >
                        <Box
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            flexBasis="100%"
                        >
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                                sx={{
                                    boxShadow: "0",
                                    fontSize: "15px",
                                    textTransform: "none",
                                    color: "white",
                                    "&:hover": { boxShadow: "0" },
                                }}
                                disabled={Boolean(Object.keys(formik.errors).length)}
                                isButtonLoading={loading.formSubmitting}
                            >
                                Update
                            </Button>
                            <Button
                                variant="text"
                                color="secondary"
                                sx={{ textTransform: "none" }}
                                size="medium"
                                fontWeight="500"
                                onClick={handleCancel}
                                disabled={loading.formSubmitting}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Stack>
            </form>
        </Box>
    );
};

export default AdministratorOrganisation