/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import CircularLoader from '../../../../components/CircularLoader'
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, MenuItem, Radio, RadioGroup, Stack, Tooltip, tooltipClasses, Typography } from '@mui/material'
import Input from '../../../../components/Input'
import { styled } from '@mui/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useAlert } from '../../../../hook/useAlert';
import useAuthentication from '../../../../hook/useAuthentication';
import { usePageTitle } from '../../../../hook/useTitle';
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import Button from '../../../../components/Button';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3F5C76',
    color: '#ffffff',
    maxWidth: 330,
    padding: 12,
  },
}));

const BasicSettings = () => {
  const showAlert = useAlert();
  const { getCurrentUser } = useAuthentication();
  const { setPageTitle } = usePageTitle();
  const currentUser = getCurrentUser();
  const orgId = currentUser?.organization?.id;
  const [loading, setLoading] = useState({
    formSubmitting: false,
    pageLoading: true,
  });
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState({});
  const [isEditable, setIsEditable] = useState(false);

  let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  setPageTitle("Leaves Settings");

  useEffect(() => {
    fetchData();
  }, [orgId]);

  const fetchData = async () => {
    try {
      let response = await axiosInstance.get(API.getOrganisationBasicSetting(orgId));
      if (response.status === 200) {
        setFormData(response?.data?.data);
      } else {
        setFormData({});
      }
      setLoading((prev) => ({ ...prev, pageLoading: false }));
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  const handleMaxAbsentChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
    if (e.target.value === null || e.target.value === "") {
      setErrors((prev) => ({
        ...prev,
        [e.target.name]: "Required"
      }))
    } else {
      setErrors((prev) => ({
        ...prev,
        [e.target.name]: ""
      }))
    }
  };

  const handleChangeCheckBox = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked
    }))
  }

  const handleCancel = () => {
    fetchData();
    setIsEditable(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading((prev) => ({ ...prev, formSubmitting: false }));
      let body = {
        enable_sys_generated_leave: formData?.enable_sys_generated_leave,
        max_absent_allowed: formData?.max_absent_allowed,
        allow_special_credits: formData?.allow_special_credits,
      }
      let response = await axiosInstance.put(API.updateOrganisationBasicSetting(orgId), body);
      if (response.status === 200) {
        showAlert(response?.data?.message);
      } else {
        showAlert("Something went wrong", "error");
      }
    } catch (error) {
      console.error(error)
      showAlert("Something went wrong", "error");
    } finally {
      setLoading((prev) => ({ ...prev, formSubmitting: false }));
      setIsEditable(false);
    }
  };

  return (loading.pageLoading ?
    <CircularLoader /> :
    <Box display="flex" flexDirection="column" sx={{ width: '100%' }} p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} height={"100%"} overflow="auto">
      <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Stack spacing={4} mb={4}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
              <Grid container maxWidth={850} width={"100%"}>
                <Grid item mr={3} flex={1}>
                  <Input
                    id="name-type"
                    select
                    type="select"
                    label="Select Yearly Renewal Month"
                    name="renewal_month"
                    variant="standard"
                    sx={{ width: "100%" }}
                    value={formData?.renewal_month || ""}
                    required={true}
                    disabled={true}
                  >
                    {months?.map(
                      (month, index) => (
                        <MenuItem
                          key={index}
                          value={month}
                        >
                          {month}
                        </MenuItem>
                      )
                    )}
                  </Input>
                </Grid>
                <Grid item flex={1}>
                  <Input
                    id="max-absent"
                    name="max_absent_allowed"
                    label="Max Absent (%)"
                    variant="standard"
                    type="number"
                    isDays={true}
                    inputProps={{ min: 0, max: 100 }}
                    min={0}
                    max={100}
                    sx={{ width: "100%" }}
                    // InputLabelProps={{
                    //     shrink: !!(formData?.max_absent_allowed) || formData?.max_absent_allowed === 0,
                    // }}
                    onChange={(e) => handleMaxAbsentChange(e)}
                    onBlur={(e) => handleMaxAbsentChange(e)}
                    value={formData?.max_absent_allowed}
                    error={!!errors["max_absent_allowed"]}
                    helperText={errors["max_absent_allowed"]}
                    disabled={!isEditable}
                  />
                  <Box>
                    <HtmlTooltip
                      arrow
                      title={
                        <React.Fragment>
                          <Typography sx={{ fontSize: '13px !important' }} textAlign={"center"}>% of team allowed to be Absent on any given day</Typography>
                        </React.Fragment>
                      }
                    >
                      <Typography
                        display="inline-block"
                        color="primary"
                        fontSize={12}
                        sx={{ cursor: "pointer" }}
                      >
                        Help
                      </Typography>
                    </HtmlTooltip>
                  </Box>
                </Grid>
              </Grid>
              {!isEditable ?
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                      boxShadow: "0",
                      color: "white",
                      fontSize: "14px",
                      paddingTop: '6px',
                      paddingBottom: '6px',
                      textTransform: "none",
                      "&:hover": { boxShadow: "0" },
                    }}
                    type="button"
                    onClick={() => setIsEditable(!isEditable)}
                  >
                    Edit
                  </Button>
                </Grid>
                : null}
            </Box>
            <Grid item>
              <Typography color="text.dark" fontSize={14} mb={1} sx={{ lineHeight: '24px' }}>
                Leave Availability Frequency
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="leave_frequency"
                  defaultValue="monthly"
                  value={formData?.leave_frequency}
                  onChange={() => { }}
                >
                  <FormControlLabel
                    value="monthly"
                    control={<Radio />}
                    label="Monthly"
                    sx={{ color: "dark.800" }}
                    disabled={true}
                  />
                  <FormControlLabel
                    value="yearly"
                    control={<Radio />}
                    label="Annually"
                    sx={{ color: "dark.800" }}
                    disabled={true}
                  />
                </RadioGroup>
                <FormHelperText error={false} sx={{ p: 0, m: 0 }}></FormHelperText>
              </FormControl>
              <Box>
                <Typography color="dark.600" fontSize={12}>
                  Set frequency of adding leaves into the leave balance.
                </Typography>
                <Typography color="dark.600" fontSize={12} component={"div"}>
                  With <b>Monthly</b> option, candidate will receive{" "}
                  <Tooltip title="monthly" arrow>
                    <Typography
                      display="inline-block"
                      fontSize={12}
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      monthly
                    </Typography>
                  </Tooltip>{" "}
                  leaves at the beginning of every month throughout the year based on
                  Yearly Leave set below.
                </Typography>
                <Typography color="dark.600" fontSize={12} component={"div"}>
                  With <b>Annually</b> option, candidate will receive all the yearly
                  leaves at the beginning of the Year.
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center" mb={1}>
                <Typography color="text.dark" fontSize={14} mr={1} sx={{ lineHeight: '24px' }}>
                  System Generated Leaves
                </Typography>
                <HtmlTooltip arrow placement="top"
                  title={
                    <React.Fragment>
                      <Typography color={"white"} textAlign={"center"} fontSize={16} fontWeight={500} lineHeight={"24px"}>System will automatically creates a leave upon unavailability for the user.</Typography>
                    </React.Fragment>
                  }
                >
                  <i style={{ width: '18px', height: '18px' }}><HelpOutlineIcon sx={{ fontSize: '18px', color: 'secondary.main' }} /></i>
                </HtmlTooltip>
              </Box>
              <Typography color="dark.600" fontSize={12}>
                System generated leaves will be enabled for all work type team members like In-office, Remote, Hybrid.
              </Typography>
              <Typography color="dark.600" fontSize={12} mb={1}>
                This setting can also be maintained at individual work-type as well as specific user level.
              </Typography>
              <FormControlLabel
                id='enable_sys_generated_leave'
                name='enable_sys_generated_leave'
                label={`Enable System Generated Leaves for all work-types`}
                labelPlacement="end"
                control={<Checkbox checked={formData?.enable_sys_generated_leave} />}
                onChange={(e) =>
                  handleChangeCheckBox(e)
                }
                disabled={!isEditable}
              />
            </Grid>
            <Grid item>
              <Grid display="flex" alignItems="center" mb={1}>
                <Typography color="text.dark" fontSize={14} mr={1} sx={{ lineHeight: '24px' }}>
                  Special Credits
                </Typography>
                <i style={{ width: '18px', height: '18px' }}><HelpOutlineIcon sx={{ fontSize: '18px', color: 'secondary.main' }} /></i>
              </Grid>
              <FormControlLabel
                id='allow_special_credits'
                name='allow_special_credits'
                label={`Enable Special credits feature`}
                labelPlacement="end"
                control={<Checkbox checked={formData?.allow_special_credits} />}
                onChange={(e) =>
                  handleChangeCheckBox(e)
                }
                disabled={!isEditable}
              />
            </Grid>
            {isEditable ? <Grid item>
              <Button
                type="submit"
                color="primary"
                sx={{
                  boxShadow: 0,
                  color: "white",
                  fontSize: "15px",
                  textTransform: "none",
                  mr: 2.4,
                  "&:hover": { boxShadow: 0 },
                }}
                disabled={!!errors?.max_absent_allowed}
                isButtonLoading={loading?.formSubmitting}
              >
                Update
              </Button>
              <Button
                variant="text"
                color="secondary"
                sx={{ textTransform: "none" }}
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
            </Grid> : null}
          </Stack>
        </form>
      </Box>
    </Box>
  )
}

export default BasicSettings;
