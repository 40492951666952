import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./texteditor.css";

const TextEditor = ({ value, onChange, readOnly = false, ...rest }) => {

  const modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        "align",
        { list: "ordered" },
        { list: "bullet" },
      ],
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // ["bold", "italic", "underline", "strike", "blockquote"],
      // [{ align: ["right", "center", "justify"]}],
      // [{ list: "ordered" }, { list: "bullet" }],
      // ["link", "image"],
      // [{ color: myColors }],
      // [{ background: myColors }],
    ],
  };

  const formats = [
    // "header",
    "bold",
    "italic",
    "underline",
    // "strike",
    // "blockquote",
    "list",
    "bullet",
    // "link",
    // "color",
    // "image",
    // "background",
    // "align",
  ];

  const [code, setCode] = useState(value || "");

  useEffect(() => {
    const updatedContent = value ? value?.replace(/(\S)\s{2,}(\S)/g, '$1&nbsp;&nbsp;$2') : "";

    setCode(updatedContent || "");
  }, [value]);

  const handleProcedureContentChange = (content) => {
    const updatedContent = content?.replace(/(\S)\s{2,}(\S)/g, '$1&nbsp;&nbsp;$2');
    setCode(updatedContent);
    onChange(updatedContent);
  };

  const handleProcedureContentBlur = (content) => {
    const trimmedContent = code?.replace(/(<[^>]+>)(\s*&nbsp;)+|(&nbsp;\s*)+(<\/[^>]+>)/g, '$1$4');
    setCode(trimmedContent);
  }

  return (
    <ReactQuill
      id="editor-0"
      name="editor-0"
      placeholder="Type Here"
      theme="snow"
      modules={readOnly ? { toolbar: false } : modules}
      formats={formats}
      value={code}
      onChange={handleProcedureContentChange}
      onChangeSelection={(range, source, editor) => {
        if (!range && source === 'user') {
          handleProcedureContentBlur();
        }
      }}
      readOnly={readOnly}
      InputProps={{ id : "editor" }}
      {...rest}
    />
  );
};

export default TextEditor;
