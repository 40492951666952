/* eslint-disable react-hooks/exhaustive-deps */
// SearchBoxInput.js
import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import useDebounce from "../../hook/useDebounce";

const SearchBoxInput = ({
  label = "Standard",
  variant = "standard",
  placeholder,
  handleSearch,
  isUseDebounce = true,
  isOverwritePlaceholder = false,
  ...rest
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm);

  useEffect(() => {
    handleSearch(isUseDebounce ? debouncedSearchTerm : searchTerm);
  }, [isUseDebounce ? debouncedSearchTerm : searchTerm]);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <TextField
      label={label}
      variant={variant}
      placeholder={isOverwritePlaceholder ? placeholder : `Type ${placeholder}`}
      type="search"
      value={searchTerm}
      onChange={handleChange}
      {...rest}
    />
  );
};

export default SearchBoxInput;
