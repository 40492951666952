/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Link,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import Button from "../../../components/Button";
import DummyPhoto from "../../../assets/images/dummy-photo.svg";
import styled from "@emotion/styled";
import { useNavigate, Link as routerLink } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosInstance from "../../../axios";
import API from "../../../axios/api";
import { useAlert } from "../../../hook/useAlert";
import Input from "../../../components/Input";
import { UrlSplitter, convertToBase64, generateUniqueId, getMaxSize, titleCase } from "../../../utils";
import { ON_BOARDING_STEPS, PROFILE_IMAGE_SIZE, VALID_IMAGE_TYPE } from "../../../constants/default-values";
import URLS from "../../../routes/urls";
import CircularLoader from "../../../components/CircularLoader";
import { checkOrganisationUniqueName } from "../../../axios/service";
import { usePageTitle } from "../../../hook/useTitle";
import { useDispatch } from "react-redux";
import { setUserOnBoardingStep } from "../../../redux/slices/userSlice";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const BasicInfoStep = ({ setOnBoardingStep, currentUser }) => {
  const showAlert = useAlert();
  const dispatch = useDispatch();
  const { setPageTitle } = usePageTitle();
  const navigate = useNavigate();
  const [basicInfoData, setBasicInfoData] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState({
    formSubmitting: false,
    pageLoading: true,
  });

  setPageTitle("Create a Organisation");

  useEffect(() => {
    const getOrganisationData = async () => {
      try {
        setLoading((prev) => ({ ...prev, pageLoading: true }));
        const response = await axiosInstance.get(
          API.organisationBasicInfo(currentUser?.organization_id)
        );
        if (response.status === 200) {
          setBasicInfoData(response.data.data);
          const previewProfileImage = response.data?.data?.profile_img;
          if (previewProfileImage) setPreviewImage(previewProfileImage);
        }
        setLoading((prev) => ({ ...prev, pageLoading: false }));
      } catch (error) {
        console.error(error);
        setLoading((prev) => ({ ...prev, pageLoading: false }));
      }
    };
    if (currentUser?.organization_id)
      getOrganisationData();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      alias: "",
      profile_img: null,
      ...basicInfoData,
      protocol: UrlSplitter(basicInfoData?.sub_domain)?.protocol || "http://",
      sub_domain: UrlSplitter(basicInfoData?.sub_domain)?.subDomain?.toLowerCase(),
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      alias: Yup.string().required("Required"),
      sub_domain: Yup.string()
        .required("Required")
        .max(63, "Subdomain must be at most 63 characters")
        .matches(/\.[a-zA-Z]{2,}$/, "Invalid domain"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading((prev) => ({ ...prev, formSubmitting: true }));
        let sub_domain = values.protocol + values.sub_domain?.toLowerCase();
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("alias", values.alias);
        formData.append("sub_domain", sub_domain);
        formData.append("id", values.id);
        formData.append("active", values.active);
        formData.append("onboarding_status", values.onboarding_status);
        let base64 = null;
        if (values?.profile_img) {
          if (values?.profile_img instanceof Blob || values?.profile_img instanceof File) {
            base64 = await convertToBase64(values?.profile_img);
          } else {
            base64 = values?.profile_img?.base64;
          }
          formData.append(`profile_img[${0}][name]`, values?.profile_img?.name);
          formData.append(`profile_img[${0}][size]`, values?.profile_img?.size);
          formData.append(`profile_img[${0}][type]`, values?.profile_img?.type);
          formData.append(`profile_img[${0}][base64]`, base64);
          formData.append(`profile_img[${0}][extension]`, `.${values?.profile_img?.name?.split('.').pop()}`);
        }

        const response = await axiosInstance.put(
          API.organisationBasicInfo(currentUser?.organization_id),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.status === 200) {
          showAlert(response.data.message);
          navigate(`${URLS.onBoarding}/${ON_BOARDING_STEPS.step2}`, {
            replace: true,
          });
          setOnBoardingStep(ON_BOARDING_STEPS.step2);
          dispatch(setUserOnBoardingStep(ON_BOARDING_STEPS.step2));
        } else {
          showAlert(response?.response?.data?.message, "error");
        }
        setLoading((prev) => ({ ...prev, formSubmitting: false }));
      } catch (error) {
        setLoading((prev) => ({ ...prev, formSubmitting: false }));
        console.error("Error:", error);
        showAlert(error?.response?.data?.message, "error");
      }
    },
  });

  const handleChangeProfileImage = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (VALID_IMAGE_TYPE.includes(file.type)) {
        if (file.size <= PROFILE_IMAGE_SIZE) {
          const previewURL = URL.createObjectURL(file);
          setPreviewImage(previewURL);
          let _file = Object.assign(file, {
            preview: URL.createObjectURL(file),
            unique_id: generateUniqueId(),
          });
          formik.setFieldValue(`profile_img`, _file);
        }
      } else {
        showAlert(`File size exceeds ${getMaxSize(PROFILE_IMAGE_SIZE)}MB limit. Please choose a smaller file.`, "error");
        formik.setFieldValue("profile_img", null);
      }
    } else {
      showAlert('Only JPEG and PNG file types are allowed.', "error");
      formik.setFieldValue("profile_img", null);
    }
  };

  const handleCheckUniqueOrganisationName = async (name) => {
    try {
      if (name !== basicInfoData?.name) {
        const { isExist } = await checkOrganisationUniqueName(name);
        if (isExist) {
          formik.setErrors({
            ...formik.errors,
            name:
              "Organization name already exists. Please choose a unique name.",
          });
        }
        return isExist;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
      <form onSubmit={formik.handleSubmit} className="step-form-1">
        <Stack spacing={4} sx={{ flexBasis: "100%" }}>
          <Typography color="secondary.800" fontSize={20}>
            Create a Organisation (Step 1 of 5)
          </Typography>
          <Grid item>
            <Typography
              color="secondary"
              fontSize={{ xs: 28, lg: 32 }}
              lineHeight={{ xs: "38px", lg: "44px" }}
              mb={2}
            >
              Let's start
            </Typography>
            <Grid display={"flex"}>
              <Typography variant="body2" color="dark.800">
                Hello,
              </Typography>
              <Typography
                variant="body2"
                color="dark.800"
                fontWeight={"bold"}
                sx={{ textTransform: "capitalize", margin: "0px 4px" }}
              >
                {titleCase(basicInfoData.name)}
              </Typography>
              <Typography variant="body2" color="dark.800">
                ({currentUser?.email}),
              </Typography>
            </Grid>
            <Typography variant="body2" color="dark.800">
              You are invited to TechLogin as organisation. Get start with your
              details.
            </Typography>
          </Grid>
          {loading.pageLoading ?
            <CircularLoader /> :
            <>
              <Grid item>
                <Typography
                  variant="body1"
                  color="dark.800"
                  mb={1}
                  sx={{ textTransform: "capitalize" }}
                >
                  {`${basicInfoData?.name ? `${titleCase(basicInfoData?.name)} Logo` : ""} `}
                </Typography>
                <Grid display="flex" alignItems="center">
                  <Box
                    mr={2.5}
                    sx={{
                      height: "90px",
                      width: "150px",
                      background: "rgba(63, 92, 118, 0.09)",
                      borderRadius: "6px",
                      overflow: "hidden",
                    }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{ height: "100%", width: "100%" }}
                    >
                      <img
                        src={previewImage || DummyPhoto}
                        alt="Techcompose"
                        width={"100%"}
                        className="imgcolver"
                      />
                    </Box>
                  </Box>
                  <Button
                    component="label"
                    variant="text"
                    color="primary"
                    fontSize={13}
                    sx={{ textTransform: "none" }}
                  >
                    Change
                    <VisuallyHiddenInput
                      name="profile_img"
                      type="file"
                      onChange={handleChangeProfileImage}
                      accept="image/*"
                    />
                  </Button>
                </Grid>
              </Grid>
              <Grid container display={"flex"} maxWidth={700} width={"100%"} gap={4}>
                <Grid item maxWidth={"100%"} sx={{ width: 'calc(50% - 16px)' }}>
                  <Input
                    id="organisation-name"
                    label="Organisation name*"
                    variant="standard"
                    placeholder="organisation name"
                    type="text"
                    name={"name"}
                    fullWidth
                    required={false}
                    {...formik.getFieldProps("name")}
                    value={titleCase(formik.getFieldProps("name")?.value)}
                    onBlur={(e) => {
                      formik.handleBlur(e);
                      handleCheckUniqueOrganisationName(e.target.value);
                    }}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  // isUpperCase={true}
                  />
                </Grid>
                <Grid item maxWidth={"100%"} sx={{ width: 'calc(50% - 16px)' }}>
                  <Input
                    id="alias"
                    label="Alias"
                    variant="standard"
                    placeholder="alias name"
                    type="text"
                    fullWidth
                    name="alias"
                    {...formik.getFieldProps("alias")}
                    error={formik.touched.alias && Boolean(formik.errors.alias)}
                    helperText={formik.touched.alias && formik.errors.alias}
                  />
                </Grid>
              </Grid>
              <Grid container display={"flex"} maxWidth={700} width={"100%"} gap={4}>
                <Grid item maxWidth={"100%"} sx={{ width: 'calc(50% - 16px)' }} display="flex">
                  <Grid item>
                    <Input
                      id={"protocol"}
                      name={"protocol"}
                      select
                      type="select"
                      label="Domain"
                      defaultValue="https://"
                      variant="standard"
                      sx={{ width: "100%" }}
                    
                      {...formik.getFieldProps("protocol")}
                    >

                      <MenuItem value={"https://"}>
                        https://
                      </MenuItem>

                      <MenuItem value={"http://"}>
                        http://
                      </MenuItem>
                    </Input>
                  </Grid>

                  <Grid item sx={{ flexBasis: "100%", mt: 2 }}>
                    <Input
                      id="website"
                      label=""
                      variant="standard"
                      placeholder="your domain"
                      type="text"
                      name="sub_domain"
                      fullWidth
                      {...formik.getFieldProps("sub_domain")}
                      value={formik.getFieldProps("sub_domain")?.value?.toLowerCase()}
                      error={
                        formik.touched.sub_domain && Boolean(formik.errors.sub_domain)
                      }
                      helperText={
                        formik.touched.sub_domain && formik.errors.sub_domain
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Typography variant="body2" color="dark.800">
                By clicking on continue, I agree the{" "}
                <Link
                  component={routerLink}
                  color="primary.main"
                  sx={{ textDecoration: "none", color: "primary.main" }}
                >
                  Terms of Service
                </Link>{" "}
                and I acknowledge our{" "}
                <Link
                  component={routerLink}
                  color="primary.main"
                  sx={{ textDecoration: "none", color: "primary.main" }}
                >
                  Privacy Policies
                </Link>
              </Typography>
            </>}
        </Stack>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={4}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            flexBasis="100%"
          >
            <Button
              variant="contained"
              size="large"
              type="submit"
              sx={{
                boxShadow: "0",
                fontSize: "15px",
                textTransform: "none",
                color: "white",
                "&:hover": { boxShadow: "0" },
              }}
              disabled={Boolean(Object.keys(formik.errors).length)}
              isButtonLoading={loading.formSubmitting}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default BasicInfoStep;