/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  Card,
  Container,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import Button from "../../../components/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link as RouteLink, useParams, useNavigate } from "react-router-dom";
import URLS from "../../../routes/urls";
import Input from "../../../components/Input";
import { useAlert } from "../../../hook/useAlert";
import API from "../../../axios/api";
import axiosInstance from "../../../axios";
import { checkOrganisationUniqueName, handleCheckIsEmailUnique } from "../../../axios/service";
import { requireField, validateEmail } from "../../../utils/validation";
import AutocompleteInput from "../../../components/AutocompleteInput";
import { generateUniqueId, getInputId, sortArrayBaseOnRole, titleCase } from "../../../utils";
import CircularLoader from "../../../components/CircularLoader";
import { usePageTitle } from "../../../hook/useTitle";
import { styled } from "@mui/styles";

const initialFormData = {
  name: "",
  org_admins: [],
  active: true,
};

const initialOrgAdminObject = {
  id: null,
  email: "",
  name: "",
  active: true,
  admin_status: "admin",
  is_newly_added: true,
  unique_id: generateUniqueId(),
};
const EditOrganisation = () => {
  const navigate = useNavigate();
  const { orgId } = useParams();
  const showAlert = useAlert();
  const { setPageTitle } = usePageTitle();
  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState([]);
  const [emailErrors, setEmailErrors] = useState({});
  const [loading, setLoading] = useState({
    formSubmitting: false,
    pageLoading: true,
  });
  const [nameErrors, setNameErrors] = useState({});
  const [organisationData, setOrganisationData] = useState({});

  setPageTitle("Edit Organisations");
  const findIndexWithUid = (id) =>
    formData.org_admins.findIndex((data) => data.unique_id === id);

  useEffect(() => {
    const getOrganisationData = async () => {
      try {
        setLoading((prev) => ({ ...prev, pageLoading: true }));
        const response = await axiosInstance.get(API.getOrganization(orgId));
        if (response.status === 200) {
          let newResponse = {
            ...response?.data?.data,
            org_admins: [
              ...response?.data?.data?.org_admins?.map((data) => ({
                ...data,
                name: data?.name?.trim(),
                unique_id: generateUniqueId(),
              })),
              initialOrgAdminObject,
            ],
          };
          setOrganisationData(response?.data?.data);
          setFormData({ ...newResponse, org_admins: sortArrayBaseOnRole(newResponse?.org_admins) });
        } else {
          showAlert(response.data?.message);
        }
        setLoading((prev) => ({ ...prev, pageLoading: false }));
      } catch (error) {
        console.error(error);
        setLoading((prev) => ({ ...prev, pageLoading: false }));
      }
    };
    if (orgId) {
      getOrganisationData();
    }
  }, [orgId]);

  const handleChange = (e, field) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
    setErrors((errors) => ({
      ...errors,
      [field]: requireField(e.target.value, field),
    }));
  };

  const validateData = async (formData) => {
    let errors = {};
    const orgNameError = requireField(formData.name, "name") || await handleCheckUniqueOrganisationName(formData?.name, formData?.id) === true ? requireField("exists", "name") : "";
    if (orgNameError) {
      errors["name"] = orgNameError;
    }

    setErrors(errors);
    return Object.keys(errors).length > 0;
  };

  const handleSubmit = async (e) => {
    try {
      setLoading((prev) => ({ ...prev, formSubmitting: true }));
      e.preventDefault();
      if (! await validateData(formData)) {
        let filteredOrgAdmin = formData.org_admins.filter(
          (data) => data.email?.trim() !== ""
        );

        const inactiveAdmins = organisationData.org_admins
          .filter(
            (existingAdmin) =>
              !filteredOrgAdmin.some(
                (formDataAdmin) => formDataAdmin.id === existingAdmin.id
              )
          ).map((inactiveAdmin) => ({
            ...inactiveAdmin,
            active: false,
            admin_status: "admin",
            is_newly_added: false,
            is_deleted: true,
          }));

        const body = {
          ...formData,
          id: formData.id,
          name: formData.name?.trim(),
          active: ["true", true].includes(formData.active),
          org_admins: [
            ...filteredOrgAdmin.map((admin, index) => ({
              id: admin.id,
              email: admin?.email,
              name: admin?.name?.trim(),
              active: admin.active,
              admin_status: index === 0 ? "primary_admin" : "admin",
              is_newly_added: !admin?.id,
            })),
            ...inactiveAdmins,
          ],
        };

        const response = await axiosInstance.put(
          `${API.createOrganization}/${body.id}`,
          body
        );
        if (response.status === 200) {
          setLoading((prev) => ({ ...prev, formSubmitting: false }));
          showAlert(response.data.message);
          navigate(URLS.AdminDashboard);
        } else {
          setLoading((prev) => ({ ...prev, formSubmitting: false }));
          showAlert(response?.response?.data?.message, "error");
        }
      } else {
        setLoading((prev) => ({ ...prev, formSubmitting: false }));
      }
    } catch (error) {
      console.error(error);

      setLoading((prev) => ({ ...prev, formSubmitting: false }));
    }
  };

  const handleCheckUniqueOrganisationName = async (orgName, orgId) => {
    try {
      const response = await checkOrganisationUniqueName(orgName, orgId);
      if (response?.isExist) {
        setErrors((errors) => ({
          ...errors,
          name: requireField("exists", "name"),
        }));
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeEmail = (unique_id, value, index) => {
    const findIndex = findIndexWithUid(unique_id);
    const updatedEmailAddresses = [...formData.org_admins];
    const updatedObject = { ...updatedEmailAddresses[findIndex], email: value };
    updatedEmailAddresses[findIndex] = updatedObject;

    if (value !== "" && index === updatedEmailAddresses.length - 1) {
      initialOrgAdminObject.unique_id = generateUniqueId();
      updatedEmailAddresses.push(initialOrgAdminObject);
    }

    setFormData({
      ...formData,
      org_admins: updatedEmailAddresses,
    });
  };

  const handleChangeName = (unique_id, value, index) => {
    const findIndex = findIndexWithUid(unique_id);
    const updatedNames = [...formData.org_admins];
    const updatedObject = { ...updatedNames[findIndex], name: value };
    updatedNames[findIndex] = updatedObject;

    if (value !== "" && index === updatedNames.length - 1) {
      initialOrgAdminObject.unique_id = generateUniqueId();
      updatedNames.push(initialOrgAdminObject);
    }

    setFormData({
      ...formData,
      org_admins: updatedNames,
    });
  };

  const handleCheckName = (unique_id, e, index) => {
    const nameValue = e.target.value;
    const isInvalidName = nameValue?.trim() && requireField(nameValue, "name");
    let nameErrors = {};
    if (index !== formData?.org_admins?.length - 1) {
      if (isInvalidName) {
        nameErrors[unique_id] = "Full name is required";
      } else if (nameValue?.length < 3) {
        nameErrors[unique_id] = "Full name have at least 3 characters";
      } else {
        nameErrors[unique_id] = "";
      }

      setNameErrors((prev) => ({
        ...prev,
        ...nameErrors,
      }));
    }
  };

  const handleCheckExistingAdmin = async (unique_id, e, index, userData) => {
    const emailValue = e.target.value;
    const isEmailUnique = await handleCheckIsEmailUnique(emailValue, userData?.id);
    const checkExistUser = formData.org_admins.filter(
      (obj) => emailValue && obj.email && obj.email === emailValue
    );
    const findExistEmail =
      index === 0
        ? formData.org_admins.slice(1).find((data) => data.email === emailValue)
          ?.unique_id
        : formData.org_admins[index]?.unique_id;

    const isInvalidEmail =
      emailValue?.trim() && !validateEmail(emailValue?.trim());

    const emailErrors = {};

    if (isInvalidEmail) {
      emailErrors[unique_id] = "Invalid email address";
    } else if (isEmailUnique || checkExistUser.length > 1) {
      emailErrors[findExistEmail] = "Email is already exist";
    } else if (!findExistEmail) {
      setEmailErrors({});
    } else {
      const isFirstEmailEmpty = !formData.org_admins[0]?.email?.trim()?.length;
      emailErrors[unique_id] = isFirstEmailEmpty
        ? "Select at least one email address"
        : "";
    }

    setEmailErrors((prev) => ({
      ...prev,
      ...emailErrors,
    }));
  };

  const handleRemoveAdmin = (unique_id) => {
    const updatedEmailAddresses = [...formData.org_admins];
    const removedObject = updatedEmailAddresses.filter(
      (data) => data.unique_id !== unique_id
    );

    setFormData({
      ...formData,
      org_admins: [...removedObject],
    });

    setEmailErrors((prev) => ({
      ...prev,
      [unique_id]: "",
    }));

    setNameErrors((prev) => ({
      ...prev,
      [unique_id]: "",
    }));
  };

  const handleCheckDisableSubmit = () => {
    const hasEmailErrors = Object.values(emailErrors).some((value) => !!value);
    const hasNameErrors = Object.values(nameErrors).some((value) => !!value);
    const hasFormErrors = Object.values(errors).some((value) => !!value);
    const hasEmptyEmail = formData.org_admins?.some((data) => !data?.email);
    const hasEmptyName = formData.org_admins?.some((data) => !data?.name);

    return (
      hasEmailErrors ||
      hasFormErrors ||
      !hasEmptyEmail ||
      hasNameErrors ||
      !hasEmptyName
    );
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#3F5C76',
      color: '#ffffff',
      maxWidth: 310,
      padding: 12,
    },
  }));

  return (
    <Box sx={{ marginTop: "-190px" }} position="relative" zIndex={1}>
      <Container>
        <Link
          component={RouteLink}
          to={URLS.AdminDashboard}
          display="inline-flex"
          alignItems="center"
          sx={{
            textDecoration: "none",
            color: "white",
            "&:hover": { opacity: "0.75" },
          }}
          fontFamily={"fontFamily"}
          p={0.5}
          mb={1.5}
        >
          <ChevronLeftIcon fontSize="small" sx={{ mr: 1 }} />
          Back to Organisations
        </Link>
        <Typography variant="h6" color="white" marginBottom="12px">
          Edit Organisations
        </Typography>
        <Card
          sx={{ bgcolor: "bgColor", p: { xs: 2, sm: 3, md: 4, boxShadow: 1 } }}
        >
          {loading.pageLoading ?
            <CircularLoader height="500px" /> :
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={9}>
                  <Stack spacing={3}>
                    <Grid item xs={7}>
                      <Input
                        id="organisation-name"
                        name="name" // Match the name with the initialValues key
                        label="Organisation name"
                        variant="standard"
                        placeholder="organisation name"
                        type="text"
                        fullWidth
                        onChange={(e) => handleChange(e, "name")}
                        onBlur={(e) => {
                          handleCheckUniqueOrganisationName(
                            e.target.value,
                            organisationData?.id
                          );
                        }}
                        value={titleCase(formData?.name)}
                        error={!!errors["name"]}
                        helperText={errors["name"]}
                      // isUpperCase={true}
                      />
                    </Grid>
                    <Grid pb={3}>
                      <Typography variant="body1" mb={1}>
                        Organisation Status
                      </Typography>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="active"
                        sx={{ flexDirection: "row" }}
                        onChange={(e) => handleChange(e, "active")}
                        value={formData.active}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Active"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="Deactive"
                        />
                      </RadioGroup>
                    </Grid>
                    <Typography variant="body1">Invite admin</Typography>
                    <Stack spacing={4}>
                      {formData?.org_admins?.map(
                        ({ email, name, unique_id }, index, array) => (
                          <Grid key={index} display="flex">
                            {index === 0 ? (
                              <Stack
                                direction="row"
                                spacing={2}
                                display={"flex"}
                                sx={{ width: "100%" }}
                              >
                                <Typography
                                  minWidth={30}
                                  sx={{ paddingTop: "18px" }}
                                >
                                  {index + 1}
                                </Typography>
                                <Grid
                                  display={"flex"}
                                  flex={1}
                                  sx={{ marginLeft: "0px !important" }}
                                >
                                  <Input
                                    id={`full-name-${index}`}
                                    name={`org_admins.${index}`}
                                    label={"Full Name(PRIMARY)"}
                                    variant="standard"
                                    placeholder={"Full Name (PRIMARY)"}
                                    type="text"
                                    fullWidth
                                    onChange={(e) =>
                                      handleChangeName(
                                        unique_id,
                                        e.target?.value || "",
                                        index
                                      )
                                    }
                                    onBlur={(e) =>
                                      handleCheckName(unique_id, e, index)
                                    }
                                    value={
                                      titleCase(formData?.org_admins[
                                        findIndexWithUid(unique_id)
                                      ]?.name)
                                    }
                                    error={!!nameErrors[unique_id]}
                                    helperText={nameErrors[unique_id]}
                                  />
                                </Grid>
                                <Grid display={"flex"} flexDirection={"column"} flex={1}>
                                  <AutocompleteInput
                                    id={`email-address-${index}`}
                                    name={`org_admins.${index}`}
                                    fullWidth
                                    variant="standard"
                                    label={"Email address (PRIMARY)"}
                                    placeholder={"email address (PRIMARY)"}
                                    options={organisationData?.org_admins}
                                    getOptionLabel={(e) => e.email || email}
                                    onChange={(value) =>
                                      handleChangeEmail(
                                        unique_id,
                                        value?.email || "",
                                        index
                                      )
                                    }
                                    onBlur={(e) =>
                                      handleCheckExistingAdmin(
                                        unique_id,
                                        e,
                                        index,
                                        formData.org_admins[findIndexWithUid(unique_id)]
                                      )
                                    }
                                    value={
                                      formData.org_admins[
                                        findIndexWithUid(unique_id)
                                      ]?.email
                                    }
                                    isOptionEqualToValue={(option, value) =>
                                      (option?.email || email) === value
                                    }
                                    error={!!emailErrors[unique_id]}
                                    helperText={emailErrors[unique_id]}
                                  />
                                  <Box>
                                    <HtmlTooltip
                                      arrow
                                      title={
                                        <React.Fragment>
                                          <Typography sx={{ fontSize: '13px !important' }} textAlign={"center"}>Primary admin email</Typography>
                                        </React.Fragment>
                                      }
                                    >
                                      <Typography
                                        display="inline-block"
                                        color="primary"
                                        fontSize={12}
                                        sx={{ cursor: "pointer" }}
                                      >
                                        Help
                                      </Typography>
                                    </HtmlTooltip>
                                  </Box>
                                </Grid>
                              </Stack>
                            ) : (
                              <Stack
                                direction="row"
                                spacing={2}
                                display={"flex"}
                                sx={{ width: "100%" }}
                              >
                                <Typography
                                  minWidth={30}
                                  sx={{ paddingTop: "18px" }}
                                >
                                  {index + 1}
                                </Typography>
                                <Grid
                                  display={"flex"}
                                  flex={1}
                                  sx={{ marginLeft: "0px !important" }}
                                >
                                  <Input
                                    autoComplete="off"
                                    id={getInputId("names", index)}
                                    name={`names.${index}`}
                                    label={"Full Name"}
                                    variant="standard"
                                    placeholder={"full name"}
                                    type="text"
                                    fullWidth
                                    onChange={(e) =>
                                      handleChangeName(
                                        unique_id,
                                        e.target.value,
                                        index
                                      )
                                    }
                                    value={name?.length ?
                                      titleCase(formData?.org_admins[
                                        findIndexWithUid(unique_id)
                                      ]?.name) : ''
                                    }
                                    onBlur={(e) =>
                                      handleCheckName(unique_id, e, index)
                                    }
                                    error={!!nameErrors[unique_id]}
                                    helperText={nameErrors[unique_id]}
                                    InputLabelProps={{
                                      shrink: true,
                                      htmlFor: getInputId("names", index),
                                    }}
                                    required={index !== array?.length - 1}
                                  />
                                </Grid>
                                <Grid display={"flex"} flex={1}>
                                  <Input
                                    autoComplete="off"
                                    id={getInputId("email-address", index)}
                                    name={`org_admins.${index}`}
                                    label={"Email address"}
                                    variant="standard"
                                    placeholder={"email address"}
                                    type="email"
                                    fullWidth
                                    onChange={(e) =>
                                      handleChangeEmail(
                                        unique_id,
                                        e.target.value,
                                        index
                                      )
                                    }
                                    value={
                                      formData.org_admins[
                                        findIndexWithUid(unique_id)
                                      ]?.email
                                    }
                                    onBlur={(e) =>
                                      handleCheckExistingAdmin(
                                        unique_id,
                                        e,
                                        index,
                                        formData.org_admins[findIndexWithUid(unique_id)]
                                      )
                                    }
                                    error={!!emailErrors[unique_id]}
                                    helperText={emailErrors[unique_id]}
                                    InputLabelProps={{
                                      shrink: true,
                                      htmlFor: getInputId("email-address", index),
                                    }}
                                    required={index !== array?.length - 1}
                                  />
                                </Grid>
                              </Stack>
                            )}
                            <Box sx={{ minWidth: "70px" }}>
                              {index !== 0 &&
                                index !== formData.org_admins.length - 1 ? (
                                <IconButton
                                  size="small"
                                  sx={{
                                    width: "40px",
                                    height: "40px",
                                    ml: 4,
                                    mt: 1,
                                  }}
                                  onClick={() => handleRemoveAdmin(unique_id)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              ) : null}
                            </Box>
                          </Grid>
                        )
                      )}
                    </Stack>
                    <Grid item>
                      <Button
                        type="submit"
                        color="primary"
                        sx={{
                          boxShadow: 0,
                          color: "white",
                          fontSize: "15px",
                          textTransform: "none",
                          mr: 2.4,
                          "&:hover": { boxShadow: 0 },
                        }}
                        disabled={handleCheckDisableSubmit()}
                        isButtonLoading={loading?.formSubmitting}
                      >
                        Update
                      </Button>
                      <Button
                        variant="text"
                        color="secondary"
                        sx={{ textTransform: "none" }}
                        onClick={() => navigate(URLS.AdminDashboard)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          }
        </Card>
      </Container>
    </Box>
  );
};

export default EditOrganisation;
