/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Chip,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import axiosInstance from "../../../axios";
import API from "../../../axios/api";
import CircularLoader from "../../../components/CircularLoader";
import {
  getFullName,
  titleCase,
} from "../../../utils/index";
import { useNavigate, useParams } from "react-router-dom";
import UserInformation from "./UserTabPages/UserInformation";
import UserProjects from "./UserTabPages/UserProjects";
import UserLeave from "./UserTabPages/UserLeave";
import UserRoles from "./UserTabPages/UserRoles";
import UserReports from "./UserTabPages/UserReports";
import useQuery from "../../../hook/useQuery";
import { usePageTitle } from "../../../hook/useTitle";
import BackgroundLetterAvatars from "../../../components/BackgroundLetterAvatars";
import Breadcrumb from "../../../components/Breadcrumb";
import PageNotFound from "../../PageNotFound";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UserDetails = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { setPageTitle } = usePageTitle();
  let { getQueryParams, setQueryParams } = useQuery();
  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
  });
  let initialTabValue = parseInt(getQueryParams("tabValue")) || 0;
  const [userData, setUserData] = useState(null);

  setPageTitle("User's Details");

  useEffect(() => {
    getUserDetails(userId);
  }, [userId]);

  const getUserDetails = async (id) => {
    try {
      let response = await axiosInstance.get(
        API.fetchUserDetails(id)
      );
      if (response.status === 200) {
        setUserData(response.data.data);
        setIsLoading((prev) => ({ ...prev, pageLoading: false }));
      } else {
        setIsLoading((prev) => ({ ...prev, pageLoading: false }));
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  }
  const handleChange = (event, newValue) => {
    setQueryParams("tabValue", newValue);
  };

  return isLoading?.pageLoading ? (
    <CircularLoader />
  ) : (
    userData ?
      <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
        <Breadcrumb isBack={true} title={"Users"}
          pageTitle={'User Details'}
          isPathShow={false}
          onBackClick={() => navigate(-1)}
        />
        <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} flex={1} overflow="auto">
          <Grid container justifyContent="space-between" mb={4}>
            <Grid item display="flex" alignItems="center">
              <BackgroundLetterAvatars
                user={userData}
                sx={{ width: 64, height: 64, fontSize: "135%", }}
              />
              <Box ml={2} display="flex" flexWrap="wrap">
                <div
                  style={{
                    marginBottom: "2px",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="secondary"
                    fontSize={18}
                    fontWeight={500}
                    mr={1}
                    sx={{
                      lineHeight: "27px",
                    }}
                  >
                    {getFullName(userData)}
                  </Typography>
                  {userData?.active ?
                    <Chip label="Active" color='secondary' size="small" variant='filled'></Chip>
                    :
                    <Chip label="Inactive" size="small" variant='filled'></Chip>}
                </div>
                <Typography
                  color="secondary"
                  fontSize={12}
                  fontWeight={500}
                  display="block"
                  sx={{ lineHeight: "18px", width: "100%", mb: 1, }}
                >
                  {userData?.email || ""}
                </Typography>
                {/* <UserRoleChip label={userData?.OrganizationUserRole?.name} /> */}
                <Chip
                  label={titleCase(userData?.OrganizationUserRole?.name)}
                  size="small"
                  sx={{
                    'svg': {
                      color: 'white'
                    },
                  }}
                />
                {!!userData?.is_dedicated_developer ? (
                  <Chip
                    label={'Dedicated'}
                    size="small"
                    sx={{
                      'svg': {
                        color: 'white'
                      },
                      marginLeft: '5px'
                    }}
                  />) : null}
              </Box>
            </Grid>
          </Grid>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={initialTabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Information" {...a11yProps(0)} />
                <Tab label="Projects" {...a11yProps(1)} />
                <Tab label="Leave" {...a11yProps(2)} />
                <Tab label="Roles" {...a11yProps(3)} />
                <Tab label="Reports" {...a11yProps(4)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={initialTabValue} index={0}>
              <UserInformation userData={userData} setUserData={setUserData} />
            </CustomTabPanel>
            <CustomTabPanel value={initialTabValue} index={1}>
              <UserProjects />
            </CustomTabPanel>
            <CustomTabPanel value={initialTabValue} index={2}>
              <UserLeave getUserDetails={getUserDetails} />
            </CustomTabPanel>
            <CustomTabPanel value={initialTabValue} index={3}>
              <UserRoles />
            </CustomTabPanel>
            <CustomTabPanel value={initialTabValue} index={4}>
              <UserReports />
            </CustomTabPanel>
          </Box>
        </Box>
      </Box> :
      <PageNotFound showSideBar={false} />
  );
}

export default UserDetails; 