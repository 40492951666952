import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import URLS from "../routes/urls";
import { toast } from "react-toastify";
import { API_ERROR_CODE, EXCLUDED_APIS } from "../constants/default-values";
import { store } from "../redux/store";
import { clearStorage } from "../redux/slices/userSlice";
import { clearAllCookies, formDataToObject, getCookie } from "../utils";
import { decryptData, getEncryptedFormData, getEncryptedObject } from "../crypto";
import API from "./api";

const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    const token = secureLocalStorage.getItem("token") || getCookie('sessionBackupToken');
    config.baseURL = process.env.REACT_APP_API_ENDPOINT;
    if (!!token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      if (config.headers["Content-Type"] === "multipart/form-data") {
        config.data = { data: getEncryptedFormData(config.data) };
        config.headers["Content-Type"] = "application/json";
      } else {
        config.data = { data: getEncryptedObject(config.data) };
      }
    } else {
      if (config.headers["Content-Type"] === "multipart/form-data") {
        const _jsonFormData = formDataToObject(config.data);
        config.data = _jsonFormData;
        config.headers["Content-Type"] = "application/json";
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

if (!window.previousPath) {
  window.previousPath = '';
}

const setPreviousPath = (path) => {
  window.previousPath = path;
};

const getPreviousPath = () => {
  return window.previousPath;
};

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      if (response.data) {
        response.data = decryptData(response.data, true);
      }
    } else {
      if (response.data) {
        response.data = { ...response?.data };
      }
    }

    if (response?.data?.['access_denied']) {
      window.location.href = URLS.AccessDenied;
    }

    return response;
  },
  function (error) {
    // Initialize previous path on the window object
    const previousPath = getPreviousPath();
    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      if (error.response) {
        error.response.data = decryptData(error.response.data, true);
      }
    }
    if (EXCLUDED_APIS.includes(error?.config?.url)) {
      return error.response;
    }
    else {
      if (API_ERROR_CODE?.includes(error?.code) && error?.response?.status !== 501) {
        if (previousPath !== window.location.pathname) {
          setPreviousPath(window.location.pathname);
          toast.error("Something went wrong. Please try again later");
        }
      }
      else if (error.response.status === 401 && ![API.login].includes(error?.config?.url)) {
        store.dispatch(clearStorage());
        clearAllCookies();
        window.location.href = URLS.Login;
        secureLocalStorage.setItem("errorMessage", error?.response?.data?.message);
      } else if (error.response.status === 403) {
        const currentUser = secureLocalStorage.getItem("currentUser");
        let user = JSON.parse(currentUser);
        if (['hr', 'developer', 'sales', 'administrator'].includes(user?.user_role?.toLowerCase())) {
          window.location.href = currentUser?.user_type === "super_admin" ? URLS.AdminDashboard : URLS.AccessDenied;
        }
      } else if (error.response.status === 404 && ![API.login].includes(error.config.url)) {
        window.location.href = URLS.AccessDenied;
      }
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;