/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../../axios";
import API from "../../../../../axios/api";
import CircularLoader from "../../../../../components/CircularLoader";
import { getApplicationNumber, getApplyDateTime, getDayName, getDurationFromDate, getFormattedDate, getLeaveDateArray, getLeaveStatus, showInformationAuthority, sortArrayByKey, specialRequestList, titleCase } from "../../../../../utils";
import TextEditor from "../../../../../components/TextEditor";
import FileDropZone from "../../../../../components/FileDropZone";
import URLS from "../../../../../routes/urls";
import EmptyPage from "../../../../../components/EmptyPage";
import { usePageTitle } from "../../../../../hook/useTitle";
import useAuthentication from "../../../../../hook/useAuthentication";
import { LEAVE_STATUS } from "../../../../../constants/default-values";
import CustomModal from "../../../../../components/CustomModal";
import { useAlert } from "../../../../../hook/useAlert";
import moment from "moment";
import Breadcrumb from "../../../../../components/Breadcrumb";
import PageNotFound from "../../../../PageNotFound";

const initialModalDetails = {
    isShow: false,
    type: null,
    comment: "",
    leaveDetails: null,
    isLoading: false,
};

const ViewWfhApplication = () => {
    const navigate = useNavigate();
    const params = useParams();
    const showAlert = useAlert();
    const { getCurrentUser } = useAuthentication();
    const currentUser = getCurrentUser();
    const { setPageTitle } = usePageTitle();
    const [leaveDetails, setLeaveDetails] = useState(null);
    const [isLoading, setIsLoading] = useState({
        leaveDetails: true,
    });
    const [activeRequestType, setActiveRequestType] = useState([]);
    const [modalDetails, setModalDetails] = useState(initialModalDetails);

    setPageTitle("View WFH Application");
    useEffect(() => {
        if (Number(currentUser?.id) === Number(params?.userId)) {
            getUserSettingData(params?.userId, params?.appId);
            getUserLeaveTypeData();
        } else {
            navigate(URLS.AccessDenied)
        }
    }, [params])

    const isCreditEnable = () => {
        return !!activeRequestType?.find(request => request?.spec_id === leaveDetails?.static_id)
    }

    const getTotalCreditUsedCount = (leave_array) => {
        let totalCreditUsed = 0;
        leave_array?.forEach((leave) => {
            totalCreditUsed += leave?.credit_used;
        });
        return totalCreditUsed || 0;
    };

    const getTotalRemainingCredit = () => {
        let wfhTypeData = activeRequestType?.find(data => data?.spec_id === leaveDetails?.static_id);
        return wfhTypeData?.remaining;
    }

    const handelEditLeave = () => {
        navigate(`${URLS.EditMyWfhApplication}/${params?.userId}/${params?.appId}`)
    }

    const handleBackClick = () => {
        navigate(-1);
    }

    const getUserSettingData = async (user_id, id) => {
        try {
            const response = await axiosInstance.get(API.getWfhApplicationById(user_id, id));
            if (response.status === 200) {
                setLeaveDetails(response?.data?.data || {})
                setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
            } else {
                setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
        }
    };


    const getUserLeaveTypeData = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, leaveType: true }))
            const response = await axiosInstance.get(
                API.getUserSpecialCredits(params?.userId)
            );
            if (response.status === 200) {
                setActiveRequestType(response?.data?.result)
                setIsLoading((prev) => ({ ...prev, leaveType: false }))
            } else {
                setIsLoading((prev) => ({ ...prev, leaveType: false }))
            }
        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, leaveType: false }))
        }
    };

    const AppliedLeaveType = specialRequestList?.find(
        (leaveType) =>
            leaveType?.id ===
            leaveDetails?.static_id
    );

    const handleCancelRequest = () => {
        handleCancelRequestApplication('cancelled', modalDetails?.comment);
    }

    const handleCancelRequestApplication = async (status, comment) => {
        try {
            if (comment?.trim()?.length) {
                setModalDetails((prev) => ({ ...prev, isLoading: true }));
                let payload = {
                    process_description: comment,
                    action: status,
                    static_id: modalDetails?.leaveDetails?.static_id,
                    allow_special_credits: currentUser?.organization?.allow_special_credits,
                    org_year_id: currentUser?.organization?.work_type_settings?.[0]?.OrgYear?.id,
                };

                const response = await axiosInstance.put(
                    API.updateWfhApplicationStatus(currentUser?.id, modalDetails?.leaveDetails?.id),
                    payload,
                );
                if (response.status === 200) {
                    showAlert(response?.data.message);
                    setModalDetails(initialModalDetails);
                    setModalDetails((prev) => ({ ...prev, isLoading: false }));
                    navigate(`${URLS.WorkFromHome}?view=list&tabValue=1&groupBy=Status&status=active`);
                } else {
                    showAlert(response?.response?.data.message, "error");
                    setModalDetails(initialModalDetails);
                    setModalDetails((prev) => ({ ...prev, isLoading: false }));
                    navigate(`${URLS.WorkFromHome}?view=list&tabValue=1&groupBy=Status&status=active`);
                }
                setModalDetails((prev) => ({ ...prev, isLoading: false }));
            } else {
                setModalDetails((prev) => ({ ...prev, error: "Required" }));
            }
        } catch (error) {
            console.error("Error:", error);
            showAlert(error?.response?.data.message, "error");
            setModalDetails((prev) => ({ ...prev, isLoading: false }));
        }
    }

    const handleDeleteRequestApplication = async () => {
        try {
            setModalDetails((prev) => ({ ...prev, isLoading: true }));
            const formData = new FormData();
            formData.append("is_deleted", true);

            const response = await axiosInstance.delete(
                API.deleteWfhApplicationById(modalDetails?.leaveDetails?.id, modalDetails?.leaveDetails?.static_id),
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            if (response.status === 200) {
                showAlert(response?.data.message);
                setModalDetails(initialModalDetails);
                setModalDetails((prev) => ({ ...prev, isLoading: false }));
                navigate(`${URLS.WorkFromHome}?view=list&tabValue=1&groupBy=Status&status=active`);
            } else {
                showAlert(response?.response?.data.message, "error");
                setModalDetails(initialModalDetails);
                setModalDetails((prev) => ({ ...prev, isLoading: false }));
            }
        } catch (error) {
            console.error(error)
            showAlert(error?.response?.data.message);
            setModalDetails(initialModalDetails);
            setModalDetails((prev) => ({ ...prev, isLoading: false }));
        }
    }

    const handleCancelRequestModal = (request) => {
        setModalDetails({
            isShow: true,
            type: LEAVE_STATUS.Cancelled,
            leaveDetails: request
        });
    };

    const handleCloseModal = () => {
        setModalDetails(initialModalDetails);
    }

    const getApplyDays = (leave_days) => {
        return leave_days?.length;
    }

    const checkApplicationEnable = (wfhData) => {
        const minRequestDate = wfhData?.WfhDays.reduce((min, request) => {
            const requestDate = new Date(request.date);
            return requestDate < min ? requestDate : min;
        }, new Date(wfhData?.WfhDays[0].date));

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const result = today <= minRequestDate;
        return result;
    }

    return (
        isLoading?.leaveDetails ?
            <CircularLoader height="600px" /> :
            <>{
                !leaveDetails ?
                    <PageNotFound showSideBar={false} />
                    :
                    <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
                        <Breadcrumb isBack={true} title={"My WFH Request"}
                            pageTitle={'WFH Request'}
                            isPathShow={false}
                            onBackClick={() => handleBackClick()} />
                        <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} width="100%" overflow="auto">
                            <Box mb={3}>
                                <Stack spacing={2}>
                                    <Grid container justifyContent="space-between" flexWrap="wrap">
                                        <Grid item overflow="hidden" flex={1}>
                                            <div style={{ marginBottom: "7px" }}>
                                                <Typography
                                                    color="dark.800"
                                                    variant="title"
                                                    fontSize={16}
                                                    fontWeight={500}
                                                    sx={{
                                                        marginRight: "6px",
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.17px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    {getApplicationNumber(leaveDetails?.application_num)}{' '}{`${AppliedLeaveType?.name === "Come late" ? "Late Coming" : titleCase(AppliedLeaveType?.name)} Request`}
                                                </Typography>
                                                <Typography
                                                    color="dark.800"
                                                    fontSize={14}
                                                    fontWeight={400}
                                                    sx={{
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.17px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    (Applied on {getApplyDateTime(leaveDetails?.createdAt || leaveDetails?.apply_date)})
                                                </Typography>
                                            </div>
                                            <Grid display="flex" overflow="hidden" whiteSpace="nowrap" sx={{ flexWrap: 'nowrap' }}>
                                                {getLeaveStatus(leaveDetails?.status, "chip")}
                                                <Typography
                                                    color="dark.800"
                                                    fontSize={14}
                                                    fontWeight={400}
                                                    whiteSpace="nowrap"
                                                    overflow="hidden"
                                                    sx={{
                                                        display: "inline-block",
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.15px",
                                                        alignItems: 'center'
                                                    }}
                                                    component={"div"}
                                                >
                                                    {leaveDetails?.status === "pending" ? "Waiting for approval..." :
                                                        leaveDetails?.process_description ?
                                                            <Box display={"flex"} gap={"5px"} alignItems={"center"}>
                                                                <CommentRoundedIcon sx={{ fontSize: '18px' }} />
                                                                <Typography color={"gray"} overflow="hidden" textOverflow="ellipsis" fontSize={14} fontWeight={400} sx={{ lineHeight: '24px', letterSpacing: '0.15px' }} component={"div"}>{" "}{titleCase(leaveDetails?.process_description)}</Typography>
                                                            </Box> : null
                                                    }
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item justifyContent="flex-end" minWidth="265px" sx={{ textAlign: 'right' }}>
                                            {leaveDetails?.status !== "pending" ? <div style={{ marginBottom: "6px" }}>
                                                <Typography
                                                    color="dark.800"
                                                    variant="title"
                                                    fontSize={14}
                                                    fontWeight={500}
                                                    sx={{
                                                        marginRight: "6px",
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.17px",
                                                        display: "inline-block",
                                                    }}
                                                    component={"div"}
                                                >
                                                    {leaveDetails?.status === "approved" ? "Approved" : "Rejected"} by:
                                                </Typography>
                                                <Typography
                                                    color="dark.800"
                                                    fontSize={14}
                                                    fontWeight={400}
                                                    sx={{
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.17px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    {leaveDetails?.processed_by?.map(user => user?.name)?.join(",") || "-"}
                                                </Typography>
                                            </div> : null}
                                            <div>
                                                <Typography
                                                    color="dark.800"
                                                    variant="title"
                                                    fontSize={16}
                                                    fontWeight={500}
                                                    sx={{
                                                        marginRight: "6px",
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.17px",
                                                        display: "inline-block",
                                                    }}
                                                    component={"div"}
                                                >
                                                    Informed to:
                                                </Typography>
                                                <Typography
                                                    color="dark.800"
                                                    fontSize={14}
                                                    fontWeight={400}
                                                    sx={{
                                                        lineHeight: "20px",
                                                        letterSpacing: "0.17px",
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    {showInformationAuthority(leaveDetails?.informed_authorities) || "-"}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Box>
                                        <TableContainer
                                            component={Paper}
                                            sx={{ border: "1px solid #E0E0E0", boxShadow: "none" }}
                                        >
                                            <Table sx={{ minWidth: 560 }} aria-label="simple table">
                                                <TableHead sx={{ background: "rgba(0, 0, 0, 0.01)" }}>
                                                    <TableRow
                                                        sx={{
                                                            th: {
                                                                color: "dark.800",
                                                                fontWeight: 500,
                                                                fontSize: 14,
                                                                lineHeight: "24px",
                                                                letterSpacing: "0.15px",
                                                                padding: "6px 16px",
                                                                whiteSpace: "nowrap",
                                                            },
                                                        }}
                                                    >
                                                        <TableCell>Date</TableCell>
                                                        <TableCell>Day</TableCell>
                                                        <TableCell>Working hours</TableCell>
                                                        <TableCell>Duration</TableCell>
                                                        {isCreditEnable() ? <TableCell width={110}>Credit</TableCell> : null}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody
                                                // sx={{
                                                //   "tr:last-child td": {
                                                //     border: 0,
                                                //   },
                                                // }}
                                                >
                                                    {sortArrayByKey(leaveDetails?.WfhDays, "asc", "date")?.map((leave, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{
                                                                td: {
                                                                    color: "dark.800",
                                                                    fontWeight: 400,
                                                                    fontSize: 14,
                                                                    lineHeight: "24px",
                                                                    letterSpacing: "0.15px",
                                                                    padding: "7px 16px",
                                                                    border: 0,
                                                                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                                                },
                                                            }}
                                                        >
                                                            <TableCell>{getFormattedDate(leave?.date)}</TableCell>
                                                            <TableCell>{getDayName(getFormattedDate(leave?.date))}</TableCell>
                                                            <TableCell>{getDurationFromDate(leave?.in_time, leave?.out_time, "workingHours")}</TableCell>
                                                            <TableCell>{getDurationFromDate(leave?.in_time, leave?.out_time, "hours")}</TableCell>
                                                            {isCreditEnable() ?
                                                                <TableCell
                                                                    width={110}
                                                                    color="dark.800"
                                                                    fontWeight={400}
                                                                    fontSize={14}
                                                                    lineheight="24px"
                                                                    letterSpacing="0.15px"
                                                                >
                                                                    {leave?.credit_used > 0 ? leave?.credit_used : null}
                                                                </TableCell> : null}
                                                        </TableRow>
                                                    ))}
                                                    {(isCreditEnable() && Number(getTotalCreditUsedCount(leaveDetails?.WfhDays)) > 0) ? <TableRow
                                                        sx={{
                                                            td: {
                                                                padding: "10px 16px",
                                                                border: 0,
                                                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                                            },
                                                        }}
                                                    >
                                                        <TableCell colSpan={4}></TableCell>
                                                        <TableCell>
                                                            <Typography
                                                                color="error.main"
                                                                fontWeight={600}
                                                                fontSize={14}
                                                                sx={{ marginLeft: "0px", lineHeight: '24px' }}
                                                            >
                                                                {getTotalCreditUsedCount(leaveDetails?.WfhDays)}{" "}
                                                                Credit Applied
                                                            </Typography>
                                                        </TableCell>

                                                    </TableRow> : null}
                                                </TableBody>
                                                <TableFooter>
                                                    {(isCreditEnable() && Number(getTotalCreditUsedCount(leaveDetails?.WfhDays)) > 0) ? <TableRow sx={{
                                                        'td': {
                                                            padding: 0,
                                                            borderBottom: 'none',
                                                        }
                                                    }}>
                                                        <TableCell colSpan={4}></TableCell>
                                                        <TableCell>
                                                            <Grid
                                                                item
                                                                width={200}
                                                                sx={{ background: "#FFF6DF", padding: "10px 16px" }}
                                                            >
                                                                <Typography
                                                                    color="dark.800"
                                                                    variant="body2"
                                                                    lineHeight="24px"
                                                                    letterSpacing="0.17px"
                                                                    fontWeight={600}
                                                                >
                                                                    Remaining Credits: {getTotalRemainingCredit() > 0 ? getTotalRemainingCredit() : 0}
                                                                </Typography>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow> : null}
                                                </TableFooter>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Stack>
                            </Box>
                            <Box mb={2}>
                                <Typography
                                    variant="body1"
                                    fontWeight={500}
                                    sx={{ marginBottom: "4px", letterSpacing: "0.17px" }}
                                >
                                    Reason:
                                </Typography>
                                {!!leaveDetails?.description && leaveDetails?.description !== "<p><br></p>" ?
                                    <div
                                        style={{
                                            padding: "16px",
                                            borderRadius: "3px",
                                            background: "#F7F7F7",
                                        }}
                                        className="reason-text-view"
                                    >
                                        <TextEditor
                                            value={leaveDetails?.description}
                                            readOnly={true}
                                            onChange={() => { }}
                                        />
                                    </div> :
                                    <EmptyPage height={92} isButtonShow={false} title={"No Reason"} />
                                }
                            </Box>

                            <Box mb={2}>
                                <Typography
                                    variant="body1"
                                    fontWeight={500}
                                    sx={{ marginBottom: "4px", letterSpacing: "0.17px" }}
                                >
                                    Attachments:
                                </Typography>
                                {!!leaveDetails?.attachment_references?.length ?
                                    <div
                                        style={{
                                            padding: "16px",
                                            borderRadius: "3px",
                                            background: "#F7F7F7",
                                        }}
                                    >
                                        <FileDropZone
                                            readOnly={true}
                                            fileURLs={leaveDetails?.attachment_references}
                                        />
                                    </div> :
                                    <EmptyPage height={92} isButtonShow={false} title={"No Attachments"} />
                                }
                            </Box>
                            <Box mt={2}>
                                <Stack
                                    spacing={1}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    {leaveDetails?.status === LEAVE_STATUS.Pending && checkApplicationEnable(leaveDetails) ?
                                        <>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                                onClick={handelEditLeave}
                                                disabled={!checkApplicationEnable(leaveDetails)}
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="medium"
                                                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                                onClick={() => handleCancelRequestModal(leaveDetails)}
                                            >
                                                Cancel Request
                                            </Button>
                                            {/* <Button
                                                variant="contained"
                                                color="dark"
                                                size="medium"
                                                sx={{
                                                    bgcolor: 'text.primary', color: "white", textTransform: "none", boxShadow: 'none !important',
                                                    '&:hover': {
                                                        bgcolor: '#000000',
                                                    }
                                                }}
                                                onClick={() => handleDeleteRequestModal(leaveDetails)}
                                            >
                                                Delete Request
                                            </Button> */}
                                        </>
                                        : null
                                    }
                                    {([LEAVE_STATUS.Approved]?.includes(leaveDetails?.status) && checkApplicationEnable(leaveDetails)) ?
                                        <>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="medium"
                                                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                                onClick={() => handleCancelRequestModal(leaveDetails)}
                                            >
                                                Cancel Request
                                            </Button>
                                            {/* <Button
                                                variant="contained"
                                                color="dark"
                                                size="medium"
                                                sx={{
                                                    bgcolor: 'text.primary', color: "white", textTransform: "none", boxShadow: 'none !important',
                                                    '&:hover': {
                                                        bgcolor: '#000000',
                                                    }
                                                }}
                                                onClick={() => handleDeleteRequestModal(leaveDetails)}
                                            >
                                                Delete Request
                                            </Button> */}
                                        </>
                                        : null}
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
            }

                {modalDetails?.type === LEAVE_STATUS.Cancelled ? <CustomModal
                    actionType={modalDetails?.type}
                    title={"Confirm Cancellation"}
                    leaveType={specialRequestList?.find(request => request?.id === modalDetails?.leaveDetails?.static_id)?.name || 'Work From Home'}
                    date={getLeaveDateArray(modalDetails?.leaveDetails?.WfhDays || [], 'date')?.map((date) => moment(date).format("Do MMMM (dddd)"))}
                    userData={{
                        profileImage: currentUser?.profile_img,
                        role: titleCase(currentUser?.user_role),
                        is_dedicated_developer: currentUser?.User?.is_dedicated_developer,
                        ...currentUser,
                    }}
                    applyDays={getApplyDays(modalDetails?.leaveDetails?.WfhDays)}
                    comment={modalDetails?.comment}
                    commentError={modalDetails?.error}
                    handleComment={(e) => {
                        setModalDetails((prev) => ({ ...prev, comment: e.target.value, error: e.target.value ? "" : "Required" }))
                    }}
                    onApprove={handleCancelRequest}
                    onCancel={handleCloseModal}
                    isButtonLoading={modalDetails?.isLoading}
                    isWfh={true}
                    submitButtonContent={'Cancel Request'}
                /> : null}

                {modalDetails?.type === LEAVE_STATUS.Delete ? <CustomModal
                    actionType={modalDetails?.type}
                    title={"Confirm Deletion"}
                    leaveType={specialRequestList?.find(request => request?.id === modalDetails?.leaveDetails?.static_id)?.name || 'Work From Home'}
                    date={getLeaveDateArray(modalDetails?.leaveDetails?.WfhDays || [], 'date')?.map((date) => moment(date)?.format("Do MMMM (dddd)"))}
                    userData={{
                        profileImage: currentUser?.profile_img,
                        role: titleCase(currentUser?.user_role),
                        is_dedicated_developer: currentUser?.User?.is_dedicated_developer,
                        ...currentUser,
                    }}
                    applyDays={getApplyDays(modalDetails?.leaveDetails?.WfhDays)}
                    comment={modalDetails?.comment}
                    commentError={modalDetails?.error}
                    handleComment={(e) => {
                        setModalDetails((prev) => ({ ...prev, comment: e.target.value, error: e.target.value ? "" : "Required" }))
                    }}
                    onApprove={handleDeleteRequestApplication}
                    onCancel={handleCloseModal}
                    isButtonLoading={modalDetails?.isLoading}
                    isWfh={true}
                    submitButtonContent={'Delete Request'}
                /> : null}
            </>
    )
}

export default ViewWfhApplication;