/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box, Button, ButtonGroup, Chip, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BackgroundLetterAvatars from '../../components/BackgroundLetterAvatars';
import { capitalOnlyFirstLatter, getApplicationNumber, titleCase } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { markNotificationAsRead, readAllNotification } from '../../redux/slices/notificationSlice';
import URLS from '../../routes/urls';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularLoader from '../../components/CircularLoader';
import moment from 'moment';
import ICONS from '../../constants/icons';
import { NOTIFICATION_TYPES } from '../../constants/default-values';
import axiosInstance from '../../axios';
import API from '../../axios/api';
import LoopIcon from '@mui/icons-material/Loop';

const NotificationsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state?.user);
  const [params, setParams] = useState({
    limit: 15,
    offSet: 0,
  });
  const [hasMore, setHasMore] = useState(true);
  const [activeType, setActiveType] = useState("unread");
  const [count, setCount] = useState(0);
  const [allNotifications, setAllNotifications] = useState([]);
  const [notificationLoading, setNotificationLoading] = useState(true);

  const getAllNotifications = async (updatedOffset = null, isUpdateType = false) => {
    try {
      setNotificationLoading(isUpdateType ? true : false);
      const response = await axiosInstance.get(API.getAllNotifications(currentUser?.id, params.limit, updatedOffset ?? params.offSet, activeType));
      if (response.status === 200) {
        if (isUpdateType)
          setAllNotifications(response?.data?.data);
        else
          setAllNotifications(prevNotifications => [...prevNotifications, ...response?.data?.data]);
        setCount(response?.data?.count);
        if (response?.data?.data?.length < params.limit) {
          setHasMore(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setNotificationLoading(false);
    }
  }

  useEffect(() => {
    setHasMore(true);
    getAllNotifications(0, true);
    setParams((prev) => ({
      ...prev,
      offSet: 0,
    }));
  }, [activeType]);

  const fetchMoreNotifications = () => {
    let updatedOffset = params.offSet + 1
    setParams((prev) => ({
      ...prev,
      offSet: updatedOffset,
    }));
    getAllNotifications(updatedOffset);
  };

  const handleViewNotification = (data) => {
    dispatch(markNotificationAsRead(data?.id));
    // if (!data?.msg?.includes("deleted")) {
    if (data?.category === "leaves" && data?.sub_category === "my_leave") {
      navigate(`${URLS.ViewMyLeaveApplication}/${data?.application_user_id}/${data?.application_id}`);
    } else if (data?.category === "WFH" && data?.sub_category === "my_wfh") {
      navigate(`${URLS.ViewMyWfhApplication}/${data?.application_user_id}/${data?.application_id}`);
    } else if (data?.category === "availability_change" && data?.sub_category === "my_wfh") {
      navigate(`${URLS.ViewMyWfhApplication}/${data?.application_user_id}/${data?.application_id}`);
    } else if (data?.category === "availability_change" && data?.sub_category === "other_wfh") {
      navigate(`${URLS.OtherWfhApplicationDetails}/${data?.application_user_id}/${data?.application_id}?previousPage=notification`);
    }
    else if (data?.category === "leaves" && data?.sub_category === "other_leave") {
      navigate(`${URLS.OtherLeaveDetails}/${data?.application_user_id}/${data?.application_id}?previousPage=notification`);
    }
    else if (data?.category === "WFH" && data?.sub_category === "other_wfh") {
      navigate(`${URLS.OtherWfhApplicationDetails}/${data?.application_user_id}/${data?.application_id}?previousPage=notification`);
    }
    // }
  };

  const handleReadAll = () => {
    dispatch(readAllNotification());
  };

  const handleReadNotification = (data) => {
    dispatch(markNotificationAsRead(data?.id));
  };

  const handleFilterChange = (e) => {
    setActiveType(e.target.name);
  }

  return (
    <Box sx={{
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
      width: '100%'
    }}>
      <Box
        px={4}
        pb={1}
        pt={4}
        bgcolor="white"
        display="flex"
        alignItems="center"
      >
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%"
        }}>
          <Box>
            <Typography
              variant="h2"
              color="secondary.main"
              fontSize={20}
              fontWeight={600}
              sx={{ lineHeight: "28px", letterSpacing: "0.15px" }}
            >
              All Notifications ({count || 0})
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 2 }}>
            <IconButton
              color="secondary"
              size="small"
              className="fav-project"
              onClick={() => {
                getAllNotifications(0, true);
              }}
            >
              <LoopIcon />
            </IconButton>
            <ButtonGroup
              variant="outlined"
              aria-label="Basic button group"
              color="secondary"
              fontSize={14}
            >
              {Object.entries(NOTIFICATION_TYPES)?.map((filter, index) => (
                <Button
                  key={index}
                  variant="outlined"
                  size='medium'
                  color={activeType === filter[0] ? "primary" : "secondary"}
                  name={filter[0]}
                  sx={{
                    textTransform: "none",
                    background: `${activeType === filter[0] ? "rgba(4, 127, 224, 0.1)" : "none"
                      }`,
                    borderColor: `${activeType === filter[0] ? "#047FE0 !important" : "none"
                      }`,
                    position: "relative",
                    zIndex: `${activeType === filter[0] ? "1" : "none"}`,
                    paddingLeft: { xs: '10px', md: '15px' },
                    paddingRight: { xs: '10px', md: '15px' }
                  }}
                  onClick={(e) => handleFilterChange(e)}
                >
                  {titleCase(filter[1])}
                </Button>
              ))}
            </ButtonGroup>
            <Box>
              <IconButton
                color='primary'
                sx={{
                  width: "100%",
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  borderRadius: 1,
                  alignItems: 'center',
                  cursor: 'pointer',
                  fontSize: 14,
                  gap: 1,
                }}
                onClick={() => handleReadAll()}
                disabled={count === 0 || notificationLoading}
              >
                {/* <DoneAllIcon sx={{ verticalAlign: 'top', width: '22px', height: '22px' }} /> */}
                {titleCase("Read All")}
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box flex={1} overflow="hidden" display="flex" width="100%" sx={{
        '.infinite-scroll-component__outerdiv': {
          display: 'flex',
          overflow: 'hidden',
          flex: '1',
          width: '100%',
        },
        'ul': {
          border: "1px solid #E0E0E0",
          borderRadius: '3px',
          'li:last-child': {
            borderBottom: 'none',
          }
        }
      }}>
        <InfiniteScroll
          dataLength={allNotifications.length} // Total number of notifications loaded so far
          next={fetchMoreNotifications}
          hasMore={hasMore}
          style={{
            scrollBehavior: "smooth",
            width: '100%',
            flex: 1,
            overflow: 'auto',
            padding: '0px 32px 32px 32px',
          }}
          height={"100%"}
          endMessage={allNotifications.length === count && allNotifications?.length > 0 ?
            <Typography style={{ textAlign: 'center', margin: "20px" }}>
              All notifications have been loaded.
            </Typography> : null}
        >
          {notificationLoading ?
            <CircularLoader height="500px" /> :
            <List sx={{ p: 0, }}>
              {allNotifications?.length ?
                allNotifications?.map((data, index) => (
                  <React.Fragment key={index}>
                    <ListItem
                      sx={{
                        padding: "0px !important",
                        position: 'relative',
                        '.MuiListItemSecondaryAction-root': {
                          position: 'absolute',
                          right: 'auto',
                          left: 0,
                        }
                      }}
                      secondaryAction={
                        data?.read ? null :
                          <IconButton edge="end" aria-label="comments" onClick={() => handleReadNotification(data)}>
                            <Box sx={{
                              width: "8px",
                              height: "8px",
                              borderRadius: "50px",
                              backgroundColor: "primary.main",
                              alignSelf: 'center'
                            }}></Box>
                          </IconButton>
                      }
                    >
                      <ListItemButton onClick={() => handleViewNotification(data)} alignItems="center" sx={{ padding: '10px 12px 10px 25px !important', }} >
                        <ListItemAvatar>
                          {data?.msg?.toString()?.toLowerCase()?.includes("system") ?
                            <Box sx={{
                              backgroundColor: "secondary.main",
                              width: 40,
                              height: 40,
                              borderRadius: "50px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              color: "#fff",
                            }}>
                              {ICONS.SystemGeneratedLeave}
                            </Box>
                            : <BackgroundLetterAvatars user={{
                              name: data?.application_user?.name,
                              profile_img: data?.application_user?.profile_img
                            }} />}
                        </ListItemAvatar>
                        <ListItemText
                          primary={capitalOnlyFirstLatter(data?.msg)}
                          primaryTypographyProps={{
                            fontSize: 14,
                            color: '#262626',
                            lineHeight: '21px',
                            fontWeight: 400,
                            marginBottom: '4px'
                          }}
                          secondary={moment(data?.createdAt).format("DD/MM/YYYY hh:mm A")}
                          secondaryTypographyProps={{
                            fontSize: 11,
                            color: 'secondary',
                            fontWeight: 500,
                            lineHeight: '14px'
                          }}
                          sx={{ margin: '0px !important' }}
                        />
                        {!!data?.application_num ?
                          <Chip
                            sx={{ alignSelf: 'center', marginRight: '5px', width: "auto" }}
                            label={getApplicationNumber(data?.application_num)}
                            color='secondary'
                            size="small"
                            variant='filled'
                          /> : null}
                      </ListItemButton>
                    </ListItem>
                    <Divider component="li" />
                  </React.Fragment>
                )) : <Box sx={{
                  textAlign: "center",
                  m: 1,
                }}>
                  <Typography>No notification found.</Typography>
                </Box>}
            </List>}
        </InfiniteScroll>
      </Box>
    </Box>
  )
}

export default NotificationsPage;