/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import URLS from "../../routes/urls";
import PublicFooter from "../../components/PublicFooter";
import Input from "../../components/Input";
import axiosInstance from "../../axios";
import API from "../../axios/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAlert } from "../../hook/useAlert";
import { useDispatch } from "react-redux";
import useAuthentication from "../../hook/useAuthentication";
import ICONS from "../../constants/icons";
import { usePageTitle } from "../../hook/useTitle";
import LogoutModal from "../../components/LogoutModal";
import moment from "moment";
import { setUserDetails } from "../../redux/slices/userSlice";
import { createDefaultObject, validateLogoutModelData } from "../../components/LogoutModal/utils-functions";
import { ON_BOARDING_STATUS } from "../../constants/default-values";
import secureLocalStorage from "react-secure-storage";
import { getInputId, setIsReportee } from "../../utils";

const Login = () => {
  const errorMessage = secureLocalStorage.getItem("errorMessage");
  const dispatch = useDispatch();
  const showAlert = useAlert();
  const navigate = useNavigate();
  const { defaultRoute } = useAuthentication();
  const { setPageTitle } = usePageTitle();
  setPageTitle("Login");
  const [isLoading, setIsLoading] = useState(false);
  let initialModalValues = {
    isShow: false,
    isButtonLoading: false,
    date: null,
    msg: null,
    time: null,
    email: null,
    last_activity: null,
  }
  const [logoutModelDetails, setLogoutModelDetails] = useState(initialModalValues);
  const [errors, setErrors] = useState({});

  const showMessage = () => {
    if (errorMessage && errorMessage?.length > 0) {
      showAlert(errorMessage, "error");
      secureLocalStorage.removeItem("errorMessage");
    }
  };

  useEffect(() => {
    showMessage();
  }, [])

  const getUsersRepoteesData = async () => {
    try {
      const response = await axiosInstance.get(API.getRepoteesListByUserId)
      if (response?.status === 200) {
        if (response.data?.userData) {
          secureLocalStorage.setItem("isReportee", response.data?.userData?.length > 0)
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
      password: Yup.string()
        .min(8, "password must be at least 8 characters")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.post(API.login, values);
        if (response.status === 200) {
          let ask_login_details = response.data?.ask_login_details;
          if (ask_login_details?.hasOwnProperty("last_day") || ask_login_details?.hasOwnProperty("checkout") || ask_login_details?.hasOwnProperty("current_day")) {
            setIsLoading(false);
            const defaultObject = {
              ...createDefaultObject(response.data?.ask_login_details),
              date: response.data?.ask_login_details?.date,
              time: moment(response.data?.ask_login_details?.date).format("hh:mm a"),
            };
            setLogoutModelDetails({
              isShow: true,
              ...defaultObject,
              email: values?.email,
              last_activity: defaultObject?.msg?.toString()?.split("from")[1]?.replace("b", "")?.replace("<>", "") || null
            });
          } else {
            dispatch(setUserDetails({
              user: { ...response?.data?.user, organization: response?.data?.organization },
              token: response?.data?.token,
              sessionBackupToken: response?.data?.token,
              organization: response?.data?.organization
            }));
            setIsLoading(false);
            let currentUser = response.data.user;
            let onboarding_status = response.data.organization?.onboarding_status;
            if (currentUser?.user_type !== "super_admin" && onboarding_status === ON_BOARDING_STATUS.completed) {
              getUsersRepoteesData();
              setIsReportee(response.data.user);
            }
            if (response.data.message?.includes("deactivated")) {
              showAlert(response.data.message, "warning");
            } else {
              showAlert(response.data.message);
            }
            navigate({ pathname: defaultRoute() });
          }
        } else {
          showAlert(response?.response?.data?.message || "Something went wrong.", "error");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        showAlert(error?.response?.data?.message || "Something went wrong.", "error");
        setIsLoading(false);
      }
    },
  });

  const handleOnSubmit = async () => {
    try {
      setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: true }));
      let payload = {
        email: logoutModelDetails?.email,
        ask_login_details: { ...logoutModelDetails },
      };
      delete payload.ask_login_details.email;
      delete payload.ask_login_details.msg;
      delete payload.ask_login_details.isShow;
      delete payload.ask_login_details.isButtonLoading;
      delete payload.ask_login_details.time;

      let errors = validateLogoutModelData(payload);
      setErrors(errors?.ask_login_details);
      if (!Object.values(errors)?.filter((value) => !!value)?.length > 0) {
        const response = await axiosInstance.post(API.addOverTime, payload);
        if (response.status === 200) {
          dispatch(setUserDetails({
            user: response.data.user,
            token: response.data.token,
            sessionBackupToken: response?.data?.token,
            organization: response.data.organization
          }));
          setIsLoading(false);
          showAlert(response.data.message);
          navigate({ pathname: defaultRoute() });
          let currentUser = response.data.user;
          let onboarding_status = response.data.organization?.onboarding_status;
          if (currentUser?.user_type !== "super_admin" && onboarding_status === ON_BOARDING_STATUS.completed) {
            getUsersRepoteesData();
            setIsReportee(response.data.user);
          }
          setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }));
        } else {
          setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }));
        }
      } else {
        setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }));
      }
    } catch (error) {
      console.error(error)
      showAlert(error?.response?.data?.message, "error");
      setLogoutModelDetails((prev) => ({ ...prev, isButtonLoading: false }));
    }
  }

  return (
    <Box
      sx={{ minHeight: "100vh", position: "relative", background: "#FAFBFF" }}
    >
      <Box
        sx={{
          position: "absolute",
          filter: "blur(18px)",
          bottom: 0,
          top: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        <i style={{ height: "calc(100vh - 175px)", display: 'flex', alignItems: 'center' }}>{ICONS.LoginBg}</i>
      </Box>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        position="relative"
        zIndex="1"
        sx={{ minHeight: "100vh" }}
      >
        <Grid sx={{ flex: 1, display: "flex", paddingTop: "50px" }}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          // sx={{ minHeight: { xs: 'calc(100vh - 134px)', md: 'calc(100vh - 112px)' } }}
          >
            <Grid item>
              <i style={{ display: 'block', }}>{ICONS.Logo}</i>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <Box
                sx={{
                  maxWidth: { xs: 400, lg: 500 },
                  "& > *": {
                    flexGrow: 1,
                    flexBasis: "50%",
                  },
                  width: "100%",
                  margin: "20px auto 55px",
                  boxShadow: '0px 31px 95px rgba(0, 0, 0, 0.12)',
                  borderRadius: "14px",
                  background: "white",
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 }, width: "100%" }}
                  boxShadow={1}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={3} alignItems="flex-start">
                      <Typography
                        variant="h5"
                        fontWeight="bold"
                        color="secondary"
                      >
                        Sign in
                      </Typography>
                      <Input
                        id={getInputId("email")}
                        name="email"
                        label="Email address"
                        variant="standard"
                        type="email"
                        sx={{ width: "100%" }}
                        placeholder={"email address"}
                        {...formik.getFieldProps("email")}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                      <Input
                        id={getInputId("password")}
                        name="password"
                        label="Password"
                        variant="standard"
                        type="password"
                        sx={{ width: "100%" }}
                        placeholder={"password"}
                        {...formik.getFieldProps("password")}
                        onChange={(e) =>
                          formik.setFieldValue("password", e.target.value.trim())
                        }
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                      />
                      <Button
                        type="submit"
                        size="large"
                        sx={{ color: "#ffffff", width: "100%", boxShadow: 'none !important' }}
                        isButtonLoading={isLoading}
                      >
                        Sign in
                      </Button>
                      <Grid
                        className="text-center"
                        sx={{ width: "100%", textAlign: "center" }}
                      >
                        <Link
                          variant="body2"
                          component={RouterLink}
                          to={URLS.ForgotPassword}
                          color="primary"
                          sx={{ textDecoration: "none", cursor: "pointer" }}
                        >
                          Forgot Password?
                        </Link>
                      </Grid>
                    </Stack>
                  </form>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <PublicFooter />
      </Grid>
      <LogoutModal
        actionType={"ask_login_details"}
        onApprove={handleOnSubmit}
        title={`Logout time for ${moment(logoutModelDetails?.date).format("DD/MM/YYYY (dddd)")}`}
        isShow={logoutModelDetails.isShow}
        isButtonLoading={logoutModelDetails.isButtonLoading}
        isShowCancelButton={false}
        errors={errors}
        setErrors={setErrors}
        logoutModelDetails={logoutModelDetails}
        setLogoutModelDetails={setLogoutModelDetails}
      />
    </Box>
  );
};

export default Login;
