import React from 'react';
import { createRoot } from 'react-dom/client';
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import customTheme from './assets/theme';
import { store } from './redux/store';
import { AlertProvider } from './hook/useAlert';
import secureLocalStorage from "react-secure-storage";
import { setUserDetails } from './redux/slices/userSlice';
import 'material-icons/iconfont/material-icons.css';

// User Authentication Setup
const user = JSON.parse(secureLocalStorage.getItem('currentUser'));
const token = secureLocalStorage.getItem('token');

if (user && token) {
  store.dispatch(setUserDetails({ user, token }));
}

// Error Boundary Component
// class MyErrorBoundary extends React.Component {
//   state = {
//     hasError: false,
//   };

//   static getDerivedStateFromError(error) {
//     return { hasError: true };
//   }

//   componentDidCatch(error, errorInfo) {
//     console.error("Error caught in MyErrorBoundary:", error, errorInfo);
//     window.location.reload();
//   }

//   render() {
//     if (this.state.hasError) {
//       return (
//         <Box height="100vh" width="100%" p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} overflow="auto">
//           <Box minHeight="100%" width="100%" bgcolor="#FAFBFF" p={6} display="flex" alignItems="center" justifyContent="center">
//             <Box boxShadow="0px 8px 14px 0px rgba(63, 92, 118, 0.2)" borderRadius="12px" overflow="hidden" maxWidth="642px" width="100%" bgcolor="white">
//               <Box bgcolor="primary.main" sx={{ padding: "10px" }} display="flex" alignItems="center" gap="6px">
//                 <CircleIcon sx={{ color: 'white', fontSize: "6px" }} />
//                 <CircleIcon sx={{ color: 'white', fontSize: "6px" }} />
//                 <CircleIcon sx={{ color: 'white', fontSize: "6px" }} />
//               </Box>
//               <Box pt={8} pb={7} px={4} textAlign="center" position="relative">
//                 <Typography variant="h3" color="black" fontSize={28} fontWeight={600} lineHeight="34px" mb={4} pl="75px">
//                   Oops! Something went wrong.
//                 </Typography>
//                 <Typography variant="body1" color="black" fontSize={18} fontWeight={500} lineHeight="26px" mb={1} pl="120px">
//                   The page is refreshing. Please hold on for a moment.
//                 </Typography>
//                 <Box position="absolute" bottom={0} left={0} display="flex" maxWidth={146}>
//                   <img alt="404 page" src={noDataImg} style={{ width: '100%' }} />
//                 </Box>
//               </Box>
//             </Box>
//           </Box>
//         </Box>
//       );
//     }

//     return this.props.children;
//   }
// }

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <ThemeProvider theme={customTheme}>
      <Provider store={store}>
        <AlertProvider>
          <Router>
            {/* <MyErrorBoundary> */}
            <App />
            {/* </MyErrorBoundary> */}
          </Router>
        </AlertProvider>
      </Provider>
    </ThemeProvider>
  );
}
