import { Autocomplete, Box, MenuItem, TextField, Typography } from '@mui/material'
import React from 'react'
import BackgroundLetterAvatars from '../BackgroundLetterAvatars'
import { titleCase } from '../../utils'

const AutocompleteSelect = ({ options, value, onChange, onBlur = () => { }, label, variant = "standard", disableClearable = false, name, id, disabledOptions = [], isProject = false, ...rest }) => {
    return (
        <Autocomplete
            variant="standard"
            disablePortal
            disableClearable={disableClearable}
            options={options}
            getOptionDisabled={(option) =>
                option?.id === disabledOptions?.find(data => data?.id === option?.id)?.id
            }
            name={name}
            renderInput={(params) =>
                <TextField
                    name={name}
                    id={id}
                    {...params}
                    label={label}
                    variant={variant}
                />
            }
            value={((value && value !== "") ? Object.keys(value)?.length : false) ? value : {}}
            onChange={(e, newValue) => onChange({
                ...e,
                target: {
                    name: name,
                    value: newValue,
                }

            })}
            onBlur={(e) => onBlur({
                ...e,
                target: {
                    ...e.target,
                    name: name,
                }

            })}
            // onBlur={onBlur}
            autoHighlight
            sx={{
                m: 0,
                "&:focus": {
                    boxShadow: "none",
                    outline: "none",
                },
                '.MuiInputBase-input': {
                    display: 'flex',
                    alignItems: 'center',
                    background: 'transparent !important',
                    outline: 'none !important',
                    boxShadow: 'none !important',
                    borderRadius: '0 !important',
                },
                '.MuiAvatar-circular': {
                    display: 'none',
                },
                '.MuiBox-root p': {
                    color: 'dark.800',
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                },
                '.MuiSelect-select .MuiBox-root': {
                    display: 'flex',
                    overflow: 'hidden',
                },
                '.MuiSelect-select .MuiBox-root p:not(:last-child)': {
                    marginRight: '5px'
                },
                '.MuiSelect-select .MuiBox-root p:last-child': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }
            }}
            getOptionLabel={(option) => isProject ? (option?.title) ? `${option?.title}` : "" : (option?.name && option?.email) ? `${option?.name} (${option?.email})` : ""}
            renderOption={(props, user) => (
                <MenuItem
                    {...props}
                    value={user?.id}
                    key={user?.id}
                    sx={{ padding: "8px 12px", display: "flex" }}
                >
                    {isProject ?
                        <Box
                            bgcolor={"#0063BF"}
                            height={36}
                            minWidth={36}
                            borderRadius={"6px"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{ padding: "6px 8px" }}
                        >
                            <Typography
                                color={"white"}
                                fontSize={13}
                                lineHeight={"20px"}
                                fontWeight={600}
                            >
                                {user?.short_code?.length > 6 ? user?.short_code?.slice(0, 6)?.toUpperCase() : user?.short_code?.toUpperCase()}
                            </Typography>
                        </Box> : <BackgroundLetterAvatars
                            user={user}
                            sx={{
                                width: "32px",
                                height: "32px",
                                marginRight: "8px",
                                fontSize: "80%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // background: "white",
                            }}
                        />}
                    <Box overflow={"hidden"}>
                        {isProject ?
                            <Typography
                                color="dark.800"
                                fontSize={15}
                                fontWeight={500}
                                lineHeight="24px"
                                letterSpacing="0.17px"
                                marginLeft={'5px'}
                            >
                                {titleCase(user?.title)}
                            </Typography>
                            :
                            <>
                                <Typography
                                    color="dark.800"
                                    fontSize={15}
                                    fontWeight={500}
                                    lineHeight="24px"
                                    letterSpacing="0.17px"
                                >
                                    {user?.name}
                                </Typography>
                                <Typography
                                    color="rgba(63, 92, 118, 0.9)"
                                    fontSize={12}
                                    fontWeight={400}
                                    lineHeight="15px"
                                    overflow={"hidden"}
                                    textOverflow={"ellipsis"}
                                    whiteSpace={"nowrap"}
                                    display={"block"}
                                    maxWidth={"100%"}
                                >
                                    {user?.email}
                                </Typography>
                            </>}
                    </Box>
                </MenuItem>
            )}
            {...rest}
        />
    )
}

export default AutocompleteSelect