import React from 'react'
import { TablePagination as MuiTablePagination } from '@mui/material';

const TablePagination = ({ count, rowsPerPage, page, rowsPerPageOptions = [10, 25, 50], onPageChange, onRowsPerPageChange }) => {
  
  if (count <= rowsPerPageOptions[0]) {
    return null;
  }

  return (
    <MuiTablePagination
      SelectProps={{
        id: "page-select",
        name: "page-select",
      }}
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
}

export default TablePagination