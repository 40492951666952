/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomModal from "../../../../components/CustomModal";
import TextEditor from "../../../../components/TextEditor";
import FileDropZone from "../../../../components/FileDropZone";
import { useAlert } from "../../../../hook/useAlert";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import CircularLoader from "../../../../components/CircularLoader";
import { LEAVE_STATUS } from "../../../../constants/default-values";
import moment from "moment";
import { getApplicationNumber, getApplyDateTime, getDayName, getDurationFromDate, getFormattedDate, getFullName, getLeaveDateArray, getLeaveStatus, showInformationAuthority, sortArrayByKey, specialRequestList, titleCase } from "../../../../utils";
import CommentRoundedIcon from '@mui/icons-material/CommentRounded';
import { useNavigate, useParams } from "react-router-dom";
import EmptyPage from "../../../../components/EmptyPage";
import URLS from "../../../../routes/urls";
import { usePageTitle } from "../../../../hook/useTitle";
import BackgroundLetterAvatars from "../../../../components/BackgroundLetterAvatars";
import UserRoleChip from "../../../../components/UserRoleChip";
import useAuthentication from "../../../../hook/useAuthentication";
import Breadcrumb from "../../../../components/Breadcrumb";
import PageNotFound from "../../../PageNotFound";
import useQuery from "../../../../hook/useQuery";

const OthersWfhApplicationDetails = () => {
  const showAlert = useAlert();
  const navigate = useNavigate();
  const params = useParams();
  const { getQueryParams } = useQuery();
  const previousPage = getQueryParams('previousPage')
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();
  const { setPageTitle } = usePageTitle();
  const initialModalDetails = {
    isShow: false,
    type: null,
    comment: "",
    error: "",
  };
  const [modalDetails, setModalDetails] = useState(initialModalDetails);
  const [leaveDetails, setLeaveDetails] = useState(null);
  const [isLoading, setIsLoading] = useState({
    leaveDetails: true,
    modalButtonLoading: false
  })
  const [activeRequestType, setActiveRequestType] = useState([]);

  const isCreditEnable = () => {
    return !!activeRequestType?.find(request => request?.spec_req_id === leaveDetails?.static_id)
  }

  setPageTitle("Other's WFH Request");
  useEffect(() => {
    if (Number(currentUser?.id) !== Number(params?.userId)) {
      getUserSettingData(params?.userId, params?.appId);
      getUserLeaveTypeData();
    } else {
      navigate(URLS.AccessDenied)
    }
  }, [params])

  const getUserLeaveTypeData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, leaveType: true }))
      const response = await axiosInstance.get(
        API.getUserSpecialCredits(params?.userId)
      );
      if (response.status === 200) {
        setActiveRequestType(response?.data?.result)
        setIsLoading((prev) => ({ ...prev, leaveType: false }))
      } else {
        setIsLoading((prev) => ({ ...prev, leaveType: false }))
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, leaveType: false }))
    }
  };

  const getTotalCreditUsedCount = (leave_array) => {
    let totalCreditUsed = 0;
    leave_array?.forEach((leave) => {
      totalCreditUsed += leave?.credit_used || 0;
    });
    return totalCreditUsed || 0;
  };

  const getTotalRemainingCredit = () => {
    let wfhTypeData = activeRequestType?.find(data => data?.spec_req_id === leaveDetails?.static_id);
    return wfhTypeData?.remaining;
  }

  const handleOpen = (type) => {
    setModalDetails({
      isShow: true,
      type,
    });
  };

  const getApplyDays = (WfhDays) => {
    // let count = WfhDays?.reduce((previousValue, currentValue, currentIndex, array) => {
    //   let paidCount = currentValue?.paid || 0;
    //   let unPaidCount = currentValue?.unpaid || 0
    //   previousValue.paidCount = previousValue.paidCount + paidCount;
    //   previousValue.unPaidCount = previousValue.unPaidCount + unPaidCount;
    //   previousValue.totalCount = previousValue.paidCount + previousValue.unPaidCount
    //   return previousValue;
    // }, { paidCount: 0, unPaidCount: 0, totalCount: 0, });
    // return count;
    return WfhDays?.length
  }

  const handleSubmitLeaveApplication = async (status, comment) => {
    try {
      setIsLoading((prev) => ({ ...prev, modalButtonLoading: true }))
      let payload = {
        process_description: comment,
        action: status,
        static_id: leaveDetails?.static_id,
        allow_special_credits: currentUser?.organization?.allow_special_credits,
        org_year_id: currentUser?.organization?.work_type_settings?.[0]?.OrgYear?.id,
      };

      const response = await axiosInstance.put(
        API.updateWfhApplicationStatus(params?.userId, params?.appId),
        payload,
      );
      if (response.status === 200) {
        showAlert(response?.data.message);
        setIsLoading((prev) => ({ ...prev, modalButtonLoading: false }))
        navigate(`${URLS.WorkFromHome}?view=list&tabValue=0&groupBy=Status&status=active`);
        // navigate(-1);
      } else {
        setIsLoading((prev) => ({ ...prev, modalButtonLoading: false }))
        showAlert(response?.response?.data.message, "error");
        // navigate(`${URLS.WorkFromHome}?view=list&tabValue=0&groupBy=Status&status=active`);
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading((prev) => ({ ...prev, modalButtonLoading: false }))
      showAlert(error?.response?.data.message, "error");
      // navigate(`${URLS.WorkFromHome}?view=list&tabValue=0&groupBy=Status&status=active`);
    }
  };

  const handleApprove = () => {
    let status = modalDetails?.type;
    if (!!modalDetails?.comment && modalDetails?.comment?.trim()?.length) {
      handleSubmitLeaveApplication(status, modalDetails?.comment)
      setModalDetails(initialModalDetails);
    } else {
      setModalDetails((prev) => ({ ...prev, error: "Required" }));
    }
  };

  const handleCancel = () => {
    setModalDetails(initialModalDetails);
  };

  const getUserSettingData = async (user_id, id) => {
    try {
      const response = await axiosInstance.get(API.getWfhApplicationById(user_id, id));
      if (response.status === 200) {
        setLeaveDetails(response?.data?.data || {})
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      } else {
        setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
      }
    } catch (error) {
      console.error(error);
      setIsLoading((prev) => ({ ...prev, leaveDetails: false }));
    }
  };

  const AppliedLeaveType = specialRequestList?.find(
    (leaveType) =>
      leaveType?.id ===
      leaveDetails?.static_id
  );

  const handleClickBack = () => {
    switch (previousPage) {
      case 'notification':
        navigate(URLS.AllNotifications)
        break;
      case 'dashboard':
        navigate(URLS.Dashboard)
        break;
      default:
        navigate(`${URLS.WorkFromHome}?groupBy=Status`)
        break;
    }
  }

  const checkApplicationEnable = (wfhData) => {
    const minRequestDate = wfhData?.WfhDays.reduce((min, request) => {
      const requestDate = (request.date);
      return requestDate < min ? requestDate : min;
    }, new Date(wfhData?.WfhDays[0].date));

    const today = new Date();
    // today.setHours(0, 0, 0, 0);

    const result = today <= minRequestDate;
    return result;
  }

  const isShowButton = (wfhData) => {
    let isAdmin = currentUser?.is_admin;
    if (isAdmin) {
      return true
    } else {
      return checkApplicationEnable(wfhData)
    }
  }

  const getPreviousPageName = () => {
    return previousPage === 'notification' ? 'Notifications' : previousPage === 'dashboard' ? 'Dashboard' : 'Others’ WFH Request'
  }

  return (
    isLoading?.leaveDetails ?
      <CircularLoader height="600px" /> :
      !leaveDetails ?
        <PageNotFound showSideBar={false} /> :
        <Grid width="100%" display={"flex"} flexDirection={"column"}>
          <Breadcrumb isBack={true} title={getPreviousPageName()}
            pageTitle={'WFH Request'}
            isPathShow={false}
            onBackClick={() => handleClickBack()} />
          <Box p={{ xs: 2, lg: 3, xl: 4 }} py={{ xs: 3, lg: 3, xl: 4 }} width="100%" flex={1} overflow="auto">
            <Box pb={2} mb={2} sx={{ borderBottom: "1px solid #E2E4EC" }}>
              <Grid container justifyContent="space-between" flexWrap="nowrap">
                <Grid item display="flex" alignItems="center" pr={2.5} overflow="hidden">
                  <BackgroundLetterAvatars
                    user={leaveDetails?.User}
                    src={leaveDetails?.User?.profile_img}
                    sx={{ width: { xs: 46, xl: 56 }, height: { xs: 46, xl: 56 } }}
                  />
                  <Box ml={2} display="flex" flexWrap="wrap" overflow="hidden">
                    <Box display="flex" alignItems={"center"}>
                      <Typography
                        variant="body1"
                        color="secondary"
                        fontSize={18}
                        fontWeight={500}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        sx={{
                          lineHeight: "27px",
                          marginBottom: "2px",
                          display: "block",
                          width: "100%",
                        }}
                      >
                        {getFullName(leaveDetails?.User)}
                      </Typography>
                      {leaveDetails?.User?.is_dedicated_developer ?
                        <Chip
                          label="Dedicated"
                          color="secondary"
                          size="small"
                          variant="outlined"
                          sx={{
                            ml: 1,
                          }}
                        /> : null}
                    </Box>

                    <Typography
                      color="secondary"
                      fontSize={12}
                      fontWeight={500}
                      display="block"
                      sx={{ lineHeight: "18px", width: "100%", mb: 1, }}
                    >
                      {leaveDetails?.User?.email || ""}
                    </Typography>

                    <UserRoleChip label={leaveDetails?.User?.OrganizationUserRole?.name} />
                  </Box>
                </Grid>
                {/* {isCreditEnable() ? <Grid item>
                  <Stack
                    spacing={1}
                    direction="row"
                    width="100%"
                    justifyContent="flex-end"
                  >

                    <Grid item p={{ xs: 1, xl: 2 }} sx={{ borderRadius: "8px", minWidth: '110px' }} bgcolor="#FFF6DF">
                      <Typography
                        variant="caption"
                        color="secondary"
                        fontWeight={500}
                        display="block"
                        mb={0.5}
                      >
                        Available Special Credits
                      </Typography>
                      <Typography
                        fontSize={{ xs: 16, xl: 18 }}
                        fontWeight={600}
                        color="secondary"
                        display="block"
                      >
                        {getTotalRemainingCredit() > 0 ? getTotalRemainingCredit() : 0}
                      </Typography>
                    </Grid>
                  </Stack>
                </Grid> : null} */}
              </Grid>
            </Box>
            <Box mb={3}>
              <Stack spacing={2}>
                <Grid container justifyContent="space-between" flexWrap="wrap">
                  <Grid item overflow="hidden" flex={1}>
                    <div style={{ marginBottom: "7px" }}>
                      <Typography
                        color="dark.800"
                        variant="title"
                        fontSize={16}
                        fontWeight={500}
                        sx={{
                          marginRight: "6px",
                          lineHeight: "24px",
                          letterSpacing: "0.17px",
                          display: "inline-block",
                        }}
                      >
                        {getApplicationNumber(leaveDetails?.application_num)}{' '}{`${AppliedLeaveType?.name === "Come late" ? "Late Coming" : titleCase(AppliedLeaveType?.name || "")} Request`}
                      </Typography>
                      <Typography
                        color="dark.800"
                        fontSize={14}
                        fontWeight={400}
                        sx={{
                          lineHeight: "24px",
                          letterSpacing: "0.17px",
                          display: "inline-block",
                        }}
                      >
                        (Applied on {getApplyDateTime(leaveDetails?.createdAt || leaveDetails?.apply_date)})
                      </Typography>
                    </div>
                    <Grid display="flex" overflow="hidden" whiteSpace="nowrap" sx={{ flexWrap: 'nowrap' }}>
                      {getLeaveStatus(leaveDetails?.status, "chip")}
                      <Typography
                        color="dark.800"
                        fontSize={14}
                        fontWeight={400}
                        whiteSpace="nowrap"
                        overflow="hidden"
                        sx={{
                          display: "inline-block",
                          lineHeight: "24px",
                          letterSpacing: "0.15px",
                          alignItems: 'center'
                        }}
                        component={"div"}
                      >
                        {leaveDetails?.status === "pending" ? "Waiting for approval..." :
                          leaveDetails?.process_description ?
                            <Box display={"flex"} gap={"5px"} alignItems={"center"}>
                              <CommentRoundedIcon sx={{ fontSize: '18px' }} />
                              <Typography color={"gray"} overflow="hidden" textOverflow="ellipsis" fontSize={14} fontWeight={400} sx={{ lineHeight: '24px', letterSpacing: '0.15px' }}>{" "}{titleCase(leaveDetails?.process_description)}</Typography>
                            </Box> : null
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item justifyContent="flex-end" minWidth="265px" sx={{ textAlign: 'right' }}>
                    {leaveDetails?.status !== "pending" ? <div style={{ marginBottom: "6px" }}>
                      <Typography
                        color="dark.800"
                        variant="title"
                        fontSize={14}
                        fontWeight={500}
                        sx={{
                          marginRight: "6px",
                          lineHeight: "24px",
                          letterSpacing: "0.17px",
                          display: "inline-block",
                        }}
                        component={"div"}
                      >
                        {leaveDetails?.status === "approved" ? "Approved" : "Rejected"} by:
                      </Typography>
                      <Typography
                        color="dark.800"
                        fontSize={14}
                        fontWeight={400}
                        sx={{
                          lineHeight: "24px",
                          letterSpacing: "0.17px",
                          display: "inline-block",
                        }}
                      >
                        {leaveDetails?.processed_by?.map(user => user?.name)?.join(",") || "-"}
                      </Typography>
                    </div> : null}
                    <div>
                      <Typography
                        color="dark.800"
                        variant="title"
                        fontSize={16}
                        fontWeight={500}
                        sx={{
                          marginRight: "6px",
                          lineHeight: "24px",
                          letterSpacing: "0.17px",
                          display: "inline-block",
                        }}
                      >
                        Informed to:
                      </Typography>
                      <Typography
                        color="dark.800"
                        fontSize={14}
                        fontWeight={400}
                        sx={{
                          lineHeight: "20px",
                          letterSpacing: "0.17px",
                          display: "inline-block",
                        }}
                      >
                        {showInformationAuthority(leaveDetails?.informed_authorities) || "-"}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Box>
                  <TableContainer
                    component={Paper}
                    sx={{ border: "1px solid #E0E0E0", boxShadow: "none" }}
                  >
                    <Table sx={{ minWidth: 560 }} aria-label="simple table">
                      <TableHead sx={{ background: "rgba(0, 0, 0, 0.01)" }}>
                        <TableRow
                          sx={{
                            th: {
                              color: "dark.800",
                              fontWeight: 500,
                              fontSize: 14,
                              lineHeight: "24px",
                              letterSpacing: "0.15px",
                              padding: "6px 16px",
                              whiteSpace: "nowrap",
                            },
                          }}
                        >
                          <TableCell>Date</TableCell>
                          <TableCell>Day</TableCell>
                          <TableCell>Working hours</TableCell>
                          <TableCell>Duration</TableCell>
                          {isCreditEnable() ? <TableCell width={110}>Credit</TableCell> : null}
                        </TableRow>
                      </TableHead>
                      <TableBody
                      // sx={{
                      //   "tr:last-child td": {
                      //     border: 0,
                      //   },
                      // }}
                      >
                        {sortArrayByKey(leaveDetails?.WfhDays, 'asc', 'date')?.map((leave, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              td: {
                                color: "dark.800",
                                fontWeight: 400,
                                fontSize: 14,
                                lineHeight: "24px",
                                letterSpacing: "0.15px",
                                padding: "7px 16px",
                                border: 0,
                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                              },
                            }}
                          >
                            <TableCell>{getFormattedDate(leave?.date)}</TableCell>
                            <TableCell>{getDayName(getFormattedDate(leave?.date))}</TableCell>
                            <TableCell>{getDurationFromDate(leave?.in_time, leave?.out_time, "workingHours")}</TableCell>
                            <TableCell>{getDurationFromDate(leave?.in_time, leave?.out_time, "hours")}</TableCell>
                            {isCreditEnable() ?
                              <TableCell
                                width={110}
                                color="dark.800"
                                fontWeight={400}
                                fontSize={14}
                                lineheight="24px"
                                letterSpacing="0.15px"
                              >
                                {leave?.credit_used > 0 ? leave?.credit_used : null}
                              </TableCell> : null}
                          </TableRow>
                        ))}
                        {(isCreditEnable() && Number(getTotalCreditUsedCount(leaveDetails?.WfhDays)) > 0) ? <TableRow
                          sx={{
                            td: {
                              padding: "10px 16px",
                              border: 0,
                              borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                            },
                          }}
                        >
                          <TableCell colSpan={4}></TableCell>
                          <TableCell>
                            <Typography
                              color="error.main"
                              fontWeight={600}
                              fontSize={14}
                              sx={{ marginLeft: "0px", lineHeight: '24px' }}
                            >
                              {getTotalCreditUsedCount(leaveDetails?.WfhDays)}{" "}
                              Credit Applied
                            </Typography>
                          </TableCell>
                        </TableRow> : null}
                      </TableBody>
                      <TableFooter>
                        {(isCreditEnable() && Number(getTotalCreditUsedCount(leaveDetails?.WfhDays)) > 0) ? <TableRow sx={{
                          'td': {
                            padding: 0,
                            borderBottom: 'none',
                          }
                        }}>
                          <TableCell colSpan={4}></TableCell>
                          <TableCell>
                            <Grid
                              item
                              width={200}
                              sx={{ background: "#FFF6DF", padding: "10px 16px" }}
                            >
                              <Typography
                                color="dark.800"
                                variant="body2"
                                lineHeight="24px"
                                letterSpacing="0.17px"
                                fontWeight={600}
                              >
                                Remaining Credits: {getTotalRemainingCredit() > 0 ? getTotalRemainingCredit() : 0}
                              </Typography>
                            </Grid>
                          </TableCell>
                        </TableRow> : null}
                      </TableFooter>
                    </Table>
                  </TableContainer>
                </Box>
              </Stack>
            </Box>
            <Box mb={2}>
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{ marginBottom: "4px", letterSpacing: "0.17px" }}
              >
                Reason:
              </Typography>
              {!!leaveDetails?.description && leaveDetails?.description !== "<p><br></p>" ?
                <div
                  style={{
                    padding: "16px",
                    borderRadius: "3px",
                    background: "#F7F7F7",
                  }}
                  className="reason-text-view"
                >
                  <TextEditor
                    value={leaveDetails?.description}
                    readOnly={true}
                    onChange={() => { }}
                  />
                </div> :
                <EmptyPage height={92} isButtonShow={false} title={"No Reason"} />
              }
            </Box>

            <Box mb={2}>
              <Typography
                variant="body1"
                fontWeight={500}
                sx={{ marginBottom: "4px", letterSpacing: "0.17px" }}
              >
                Attachments:
              </Typography>
              {!!leaveDetails?.attachment_references?.length ?
                <div
                  style={{
                    padding: "16px",
                    borderRadius: "3px",
                    background: "#F7F7F7",
                  }}
                >
                  <FileDropZone
                    readOnly={true}
                    fileURLs={leaveDetails?.attachment_references}
                  />
                </div> :
                <EmptyPage height={92} isButtonShow={false} title={"No Attachments"} />
              }
            </Box>
            <Box mt={2}>
              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                {leaveDetails?.status === LEAVE_STATUS.Pending && isShowButton(leaveDetails) ?
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                      onClick={() => handleOpen(LEAVE_STATUS.Approved)}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="medium"
                      sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                      onClick={() => handleOpen(LEAVE_STATUS.Rejected)}
                    >
                      Reject
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="medium"
                      sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                      onClick={() => handleOpen(LEAVE_STATUS.Cancelled)}
                    >
                      Cancel Request
                    </Button>
                  </> :
                  null}
                {([LEAVE_STATUS.Approved, LEAVE_STATUS.Rejected]?.includes(leaveDetails?.status) && isShowButton(leaveDetails)) ?
                  <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                    onClick={() => handleOpen(LEAVE_STATUS.Cancelled)}
                  >
                    Cancel Request
                  </Button>
                  : null}
              </Stack>
            </Box>
            <CustomModal
              actionType={modalDetails.type}
              title={modalDetails.type === LEAVE_STATUS.Approved ? "Confirm Approval" : modalDetails.type === LEAVE_STATUS.Rejected ? "Confirm Rejection" : modalDetails.type === LEAVE_STATUS.Cancelled ? "Confirm Cancellation" : ""}
              leaveType={titleCase(specialRequestList?.find(request => request?.id === leaveDetails?.static_id)?.name || 'Work From Home')}
              date={getLeaveDateArray(leaveDetails?.WfhDays || [], 'date')?.map((date) => moment(date).format("Do MMMM (dddd)"))}
              userData={{
                profileImage: leaveDetails?.User?.profile_img,
                userName: getFullName(leaveDetails?.User),
                role: titleCase(leaveDetails?.User?.OrganizationUserRole?.name),
                is_dedicated_developer: leaveDetails?.User?.is_dedicated_developer,
              }}
              applyDays={getApplyDays(leaveDetails?.WfhDays)}
              comment={modalDetails?.comment}
              commentError={modalDetails?.error}
              handleComment={(e) => {
                setModalDetails((prev) => ({ ...prev, comment: e.target.value, error: e.target.value ? "" : "Required" }))
              }}
              onApprove={handleApprove}
              onCancel={handleCancel}
              isWfh={true}
              isButtonLoading={isLoading?.modalButtonLoading}
              submitButtonContent={modalDetails.type === LEAVE_STATUS.Cancelled ? 'Cancel Leave' : ''}
            />
          </Box>
        </Grid>
  )
}

export default OthersWfhApplicationDetails