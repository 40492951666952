import { createSlice } from '@reduxjs/toolkit';
import secureLocalStorage from "react-secure-storage";

const getNightModeFromLocalStorage = () => {
  const nightMode = secureLocalStorage.getItem('nightMode');
  return !!nightMode ? (nightMode) : { id: null, maintain_session: false };
};

const initialState = getNightModeFromLocalStorage();

const nightModeSlice = createSlice({
  name: 'nightMode',
  initialState,
  reducers: {
    setNightModeDetails: (state, action) => {
      state.id = action.payload?.id || null;
      state.maintain_session = action.payload?.maintain_session || false;
      secureLocalStorage.setItem('nightMode', action.payload);
    },
    clearNightMode: (state) => {
      secureLocalStorage.removeItem('nightMode');
      state.id = null;
      state.maintain_session = false;
    }
  },
});

export const { setNightModeDetails, clearNightMode } = nightModeSlice.actions;
export default nightModeSlice.reducer;
