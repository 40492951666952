/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Stack,
    Typography,
    Tooltip,
    tooltipClasses,
    FormControl,
    InputLabel,
    Select,
    Dialog,
    DialogContent,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeleteIcon from "@mui/icons-material/Delete";
import { calculateLeavePerMonth, generateUniqueId, getDaysInYear, getFormattedDate, getYearObject, getFullName, isValidDays, isValidOpeningBalance, sortArrayByKey, titleCase, getInputId } from "../../../../utils";
import { useAlert } from "../../../../hook/useAlert";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import { useParams } from "react-router-dom";
import moment from "moment";
import SingleDatePicker from "../../../../components/SingleDatePicker";
import Input from "../../../../components/Input";
import Button from "../../../../components/Button";
import CircularLoader from "../../../../components/CircularLoader";
import { styled } from "@mui/styles";
import UserLeaveReport from "./userLeaveReport";
import useAuthentication from "../../../../hook/useAuthentication";
import PageNotFound from "../../../PageNotFound";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        overflowX: 'hidden',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
    },
}));

const UserLeave = ({ getUserDetails }) => {
    const { getCurrentUser } = useAuthentication();
    const { userId } = useParams();
    const currentUser = getCurrentUser();
    const [isEditable, setIsEditable] = useState(false);
    const [isLoading, setIsLoading] = useState({
        pageLoading: true,
        specialRequest: true,
        formSubmitting: false,
        yearLoading: false
    });
    const [specialRequestTypes, setSpecialRequestTypes] = useState([]);
    let initialValues = {
        max_absent_allowed: null,
        userLeaveData: [],
        specialRequest: [],
        specialHoliday: [],
        is_dedicated_developer: false,
        enable_sys_generated_leave: false,
        name: null,
        email: null,
        customHoliday: [],
        orgHolidayDays: [],
        organization_id: null,
        organization: {}
    };
    const [leaveData, setLeaveData] = useState(initialValues);
    const [optionalLeaveData, setOptionalLeaveData] = useState(initialValues);
    const [initialLeaveData, setInitialLeaveData] = useState(null);
    const [selectedYear, setSelectedYear] = useState("");
    const [years, setYears] = useState([]);
    const [activeYear, setActiveYear] = useState({});
    const showAlert = useAlert();
    const [errors, setErrors] = useState({
        userLeaveData: [],
        specialRequest: [],
        specialHoliday: [],
        max_absent_allowed: "",
    });
    const [leaveReportyears, setLeaveReportYears] = useState([]);
    const [isReporModalOpen, setIsReporModalOpen] = useState(false);

    let maxDays = getDaysInYear();
    let minDays = 0;

    const getIndexByUid = (array, UID) => {
        return array?.findIndex((value) => value?.unique_id === UID);
    };

    useEffect(() => {
        getAllHolidayYears();
    }, [userId]);

    const getAllHolidayYears = async () => {
        try {
            setIsLoading((prev) => ({ ...prev, years: true }));
            const response = await axiosInstance.get(
                API.getAllHolidaysYear(currentUser.organization_id)
            );
            if (response.status === 200) {
                let sortedYear = response?.data?.result?.sort((a, b) =>
                    a?.year?.toString()?.localeCompare(b?.year?.toString())
                );

                let removeFutureYear = sortedYear?.filter((yearData) => {
                    return yearData?.year <= new Date().getFullYear();
                });
                setYears(removeFutureYear);
                setLeaveReportYears(sortedYear);
                let activeYear = sortedYear?.find(yearData => yearData?.active);
                setSelectedYear(activeYear?.id);
                setActiveYear(activeYear)
                getLeaveSpecialRequests(activeYear);
            }
            setIsLoading((prev) => ({ ...prev, years: false }));
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading((prev) => ({ ...prev, years: false }));
        }
    };

    const getLeaveSpecialRequests = async (activeYear) => {
        setIsLoading((prev) => ({ ...prev, specialRequest: true }));
        try {
            let response = await axiosInstance.get(API.getLeaveSpecialRequests);
            if (response.status === 200) {
                setSpecialRequestTypes(response?.data?.data);
                getLeaveDetails(response?.data?.data, activeYear?.id);
                setIsLoading((prev) => ({ ...prev, specialRequest: false }));
            }
            else {
                setIsLoading((prev) => ({ ...prev, specialRequest: false }));
            }

        } catch (error) {
            console.error(error);
            setIsLoading((prev) => ({ ...prev, specialRequest: false }));
        }
    }

    const getLeaveDetails = async (specialRequestTypes, OrgYearId = null) => {
        try {
            // setIsLoading((prev) => ({ ...prev, pageLoading: true }));
            let response = await axiosInstance.get(
                API.getUserLeaveDetailsById(userId, OrgYearId || selectedYear)
            );
            if (response.status === 200) {
                let specialRequest = [
                    ...response?.data?.resultData?.specialRequest?.map((leave) => ({
                        ...leave,
                        unique_id: generateUniqueId(),
                        is_visible: true,
                        is_changed: false,
                    }))];

                let specialHoliday = [
                    ...response?.data?.resultData?.specialHoliday?.map((leave) => ({
                        ...leave,
                        unique_id: generateUniqueId(),
                        is_visible: true
                    }))];

                specialHoliday.push({
                    name: "",
                    leave_date: "",
                    unique_id: generateUniqueId(),
                    is_visible: true,
                    is_initial: true,
                    is_changed: false,
                });

                if (specialRequest?.length < specialRequestTypes?.length) {
                    specialRequest.push({
                        id: null,
                        user_id: specialRequest[0]?.user_id,
                        name: "",
                        allotment: null,
                        remaining: null,
                        points: null,
                        unique_id: generateUniqueId(),
                        is_visible: true,
                        is_initial: true,
                        is_changed: false,
                    });
                }
                setInitialLeaveData(response?.data?.resultData);
                setLeaveData(() => ({
                    ...response?.data?.resultData,
                    userLeaveData: response?.data?.resultData?.userLeaveData?.map(leaveData => {
                        return {
                            ...leaveData,
                            opening_balance: leaveData?.opening_balance || 0
                        }
                    }),
                    specialRequest: sortArrayByKey(specialRequest, "asc", "id"),
                    specialHoliday,
                    max_absent_allowed: response?.data?.resultData?.userLeaveData[0]?.org_leave_type?.Organization?.max_absent_allowed || 0,
                }))
                setOptionalLeaveData(() => ({
                    ...response?.data?.resultData,
                    specialRequest: sortArrayByKey(specialRequest, "asc", "id"),
                    specialHoliday,
                    max_absent_allowed: response?.data?.resultData?.userLeaveData[0]?.org_leave_type?.Organization?.max_absent_allowed || 0,
                }))
                setIsLoading((prev) => ({ ...prev, pageLoading: false }));
            } else {
                setLeaveData({})
                setIsLoading((prev) => ({ ...prev, pageLoading: false }));
            }
        } catch (error) {
            console.error(error);
            setLeaveData({})
            setIsLoading((prev) => ({ ...prev, pageLoading: false }));
        }
    };

    const handleRequestClick = (e, leave, currentIndex) => {
        validateSpacialRequest(e, currentIndex);
        let updatedList = leaveData?.specialRequest?.map((value) => {
            if (value?.unique_id === leave?.unique_id)
                return {
                    ...value,
                    name: e.target.value,
                    is_initial: false,
                    is_changed: true,
                };
            else return value;
        });

        let activeList = updatedList?.filter(value => !value?.is_deleted) || [];
        if (activeList?.length < specialRequestTypes?.length) {
            let lastData = updatedList[updatedList?.length - 1];
            if (!!lastData?.name) {
                updatedList?.push({
                    id: null,
                    name: '',
                    allotment: "",
                    is_visible: true,
                    unique_id: generateUniqueId(),
                    is_initial: true,
                    user_id: leaveData?.id,
                    is_changed: false,
                })
            }
        }
        setLeaveData((prev) => ({ ...prev, specialRequest: updatedList }));
    };

    function validateData(data) {
        let Errors = [];
        // Validate userLeaveData
        if (data?.userLeaveData) {
            data?.userLeaveData?.forEach((item, index) => {
                if (item?.opening_balance && parseFloat(item?.opening_balance) < 0) {
                    Errors.push(`Invalid opening balance at index ${index}`);
                }
                if (!item || !item?.total_leave_allocation) {
                    Errors.push(`Invalid total leave allocation at index ${index}`);
                } else {
                    if (item?.org_leave_type.Organization?.leave_frequency === "monthly" && !isValidDays(item?.total_leave_allocation)) {
                        Errors.push(`Invalid total leave allocation at index ${index}`);
                        // errors.userLeaveData[index] = { total_leave_allocation: "Please enter valid days which is divisible by 12 or 6" };
                    }
                }
            });
        }

        // Validate specialRequest
        if (data?.specialRequest) {
            data?.specialRequest?.forEach((item, index, array) => {
                if (index !== array?.length - 1) {
                    if (item && (item?.allotment === null || item?.allotment === "")) {
                        Errors.push(`Allotment required at index ${index}`);
                    }
                    if (item && !item?.name) {
                        Errors.push(`Name required at index ${index}`);
                    }
                }
                else {
                    if (!!item?.id) {
                        if (item && (item?.allotment === null || item?.allotment === "")) {
                            Errors.push(`Allotment required at index ${index}`);
                        }
                        if (item && !item?.name) {
                            Errors.push(`Name required at index ${index}`);
                        }
                    }
                    else {
                        if (item && !!item?.allotment && !item?.name) {
                            Errors.push(`Name required at index ${index}`);
                        }
                        if (item && !!item?.name && (item?.allotment === null || item?.allotment === "")) {
                            Errors.push(`Allotment required at index ${index}`);
                        }

                    }
                }
            });
        }

        // Validate specialHoliday
        if (data?.specialHoliday) {
            data?.specialHoliday?.forEach((item, index, array) => {
                if (index !== array?.length - 1) {
                    if (item && !item?.name) {
                        Errors.push(`Name required for special holiday at index ${index}`);
                    }
                    if (item && !item?.leave_date) {
                        Errors.push(`Date required for special holiday at index ${index}`);
                    }
                } else {
                    if (!!item?.id) {
                        if (item && !item?.name) {
                            Errors.push(`Name required for special holiday at index ${index}`);
                        }
                        if (item && !item?.leave_date) {
                            Errors.push(`Date required for special holiday at index ${index}`);
                        }
                    }
                }
            });
        }

        // Validate max_absent_allowed
        if (leaveData?.is_dedicated_developer) {
            if (!data.max_absent_allowed || !/^(0|[1-9]\d*)$/.test(data.max_absent_allowed)) {
                Errors.push("Max absent allowed is required and must be a positive integer");
            }
        }

        return Errors;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateData(leaveData)?.length === 0) {
            try {
                setIsLoading((prev) => ({ ...prev, formSubmitting: true }));
                let specialHoliday = leaveData?.specialHoliday?.filter((value) => !!value?.name && !!value?.leave_date)?.map((leave) => ({
                    id: leave?.id,
                    name: leave?.name,
                    leave_date: leave?.leave_date,
                    org_year_id: selectedYear,
                }));
                let specialRequest = leaveData?.specialRequest
                    ?.filter((value) => !!value?.name)
                    ?.map((leave, index, array) => {
                        let updatedValue = {
                            id: leave?.id || null,
                            user_id: leaveData?.id,
                            name: leave?.name,
                            allotment: leave?.allotment >= 0 ? Number.parseInt(leave?.allotment) : null,
                            remaining: leave?.remaining >= 0 ? Number.parseInt(leave?.remaining) : null,
                            points: leave?.point >= 0 ? Number.parseInt(leave?.points) : null,
                            org_year_id: selectedYear,
                        };
                        if (leave?.is_changed) {
                            let initialReq = initialLeaveData?.specialRequest?.find((value) => value?.id === leave?.id);
                            let remaining_days = updatedValue?.allotment - (initialReq?.allotment || 0);
                            let totalRemaining = remaining_days + (initialReq?.remaining || 0)
                            updatedValue.remaining = (totalRemaining <= 0 || updatedValue?.allotment === 0) ? 0 : totalRemaining
                        }
                        if (!updatedValue?.id) {
                            delete updatedValue?.id;
                        } else {
                            updatedValue.user_id = leaveData?.id;
                        }
                        if (leave?.is_deleted) {
                            updatedValue['is_deleted'] = leave?.is_deleted;
                        }
                        return updatedValue;
                    });
                let userLeaveData = leaveData?.userLeaveData?.map((leave) => {
                    let leaveData = {
                        id: leave?.id,
                        alloted_days:
                            leave?.alloted_days >= 0
                                ? Number.parseInt(leave?.alloted_days)
                                : null,
                        total_leave_allocation:
                            leave?.total_leave_allocation >= 0
                                ? Number.parseInt(leave?.total_leave_allocation)
                                : null,
                        opening_balance:
                            leave?.opening_balance >= 0
                                ? Number.parseFloat(leave?.opening_balance)
                                : null,
                        remaining_days:
                            leave?.remaining_days >= 0
                                ? Number.parseFloat(leave?.remaining_days)
                                : null,
                        org_year_id: selectedYear,
                    }
                    return leaveData;
                });
                const payload = {
                    organization_id: leaveData?.organization_id,
                    is_dedicated_developer: leaveData?.is_dedicated_developer,
                    enable_sys_generated_leave: leaveData?.enable_sys_generated_leave,
                    max_absent_allowed: leaveData?.is_dedicated_developer ? leaveData?.max_absent_allowed : Number.parseInt(leaveData?.userLeaveData[0]?.org_leave_type?.max_leave_encashment),
                    specialRequest: specialRequest,
                    specialHoliday: specialHoliday,
                    userLeaveData: userLeaveData,
                };
                let response = await axiosInstance.put(
                    API.updateUserLeaveDetailsById(userId, selectedYear),
                    payload
                );
                if (response.status === 200) {
                    showAlert(response.data?.message, "success");
                    toggleEdit();
                    getLeaveDetails(specialRequestTypes);
                    getUserDetails(userId);
                } else {
                    showAlert("Something went wrong", "error");
                    toggleEdit();
                    getLeaveDetails(specialRequestTypes);
                    getUserDetails(userId);
                }

                setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
            } catch (error) {
                console.error(error);
                setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
            }
        }
    };

    const validateMaxDays = (e) => {
        let error = "";
        let { value } = e.target;
        if (!value) {
            error = "Required";
        } else {
            error = "";
        }
        setErrors((prev) => ({ ...prev, max_absent_allowed: error }))
    }

    const handleChangeMaxDays = (e) => {
        let { value } = e.target;
        validateMaxDays(e)
        setLeaveData((prev) => ({ ...prev, max_absent_allowed: value }))
    }

    const handleDeleteChangeRequestTypes = (unique_id) => {
        let specialRequests = [...leaveData?.specialRequest];
        let updatedSpecialRequests = specialRequests?.map((value, index) => {
            if (value?.unique_id === unique_id) {
                if (!!value?.id) {
                    return {
                        ...value,
                        is_deleted: true,
                        is_visible: false
                    }
                }
                else {
                    return null;
                }
            }
            else {
                return value;
            }
        });
        let filteredData = updatedSpecialRequests?.filter(value => !!value);
        let deletedList = filteredData?.filter(value => value?.is_deleted);
        let lastEle = filteredData[filteredData?.length - 1];
        if ((filteredData?.length - deletedList?.length) < specialRequestTypes?.length && lastEle?.name !== null && lastEle?.allotment !== null) {
            let initialData = {
                name: null,
                allotment: null,
                is_visible: true,
                is_changed: false,
                id: null,
                is_initial: true,
                unique_id: generateUniqueId(),
            };
            filteredData.push(initialData);
        }
        setLeaveData(prev => ({ ...prev, specialRequest: filteredData }))
    };

    const getUserLeaveTypeData = (leaveType = "Sick Leave") => {
        let data = leaveData?.userLeaveData?.find(
            (value) => value?.org_leave_type?.LeaveType?.name === leaveType
        );
        let index = leaveData?.userLeaveData?.findIndex(
            (value) => value?.org_leave_type?.LeaveType?.name === leaveType
        );
        return { ...data, index };
    };

    const validateSpacialRequest = (event, currentIndex) => {
        const { value, name } = event.target;
        let updatedError = [...errors?.specialRequest || []]
        if (currentIndex !== leaveData?.specialRequest?.length - 1) {
            if (value === null || value === "") {
                updatedError[currentIndex] = { [name]: "Required" };
            } else {
                updatedError[currentIndex] = { [name]: "" };
            }
        } else {
            let lastEle = leaveData?.specialRequest[currentIndex];
            if (!!lastEle?.id) {
                if (value === null || value === "") {
                    updatedError[currentIndex] = { [name]: "Required" };
                } else {
                    updatedError[currentIndex] = { [name]: "" };
                }
            }
        }
        setErrors((prev) => ({
            ...prev,
            specialRequest: updatedError,
        }));
    }

    const validateSpacialHoliday = (event, currentIndex, isDate = false) => {
        const { value, name } = isDate ? { name: "leave_date", value: event } : event.target;
        let updatedError = [...errors?.specialHoliday || []]
        let currentHoliday = leaveData?.specialHoliday[currentIndex];
        if (!value) {
            if (name === "name" && currentHoliday?.leave_date === "") {
                updatedError[currentIndex] = { [name]: "" };
            } else {
                updatedError[currentIndex] = { [name]: "Required" };
            }
        } else {
            updatedError[currentIndex] = { [name]: "" };
        }
        setErrors((prev) => ({
            ...prev,
            specialHoliday: updatedError,
        }));
    }

    const validateLeaveTypes = (event, currentIndex) => {
        const { value } = event.target;
        if (!value) {
            let updatedError = [...errors?.userLeaveData || []]
            updatedError[currentIndex] = { total_leave_allocation: "Required" };
            setErrors((prev) => ({
                ...prev,
                userLeaveData: updatedError,
            }));
        } else {
            let leave_frequency = leaveData?.userLeaveData[currentIndex]?.org_leave_type?.Organization?.leave_frequency || "monthly";
            let updatedError = [...errors?.userLeaveData || []]
            if (leave_frequency === "monthly") {
                let error = !Boolean(parseInt(value)) ? "Required" : !isValidDays(parseInt(value)) ? "Please enter valid days which is divisible by 12 or 6" : "";
                updatedError[currentIndex] = { total_leave_allocation: error };
            } else {
                let error = !Boolean(parseInt(value)) && parseInt(value) !== 0 ? "Required" : "";
                updatedError[currentIndex] = { total_leave_allocation: error };
            }
            setErrors((prev) => ({
                ...prev,
                userLeaveData: updatedError,
            }));
        }
    };

    const handleChangeSpecialRequest = (event, currentIndex) => {
        validateSpacialRequest(event, currentIndex);
        let updatedList = leaveData?.specialRequest?.map((value, index) => {
            if (index === currentIndex)
                return {
                    ...value,
                    allotment: event.target.value !== null ? parseInt(event.target.value) : null,
                    is_changed: true,
                };
            else
                return value;
        });
        let activeList = updatedList?.filter(value => !value?.is_deleted) || [];
        if (activeList?.length < specialRequestTypes?.length) {
            let lastData = updatedList[updatedList?.length - 1];
            if (lastData?.allotment !== null && !!lastData?.name) {
                updatedList?.push({
                    id: null,
                    name: '',
                    allotment: "",
                    is_visible: true,
                    unique_id: generateUniqueId(),
                    is_initial: true,
                    is_changed: false,
                })
            }
        }
        setLeaveData((prev) => ({ ...prev, specialRequest: updatedList }));
    }

    let getIgnoreDates = () => {
        if (!isLoading?.pageLoading) {
            let array = [
                ...leaveData?.orgHolidayDays?.map((value) => value),
                ...leaveData?.customHoliday?.map((value) => value?.date),
                // ...leaveData?.userLeaveData?.map((value) => value?.date),
            ];
            if (leaveData?.specialHoliday?.length > 0) {
                let data = leaveData?.specialHoliday?.map((value) => value?.leave_date)?.filter(value => !!value);
                array.push(...data);
            }
            let result = Array.from(new Set(array?.map((date) => getFormattedDate(date))));
            return result;
        }
        return [];
    };

    const handleChangeSpecialHoliday = (event, currentIndex, isDate = false) => {
        validateSpacialHoliday(event, currentIndex, isDate);
        let updatedList = leaveData?.specialHoliday?.map((value, index) => {
            if (index === currentIndex) {
                if (isDate) {
                    return {
                        ...value,
                        leave_date: moment(new Date(event)).format("YYYY-MM-DD")
                    };
                } else {
                    return {
                        ...value,
                        [event.target.name]: event.target.value,
                    };
                }
            }
            else {
                return value;
            }
        });

        let lastData = updatedList[updatedList?.length - 1];
        if (!!lastData?.name && !!lastData?.leave_date) {
            updatedList?.push({
                name: "",
                leave_date: "",
                unique_id: generateUniqueId(),
                is_visible: true,
                is_initial: true,
            })
        }
        setLeaveData((prev) => ({ ...prev, specialHoliday: updatedList }));
    }

    const validateOpeningBalance = (event, currentIndex) => {
        const { value } = event.target;
        let updatedError = [...errors?.userLeaveData || []]
        // let error = value === "null" || value === null || value === "" || value < 0 ? "Required" : !isValidOpeningBalance(value) ? "Please enter valid opening balance" : "";
        let error = !isValidOpeningBalance(value) ? "Please enter valid opening balance" : "";
        updatedError[currentIndex] = { opening_balance: error };
        setErrors((prev) => ({
            ...prev,
            userLeaveData: updatedError,
        }));
    }
    const handleChangeLeaveData = (e, currentIndex) => {
        validateLeaveTypes(e, currentIndex);
        let updatedList = leaveData?.userLeaveData?.map((value, index) => {
            if (index === currentIndex)
                return {
                    ...value,
                    total_leave_allocation: !!e.target.value ? parseInt(e.target.value) : null,
                };
            else
                return value;
        });
        setLeaveData((prev) => ({ ...prev, userLeaveData: updatedList }));
    }

    const handleChangeOpeningBalance = (e, currentIndex) => {
        validateOpeningBalance(e, currentIndex);
        let updatedList = leaveData?.userLeaveData?.map((value, index) => {
            if (index === currentIndex) {
                let _value = e?.target?.value?.toString();
                _value?.replace(/[^\d.-]/g, '');
                return {
                    ...value,
                    opening_balance: parseFloat(_value) ?? 0
                };
            }
            else {
                return value;
            }
        });
        setLeaveData((prev) => ({ ...prev, userLeaveData: updatedList }));
    }

    const toggleEdit = () => {
        setIsEditable((prev) => !prev);
    };

    const getYearObjectById = (id) => {
        if (years?.length) {
            return years?.find(value => value?.id === id);
        }
        return null;
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#3F5C76',
            color: '#ffffff',
            maxWidth: 310,
            padding: 12,
        },
    }));

    const handleCancel = () => {
        toggleEdit(isEditable);
        setLeaveData(optionalLeaveData);
    }

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
        getLeaveDetails(specialRequestTypes, event.target.value);
    };

    return ((isLoading?.pageLoading || isLoading?.specialRequest) ?
        <CircularLoader height="500px" /> :
        (!!leaveData && Object.keys(leaveData)?.length && !isLoading?.pageLoading) ?
            <React.Fragment>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"flex-start"} >
                    <Grid item mb={2}>
                        <Box justifyContent="space-between" alignItems="center">
                            <Grid item width={265} maxWidth={265}>
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <InputLabel htmlFor="year_label">
                                        Select Year
                                    </InputLabel>
                                    <Select
                                        inputProps={{ id: "year_label" }}
                                        id="year-range"
                                        value={selectedYear}
                                        onChange={(e) => handleYearChange(e)}
                                        label="Select Year"
                                        sx={{
                                            ".MuiSelect-select": {
                                                fontSize: 14,
                                                fontWeight: 400,
                                                color: "dark.800",
                                            },
                                        }}
                                        disabled={isEditable}
                                    >
                                        {years?.map((yearData) => (
                                            <MenuItem value={yearData?.id} key={yearData?.id}>
                                                {getYearObject(yearData)?.yearDashedFormat}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Box>
                    </Grid>
                    {!isEditable ? <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            sx={{ color: "white", boxShadow: "none", textTransform: "none" }}
                            onClick={toggleEdit}
                            disabled={!(years.find(yearData => yearData?.active)?.year === getYearObjectById(selectedYear)?.year)}
                        >
                            Edit
                        </Button>
                    </Grid> : null}
                </Box>
                {/* <Grid item>
                    <Box display="flex" alignItems="center" mb={1}>
                        <Typography color="text.dark" fontSize={14} mr={1} sx={{ lineHeight: '24px' }}>
                            System Generated Leaves
                        </Typography>
                        <HtmlTooltip arrow placement="top"
                            title={
                                <React.Fragment>
                                    <Typography color={"white"} textAlign={"center"} fontSize={16} fontWeight={500} lineHeight={"24px"}>System will automatically creates a leave upon unavailability for the user.</Typography>
                                </React.Fragment>
                            }
                        >
                            <i style={{ width: '18px', height: '18px' }}><HelpOutlineIcon sx={{ fontSize: '18px', color: 'secondary.main' }} /></i>
                        </HtmlTooltip>
                    </Box>
                    <Typography color="dark.600" fontSize={12}>
                        Enable System Generated Leaves for this user
                    </Typography>
                    <FormControlLabel
                        id='enable_sys_generated_leave'
                        name='enable_sys_generated_leave'
                        label={`Enable System Generated Leaves for all work-types`}
                        labelPlacement="end"
                        control={<Checkbox checked={leaveData?.enable_sys_generated_leave} />}
                        disabled={!isEditable}
                        onChange={(e, checked) => {
                            setLeaveData((prev) => ({ ...prev, enable_sys_generated_leave: checked }))
                        }}
                    />
                </Grid> */}
                <Box width={"100%"} maxWidth="100%">
                    <form onSubmit={handleSubmit} >
                        <Grid item mb={4}>
                            <Typography
                                variant="h6"
                                fontSize={20}
                                fontWeight={400}
                                color="dark.800"
                                sx={{ lineHeight: "30px", letterSpacing: "0.15px" }}
                            >
                                Leave Types
                            </Typography>
                            <Typography
                                variant="caption"
                                display="block"
                                fontSize={14}
                                fontWeight={400}
                                color="#014361"
                                mb={2}
                                sx={{ lineHeight: "20px", letterSpacing: "0.17px" }}
                            >
                                Leave Availability Frequency is {" "}
                                <b>
                                    {titleCase(leaveData?.organization?.leave_frequency ? leaveData?.organization?.leave_frequency : "-")}
                                </b>
                            </Typography>
                            <Typography
                                fontWeight={500}
                                fontSize={16}
                                color="#014361"
                                sx={{ lineHeight: "24px", letterSpacing: "0.15px" }}
                                component={"div"}
                            >
                                <Typography
                                    fontSize={21}
                                    fontWeight={500}
                                    display="inline-block"
                                    sx={{ lineHeight: "31px", letterSpacing: "0.15px" }}
                                >
                                    {Number.parseInt(
                                        getUserLeaveTypeData("Sick Leave")
                                            ?.total_leave_allocation || 0
                                    ) +
                                        Number.parseInt(
                                            getUserLeaveTypeData("Casual Leave")
                                                ?.total_leave_allocation
                                        ) || 0}
                                </Typography>{" "}
                                Total Yearly Paid Leaves
                            </Typography>
                            <Typography
                                variant="caption"
                                display="block"
                                fontSize={14}
                                fontWeight={400}
                                color="#014361"
                                sx={{ lineHeight: "20px", letterSpacing: "0.17px" }}
                            >
                                This include all the Yearly leaves for all leave types.
                            </Typography>
                            <Box bgcolor={"offWhite"} p={4} sx={{ mt: 2 }}>
                                <Stack direction="row" width="100%" maxWidth="100%">
                                    <Grid item maxWidth="70%">
                                        <Stack direction="row" spacing={4} width={768} maxWidth="100%">
                                            {!!getUserLeaveTypeData("Casual Leave")?.org_leave_type_id ?
                                                <Grid item flex={1}>
                                                    <Input
                                                        inputProps={{ min: minDays, max: maxDays }}
                                                        min={minDays}
                                                        max={maxDays}
                                                        type="number"
                                                        isDays={true}
                                                        label={`Yearly Casual Leave (Days)`}
                                                        variant="standard"
                                                        sx={{ width: "100%" }}
                                                        name={`total_leave_allocation`}
                                                        id={getInputId("userLeaveData", getUserLeaveTypeData("Casual Leave")?.index)}
                                                        onChange={(e) => {
                                                            handleChangeLeaveData(e, getUserLeaveTypeData("Casual Leave")?.index)
                                                        }}
                                                        onBlur={(e) => validateLeaveTypes(e, getUserLeaveTypeData("Casual Leave")?.index)}
                                                        value={
                                                            getUserLeaveTypeData("Casual Leave")
                                                                ?.total_leave_allocation
                                                        }
                                                        error={!!errors?.userLeaveData && !!errors?.userLeaveData[getUserLeaveTypeData("Casual Leave")?.index]?.total_leave_allocation}
                                                        helperText={errors?.userLeaveData && errors?.userLeaveData[getUserLeaveTypeData("Casual Leave")?.index]?.total_leave_allocation}
                                                        disabled={!isEditable}
                                                    />
                                                    {calculateLeavePerMonth(
                                                        getUserLeaveTypeData("Casual Leave")
                                                            ?.total_leave_allocation
                                                    ) > 0 && leaveData?.userLeaveData[0]?.org_leave_type?.Organization?.leave_frequency === "monthly" ?
                                                        <Typography
                                                            color="dark.600"
                                                            fontSize={12}
                                                            fontWeight={400}
                                                            sx={{ lineHeight: "20px", letterSpacing: "0.4px" }}
                                                        >
                                                            <b>
                                                                =
                                                                {calculateLeavePerMonth(
                                                                    getUserLeaveTypeData("Casual Leave")
                                                                        ?.total_leave_allocation
                                                                )}{" "}
                                                                Leaves
                                                            </b>{" "}
                                                            / Month
                                                        </Typography>
                                                        : null}
                                                </Grid> : null}
                                            {!!getUserLeaveTypeData("Casual Leave")?.org_leave_type_id ?
                                                <Grid item flex={1} alignSelf={'center'}>
                                                    <Typography
                                                        color="dark.600"
                                                        fontSize={12}
                                                        fontWeight={400}
                                                        sx={{ lineHeight: "20px", letterSpacing: "0.4px" }}
                                                    >
                                                        Alloted Casual Leave
                                                    </Typography>
                                                    <Typography
                                                        color="dark.600"
                                                        fontSize={12}
                                                        fontWeight={400}
                                                        sx={{ lineHeight: "20px", letterSpacing: "0.4px" }}
                                                    >
                                                        <b>
                                                            {getUserLeaveTypeData("Casual Leave")?.alloted_days || "0"}{" "} Leaves{" "}
                                                        </b>
                                                        (Leaves Taken - {" "}
                                                        {getUserLeaveTypeData("Casual Leave")?.leaves_taken || 0})
                                                    </Typography>
                                                </Grid> : null}

                                            {!!getUserLeaveTypeData("Casual Leave")?.org_leave_type_id ?
                                                <Grid item flex={1}>
                                                    <Input
                                                        type="number"
                                                        label={`Opening Balance (Days)`}
                                                        variant="standard"
                                                        parseFunction={Number.parseFloat}
                                                        isAllowedAutoFloat={true}
                                                        min={0}
                                                        max={365}
                                                        sx={{ width: "100%" }}
                                                        name={`opening_balance`}
                                                        id={getInputId("opening_balance", getUserLeaveTypeData("Casual Leave")?.index)}
                                                        onChange={(e) => handleChangeOpeningBalance(e, getUserLeaveTypeData("Casual Leave")?.index)}
                                                        onBlur={(e) => validateOpeningBalance(e, getUserLeaveTypeData("Casual Leave")?.index)}
                                                        value={getUserLeaveTypeData("Casual Leave")?.opening_balance}
                                                        error={!!errors?.userLeaveData && !!errors?.userLeaveData[getUserLeaveTypeData("Casual Leave")?.index]?.opening_balance}
                                                        helperText={errors?.userLeaveData && errors?.userLeaveData[getUserLeaveTypeData("Casual Leave")?.index]?.opening_balance}
                                                        disabled={!isEditable}
                                                        required={false}
                                                        zeroAllowed={true}
                                                    />
                                                </Grid> : null}
                                        </Stack>
                                        <Stack direction="row" mt={2} spacing={4} width={768} maxWidth="100%">
                                            {!!getUserLeaveTypeData("Sick Leave")?.org_leave_type_id ?
                                                <Grid item flex={1}>
                                                    <Input
                                                        type="number"
                                                        inputProps={{ min: minDays, max: maxDays }}
                                                        min={minDays}
                                                        max={maxDays}
                                                        isDays={true}
                                                        label={`Yearly Sick Leave (Days)`}
                                                        variant="standard"
                                                        sx={{ width: "100%" }}
                                                        name={`total_leave_allocation`}
                                                        id={`total_leave_allocation`}
                                                        onChange={(e) => handleChangeLeaveData(e, getUserLeaveTypeData("Sick Leave")?.index)}
                                                        onBlur={(e) => validateLeaveTypes(e, getUserLeaveTypeData("Sick Leave")?.index)}
                                                        value={getUserLeaveTypeData("Sick Leave")?.total_leave_allocation}
                                                        error={!!errors?.userLeaveData && !!errors?.userLeaveData[getUserLeaveTypeData("Sick Leave")?.index]?.total_leave_allocation}
                                                        helperText={errors?.userLeaveData && errors?.userLeaveData[getUserLeaveTypeData("Sick Leave")?.index]?.total_leave_allocation}
                                                        disabled={!isEditable}
                                                    />
                                                    {calculateLeavePerMonth(
                                                        getUserLeaveTypeData("Sick Leave")
                                                            ?.total_leave_allocation
                                                    ) > 0 && leaveData?.userLeaveData[0]?.org_leave_type?.Organization?.leave_frequency === "monthly" ?
                                                        <Typography
                                                            color="dark.600"
                                                            fontSize={12}
                                                            fontWeight={400}
                                                            sx={{ lineHeight: "20px", letterSpacing: "0.4px" }}
                                                        >
                                                            <b>
                                                                =
                                                                {calculateLeavePerMonth(
                                                                    getUserLeaveTypeData("Sick Leave")
                                                                        ?.total_leave_allocation
                                                                )}{" "}
                                                                Leaves
                                                            </b>{" "}
                                                            / Month
                                                        </Typography>
                                                        : null}
                                                </Grid> : null}
                                            {!!getUserLeaveTypeData("Sick Leave")?.org_leave_type_id ?
                                                <Grid item flex={1} alignSelf={'center'}>
                                                    <Typography
                                                        color="dark.600"
                                                        fontSize={12}
                                                        fontWeight={400}
                                                        sx={{ lineHeight: "20px", letterSpacing: "0.4px" }}
                                                    >
                                                        Alloted Sick Leave
                                                    </Typography>
                                                    <Typography
                                                        color="dark.600"
                                                        fontSize={12}
                                                        fontWeight={400}
                                                        sx={{ lineHeight: "20px", letterSpacing: "0.4px" }}
                                                    >
                                                        <b>
                                                            {getUserLeaveTypeData("Sick Leave")?.alloted_days || "0"}{" "} Leaves{" "}
                                                        </b>
                                                        (Leaves Taken - {" "}
                                                        {getUserLeaveTypeData("Sick Leave")?.leaves_taken || 0})
                                                    </Typography>
                                                </Grid> : null}
                                            {!!getUserLeaveTypeData("Sick Leave")?.org_leave_type_id ?
                                                <Grid item flex={1}>
                                                    <Input
                                                        type="number"
                                                        label={`Opening Balance (Days)`}
                                                        variant="standard"
                                                        sx={{ width: "100%" }}
                                                        parseFunction={Number.parseFloat}
                                                        isAllowedAutoFloat={true}
                                                        min={0}
                                                        max={365}
                                                        name={`opening_balance`}
                                                        id={`opening_balance`}
                                                        onChange={(e) => handleChangeOpeningBalance(e, getUserLeaveTypeData("Sick Leave")?.index)}
                                                        onBlur={(e) => validateOpeningBalance(e, getUserLeaveTypeData("Sick Leave")?.index)}
                                                        value={getUserLeaveTypeData("Sick Leave")?.opening_balance}
                                                        error={!!errors?.userLeaveData && !!errors?.userLeaveData[getUserLeaveTypeData("Sick Leave")?.index]?.opening_balance}
                                                        helperText={errors?.userLeaveData && errors?.userLeaveData[getUserLeaveTypeData("Sick Leave")?.index]?.opening_balance}
                                                        disabled={!isEditable}
                                                        required={false}
                                                        zeroAllowed={true}
                                                    />
                                                </Grid> : null}
                                        </Stack>
                                    </Grid>
                                    <Grid item sx={{ alignSelf: 'start', textAlign: 'end' }} minWidth="30%" maxWidth="30%">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            sx={{ color: "white", boxShadow: "none", textTransform: "none" }}
                                            onClick={() => setIsReporModalOpen(true)}
                                        >
                                            {`${getFullName(leaveData)?.split(' ')?.[0]}'s Leave Report`}
                                        </Button>
                                    </Grid>
                                </Stack>
                            </Box>
                        </Grid>
                        {/* <Divider sx={{ my: { xs: 3, lg: 4 } }} /> */}
                        <Grid mb={4} >
                            <Typography
                                variant="h6"
                                fontSize={20}
                                fontWeight={400}
                                mb={1}
                                color="dark.800"
                                sx={{ lineHeight: "30px", letterSpacing: "0.15px" }}
                            >
                                Specials Leaves
                            </Typography>
                            <Card
                                sx={{
                                    boxShadow: "none",
                                    padding: 2,
                                    borderRadius: "4px",
                                    backgroundColor: "#E5F6FD",
                                    display: "flex",
                                    mb: 2,
                                }}
                            >
                                <InfoOutlinedIcon
                                    sx={{ mr: 1.5, fontSize: 22, color: "#0288D1" }}
                                />
                                <div style={{}}>
                                    <Typography
                                        mb={0.5}
                                        fontSize={15}
                                        fontWeight={700}
                                        color="#014361"
                                        sx={{ lineHeight: "22px", letterSpacing: "0.15" }}
                                    >
                                        {leaveData?.specialHoliday?.filter(value => !!value?.name && !!value?.leave_date)?.length || 0} Special
                                        Leaves available
                                    </Typography>
                                    <Typography
                                        fontSize={14}
                                        fontWeight={400}
                                        color="#014361"
                                        sx={{ lineHeight: "20px", letterSpacing: "0.17" }}
                                    >
                                        Public holidays are only{" "}
                                        <b>{leaveData?.customHoliday?.length || 0}</b> in this year
                                        (Excluding Saturday- Sunday).
                                    </Typography>
                                </div>
                            </Card>
                            <Box bgcolor={"offWhite"} p={4} sx={{ mt: 2 }}>
                                <Stack direction="column" spacing={4} width={768} maxWidth="100%">
                                    {leaveData?.specialHoliday?.map((leave, index, array) => (
                                        <Stack direction="row" spacing={4} key={index} width={"100%"}>
                                            <Grid item flex={1}>
                                                <Grid
                                                    item
                                                    flex={1}
                                                    sx={{
                                                        input: {
                                                            width: "100%",
                                                            height: 30,
                                                            border: "none !important",
                                                            boxShadow: "none !important",
                                                            borderRadius: "0px",
                                                            paddingBottom: "6px",
                                                            paddingLeft: "28px",
                                                            borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
                                                            margin: "0",
                                                            position: "relative",
                                                            background: "transparent",
                                                            color: "dark.800",
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            lineHeight: "24px",
                                                            letterSpacing: "0.15px",
                                                        },
                                                        'input:hover': {
                                                            borderBottomColor: '#333333 !important',
                                                        },
                                                        'input:disabled': {
                                                            color: 'rgb(170, 170, 170)',
                                                            borderColor: 'rgba(118, 118, 118, 0.3) !important',
                                                        },
                                                        'input:disabled:hover': {
                                                            color: 'rgb(170, 170, 170)',
                                                            borderColor: 'rgba(118, 118, 118, 0.3) !important',
                                                        },
                                                        'input:focus': {
                                                            borderBottomColor: '#047FE0 !important',
                                                        },
                                                        ".rmdp-container": {
                                                            minWidth: "100px",
                                                        },
                                                    }}
                                                >
                                                    <Typography id="demo-simple-select-standard-label" fontSize={12} fontWeight={400} color="dark.600" sx={{ width: '100%', letterSpacing: '0.15px', marginTop: '-3px', marginBottom: '2px' }}>Leave Date {index + 1}</Typography>
                                                    <div style={{ height: 1 }}>
                                                        <CalendarTodayIcon
                                                            sx={{
                                                                fontSize: 20,
                                                                color: !isEditable ? "rgb(170, 170, 170) !important" : "#3F5C76",
                                                                marginBottom: "-5px",
                                                                marginLeft: "2px",
                                                            }}
                                                        />
                                                    </div>
                                                    <SingleDatePicker
                                                        ignoreDates={getIgnoreDates()}
                                                        disabled={!isEditable}
                                                        value={moment(leave?.leave_date).format(
                                                            "DD/MM/YYYY"
                                                        )}
                                                        onChange={(value) => {
                                                            handleChangeSpecialHoliday(value, index, true)
                                                        }}
                                                        name={`leave_date`}
                                                        id={getInputId("leave-date", index)}
                                                        isMultiple={false}
                                                        error={Boolean(errors?.specialHoliday?.[index]?.leave_date)}
                                                        helperText={errors?.specialHoliday?.[index]?.leave_date}
                                                        required={!(array?.length - 1 === index)}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item flex={1}>
                                                <Input
                                                    value={leave?.name}
                                                    onChange={(e) => handleChangeSpecialHoliday(e, index)}
                                                    onBlur={(e) => validateSpacialHoliday(e, index)}
                                                    name={`name`}
                                                    id={getInputId("name", index)}
                                                    error={Boolean(errors?.specialHoliday?.[index]?.name)}
                                                    helperText={errors?.specialHoliday?.[index]?.name}
                                                    label={`Festival`}
                                                    variant="standard"
                                                    sx={{ width: "100%" }}
                                                    disabled={!isEditable}
                                                    isOnlyCapitalFirstLatter={true}
                                                    required={!(array?.length - 1 === index)}
                                                />
                                            </Grid>
                                        </Stack>
                                    ))}
                                </Stack>
                            </Box>
                        </Grid>
                        {/* <Divider sx={{ my: { xs: 3, lg: 4 } }} /> */}
                        <Grid mb={4} >
                            <Typography
                                variant="h6"
                                fontSize={20}
                                fontWeight={400}
                                color="dark.800"
                                sx={{ lineHeight: "30px", letterSpacing: "0.15px" }}
                            >
                                Special Credits
                            </Typography>
                            <Box bgcolor={"offWhite"} p={4} sx={{ mt: 2 }}>
                                <Stack spacing={4} width={552} maxWidth="100%">
                                    {leaveData?.specialRequest?.filter(value => value?.is_visible)?.map(
                                        (leave, index, array) => (
                                            <Stack direction="row" spacing={4} key={index}>
                                                <Grid item flex={"0 0 100%"} maxWidth={322}>
                                                    <Input
                                                        id={getInputId("request", index)}
                                                        select
                                                        type="select"
                                                        label="Request"
                                                        variant="standard"
                                                        name="name"
                                                        sx={{ width: "100%" }}
                                                        value={Boolean(leave?.is_initial) && !Boolean(leave?.name) ? "" : leave?.name}
                                                        onChange={(e) => {
                                                            handleRequestClick(e, leave, getIndexByUid(leaveData?.specialRequest, leave?.unique_id));
                                                        }}
                                                        onBlur={(e) => validateSpacialRequest(e, getIndexByUid(leaveData?.specialRequest, leave?.unique_id))}
                                                        disabled={!isEditable}
                                                        error={
                                                            errors?.specialRequest &&
                                                            errors?.specialRequest[
                                                            getIndexByUid(leaveData?.specialRequest, leave?.unique_id)
                                                            ] &&
                                                            Boolean(
                                                                errors?.specialRequest?.[
                                                                    getIndexByUid(leaveData?.specialRequest, leave?.unique_id)
                                                                ]?.name
                                                            ) &&
                                                            index !== array.length - 1
                                                        }
                                                        helperText={
                                                            errors?.specialRequest &&
                                                            errors?.specialRequest[
                                                            getIndexByUid(leaveData?.specialRequest, leave?.unique_id)
                                                            ] &&
                                                            Boolean(
                                                                errors?.specialRequest?.[
                                                                    getIndexByUid(leaveData?.specialRequest, leave?.unique_id)
                                                                ]?.name
                                                            ) &&
                                                            index !== array.length - 1
                                                        }
                                                        required={!(array?.length - 1 === index)}
                                                    >
                                                        {specialRequestTypes?.map(
                                                            ({ name, is_visible }, index) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    value={name}
                                                                    disabled={!!array?.find(value => value?.name?.toLowerCase() === name?.toLowerCase())}
                                                                    sx={{
                                                                        "&:hover": {
                                                                            background: "rgba(4, 127, 224, 0.1)",
                                                                        },
                                                                    }}
                                                                >
                                                                    {titleCase(name)}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                    </Input>
                                                </Grid>
                                                <Grid item flex={1} display="flex">
                                                    <div style={{ width: "100%" }}>
                                                        {!!leave?.name || index === 0 ?
                                                            <Input
                                                                id={getInputId("allotment", index)}
                                                                label="Credit(s)"
                                                                variant="standard"
                                                                name="allotment"
                                                                sx={{ width: "100%" }}
                                                                onChange={(e) => {
                                                                    handleChangeSpecialRequest(e, getIndexByUid(leaveData?.specialRequest, leave?.unique_id));
                                                                }}
                                                                onBlur={(e) => validateSpacialRequest(e, getIndexByUid(leaveData?.specialRequest, leave?.unique_id))}
                                                                value={Boolean(leave?.is_initial) && leave?.allotment === null ? "" : leave?.allotment}
                                                                type="number"
                                                                error={
                                                                    errors?.specialRequest &&
                                                                    errors?.specialRequest[
                                                                    getIndexByUid(leaveData?.specialRequest, leave?.unique_id)
                                                                    ] &&
                                                                    Boolean(
                                                                        errors?.specialRequest?.[
                                                                            getIndexByUid(leaveData?.specialRequest, leave?.unique_id)
                                                                        ]?.allotment
                                                                    )
                                                                }
                                                                helperText={
                                                                    errors?.specialRequest &&
                                                                    errors?.specialRequest[
                                                                    getIndexByUid(leaveData?.specialRequest, leave?.unique_id)
                                                                    ] &&
                                                                    errors?.specialRequest?.[
                                                                        getIndexByUid(leaveData?.specialRequest, leave?.unique_id)
                                                                    ]?.allotment
                                                                }
                                                                required={!(array?.length - 1 === index)}
                                                                disabled={!isEditable}
                                                            /> : null}
                                                    </div>
                                                    <IconButton
                                                        aria-label="delete"
                                                        size="large"
                                                        sx={{ ml: 3 }}
                                                        onClick={() =>
                                                            handleDeleteChangeRequestTypes(leave?.unique_id)
                                                        }
                                                        disabled={array?.length - 1 === index || !isEditable}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Stack>
                                        )
                                    )}
                                </Stack>
                            </Box>
                        </Grid>
                        {/* <Divider sx={{ my: { xs: 3, lg: 4 } }} /> */}
                        < Grid >
                            <Typography
                                variant="h6"
                                fontSize={20}
                                fontWeight={400}
                                color="dark.800"
                                sx={{ lineHeight: "30px", letterSpacing: "0.15px" }}
                            >
                                Dedicated Developer
                            </Typography>
                            <Grid container mt={2} alignItems="center">
                                <Grid item width={300} pr={3}>
                                    <FormControlLabel
                                        control={<Checkbox disabled={!isEditable} />}
                                        label="Is Dedicated Developer?"
                                        name="is_dedicated_developer"
                                        id="is_dedicated_developer"
                                        checked={leaveData?.is_dedicated_developer}
                                        value={leaveData?.is_dedicated_developer}
                                        onChange={(e) => {
                                            setLeaveData((prev) => ({ ...prev, is_dedicated_developer: e.target.checked }))
                                        }}
                                    />
                                </Grid>
                                {leaveData?.is_dedicated_developer ?
                                    <Grid item>
                                        <Grid item width={300}>
                                            <Input
                                                id="monthly-max-leaves"
                                                label="Monthly Max Leaves (Days)"
                                                variant="standard"
                                                name="max_absent_allowed"
                                                sx={{ width: "100%" }}
                                                type="number"
                                                inputProps={{ min: minDays, max: maxDays }}
                                                min={minDays}
                                                max={maxDays}
                                                isDays={true}
                                                onChange={handleChangeMaxDays}
                                                onBlur={validateMaxDays}
                                                value={leaveData?.max_absent_allowed}
                                                error={!!errors?.max_absent_allowed}
                                                helperText={errors?.max_absent_allowed}
                                                disabled={!isEditable}
                                            />
                                            <Box>
                                                <HtmlTooltip
                                                    arrow
                                                    title={
                                                        <React.Fragment>
                                                            <Typography sx={{ fontSize: '13px !important' }} textAlign={"center"}>Individuals working on project in dedicated mode needs higher avalability to work</Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <Typography
                                                        display="inline-block"
                                                        color="primary"
                                                        fontSize={12}
                                                        sx={{ cursor: "pointer" }}
                                                    >
                                                        Help
                                                    </Typography>
                                                </HtmlTooltip>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    : null}
                            </Grid>
                        </Grid >
                        {isEditable ? <Stack direction="row" sx={{ mt: 4 }} spacing={2.5} >
                            <Button
                                isButtonLoading={isLoading?.formSubmitting}
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="medium"
                                sx={{
                                    textTransform: "none",
                                    boxShadow: "none",
                                    color: "white",
                                }}
                                disabled={!isEditable || isLoading?.formSubmitting || !!validateData(leaveData)?.length}
                            >
                                Update
                            </Button>
                            <Button
                                onClick={() => handleCancel()}
                                variant="text"
                                color="secondary"
                                size="medium"
                                sx={{ textTransform: "none", boxShadow: "none" }}
                            >
                                Cancel
                            </Button>
                        </Stack > : null}
                    </form >
                </Box >
                <BootstrapDialog
                    open={isReporModalOpen}
                    onClose={() => setIsReporModalOpen(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        alignItems: 'flex-start',
                        '.MuiDialog-paper': {
                            maxWidth: '100%',
                            width: '1200px',
                            ' > div': {
                                overflow: 'hidden',
                                display: 'flex',
                                '> div': {
                                    display: 'flex',
                                }
                            },
                        },
                        '.MuiDialog-container': { alignItems: 'flex-start' }
                    }}
                >
                    <DialogContent sx={{ width: '100%' }}>
                        <Box sx={{ width: '100%' }}>
                            <UserLeaveReport activeYear={activeYear} setIsReporModalOpen={setIsReporModalOpen} userData={leaveData} years={leaveReportyears} currentUser={currentUser} />
                        </Box>
                    </DialogContent>
                </BootstrapDialog>
            </React.Fragment >
            :
            <PageNotFound showSideBar={false} />
    )
}

export default UserLeave