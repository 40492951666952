import axiosInstance from ".";
import API from "./api";

export const checkOrganisationUniqueName = async (orgName, orgId = null) => {
  try {
    let body = {
      name: orgName?.trim(),
      orgId: orgId,
    };
    if (!orgId) {
      delete body.orgId;
    }
    if (orgName?.toString()?.trim()?.length) {
      const response = await axiosInstance.post(
        API.checkOrganisationUniqueName,
        body
      );
      return response.data;
    }
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};

export const changeOrganisationStatus = async (orgId, status) => {
  try {
    if (orgId) {
      const url = API.changeOrganisationStatus(orgId, status);
      const response = await axiosInstance.put(url);
      return response;
    }
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};

export const handleCheckIsEmailUnique = async (email, userId) => {
  try {
    if (email?.length) {
      const body = {
        email: email?.trim(), userId
      }
      const response = await axiosInstance.post(API.isEmailUnique, body);
      return response?.data?.isExist;
    }
  } catch (error) {
    console.error("Error in API call:", error);
    // throw error;
  }
};

export const checkActivityUniqueName = async (title, orgId, id = null) => {
  try {
    const body = {
      title: title?.trim(), orgId, id
    }
    if (title?.toString()?.trim()?.length) {
      const response = await axiosInstance.post(API.checkActivityUniqueName, body);
      return response.data;
    }
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};

export const checkProjectUniqueName = async (projectTitle, projectId = null) => {
  try {
    const body = {
      title: projectTitle?.trim(), projectId
    };
    if (projectTitle?.toString()?.trim()?.length) {
      const response = await axiosInstance.post(API.checkProjectUniqueName, body);
      return response.data;
    }
  } catch (error) {
    console.error("Error in API call:", error);
    throw error;
  }
};