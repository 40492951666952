import URLS from "../routes/urls";
import ICONS from "./icons";

const SIDEBAR_SETTINGS = [
  {
    name: "Dashboard",
    icon: ICONS.Dashboard,
    href: URLS.Dashboard,
  },
  {
    name: "Projects",
    icon: ICONS.Projects,
    href: URLS.Projects,
  },
  {
    name: "Request",
    icon: ICONS.Request,
    subMenu: [
      {
        name: "Leave",
        href: URLS.Leaves,
      },
      {
        name: "WFH / Timing Request",
        href: URLS.WorkFromHome,
      },
      // {
      //   name: "Inventory",
      //   href: URLS.Inventory,
      // },
    ],
  },
  {
    name: "Users",
    icon: ICONS.Users,
    href: URLS.Users,
  },
  {
    name: "Holidays",
    icon: ICONS.Holidays,
    href: URLS.Holidays,
  },
  {
    name: "Reports",
    icon: ICONS.ReportIcon,
    href: URLS.Reports,
  },
  {
    name: "Administrator",
    icon: ICONS.Administrator,
    subMenu: [
      {
        name: "Organisation",
        href: URLS.AdministratorOrganisation,
      },
      {
        name: "Leaves",
        href: URLS.AdministratorLeaves,
      },
      {
        name: "Holidays",
        href: URLS.AdministratorHolidays,
      },
      {
        name: "Roles",
        href: URLS.AdministratorRoles,
      },
      {
        name: "Project Roles",
        href: URLS.AdministratorProjectRoles,
      },
      {
        name: "Activity",
        href: URLS.AdministratorActivity,
      },
      {
        name: "Years",
        href: URLS.AdministratorYears,
      },
    ],
  },
];

export default SIDEBAR_SETTINGS;
