/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import URLS from '../../../../routes/urls';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '../../../../hook/useTitle';
import { sortArrayByKey, titleCase } from '../../../../utils';
import API from '../../../../axios/api';
import axiosInstance from '../../../../axios';
import useAuthentication from '../../../../hook/useAuthentication';
import { toast } from 'react-toastify';
import EmptyPage from '../../../../components/EmptyPage';
import CircularLoader from '../../../../components/CircularLoader';
import ICONS from '../../../../constants/icons';

const AdministratorActivity = () => {
  const navigate = useNavigate();
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();
  const [allActivity, setAllActivity] = useState([]);
  const [workTypeData, setWorkTypeData] = useState([]);
  const [loading, setLoading] = useState({
    pageLoading: true,
    workTypeLoading: true,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openMenuIndex, setOpenMenuIndex] = useState(null);
  const open = Boolean(anchorEl);

  const { setPageTitle } = usePageTitle();
  setPageTitle("Activity")

  useEffect(() => {
    if (currentUser?.organization_id) {
      getAllActivityData();
      getWorkTypesByOrganizationIdData();
    }
  }, [])

  const getAllActivityData = async () => {
    try {
      const response = await axiosInstance.get(
        API.getAllActivity(currentUser?.organization_id)
      );
      if (response.status === 200) {
        if (response?.data?.data?.length) {
          let sortedActivity = sortArrayByKey(response?.data?.data, 'asc', 'title')
          setAllActivity(sortedActivity);
        } else {
          setAllActivity(response?.data?.data);
        }
      } else {
        toast.error(response?.data?.message);
        setAllActivity([]);
      }
      setLoading((prev) => ({ ...prev, pageLoading: false }))
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, pageLoading: false }))
    }
  };

  const getWorkTypesByOrganizationIdData = async () => {
    try {
      setLoading((prev) => ({ ...prev, workTypeLoading: true }))
      const response = await axiosInstance.get(
        API.getWorkTypesByOrganisationId(currentUser?.organization_id)
      );
      if (response.status === 200) {
        setWorkTypeData(response?.data?.data)
      }
      setLoading((prev) => ({ ...prev, workTypeLoading: false }))
    } catch (error) {
      console.error(error);
      setLoading((prev) => ({ ...prev, workTypeLoading: false }))
    }
  };

  const handleClickActivity = (id) => {
    navigate(`${URLS.AdministratorUpdateActivity}/${id}`)
  }

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuIndex(index)
  };


  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenuIndex(null)
  };

  const getWorkTypeNameById = (idArray) => {
    return workTypeData.filter((workTypeDetail) => idArray.includes(workTypeDetail?.id))?.map((data) => data?.name)?.join(', ')
  }

  return (
    <>
      {loading?.pageLoading || loading?.workTypeLoading ? <CircularLoader /> :
        allActivity?.length ?
          <Box p={4} overflow="auto" width="100%">
            <Grid container mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <Grid item display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                <Typography color={"dark.800"} fontSize={16} fontWeight={400} lineHeight={"22px"} letterSpacing={"0.17px"}>
                  {allActivity?.length ? allActivity?.length : null}
                  {allActivity?.length ? allActivity?.length > 1 ? ' Activities' : ' Activity' : null}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ textTransform: "none", boxShadow: "none", color: 'white' }}
                  onClick={() => navigate(URLS.AdministratorCreateActivity)}
                >
                  Add Activity
                </Button>
              </Grid>
            </Grid>
            <Box>
              <Paper
                sx={{
                  border: "1px solid #E0E0E0",
                  borderRadius: "3px",
                  boxShadow: 'none',
                }}>
                <TableContainer>
                  <Table>
                    <TableHead
                      sx={{
                        th: {
                          color: "dark.800",
                          fontWeight: 500,
                          fontSize: 14,
                          lineHeight: "24px",
                          letterSpacing: "0.17px",
                          padding: "5px 16px",
                          textAlign: "left",
                          background: "rgba(245, 248, 250, 1)",
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell>Activity Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Work Type</TableCell>
                        <TableCell sx={{ width: '90px', maxWidth: '90px', minWidth: '90px', textAlign: 'center !important' }} >Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "tr:hover": {
                          background: "rgba(247, 247, 247, 1)",
                        },
                        td: {
                          color: "dark.800",
                          fontWeight: 400,
                          fontSize: 14,
                          lineHeight: "20px",
                          letterSpacing: "0.17px",
                          padding: "7px 16px",
                          textAlign: "left",
                          height: "45px",
                        },
                      }}>
                      {allActivity?.length ?
                        allActivity?.map((activityData, index) => (
                          <TableRow key={index}>
                            <TableCell>{activityData?.title}</TableCell>
                            <TableCell>{titleCase(activityData?.types)}</TableCell>
                            <TableCell>{getWorkTypeNameById(activityData?.org_work_type_ids)}</TableCell>
                            <TableCell sx={{ textAlign: 'center !important' }}>
                              <IconButton
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={(e) => handleClick(e, activityData?.id)}
                                sx={{
                                  height: "30px",
                                  width: "30px",
                                  padding: "0px",
                                  borderRadius: "4px",
                                }}
                              >
                                <i
                                  style={{
                                    height: "20px",
                                    width: "20px",
                                    display: "flex",
                                  }}
                                >
                                  {ICONS.DotsHorizontal}
                                </i>
                              </IconButton>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open && openMenuIndex === activityData?.id}
                                onClose={handleClose}
                                MenuListProps={{
                                  'aria-labelledby': 'basic-button',
                                }}
                              >
                                <MenuItem onClick={() => handleClickActivity(activityData?.id)}>Edit</MenuItem>
                              </Menu>
                            </TableCell>
                          </TableRow>
                        ))
                        : <TableRow
                          style={{
                            height: 50,
                          }}
                        >
                          <TableCell colSpan={4}>
                            <Typography fontSize={'15px'} fontWeight={500} sx={{ textAlign: 'center' }}>No Activity Available</Typography>
                          </TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Box> :
          <Box p={4} overflow="auto" width="100%">
            <EmptyPage
              title="No activity available"
              buttontext="Add Activity"
              buttonStyle={{ color: "white" }}
              buttonColor="secondary"
              onClick={() => navigate(URLS.AdministratorCreateActivity)}
            />
          </Box>
      }
    </>
  )
}

export default AdministratorActivity