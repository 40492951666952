import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import useAuthentication from '../../../hook/useAuthentication';
import { Navigate } from 'react-router-dom';

const InCompleteOnBoardingProcess = () => {
    const { isAuthenticated, defaultRoute, getCurrentUser } = useAuthentication();
    const DefaultPath = defaultRoute();
    const currentUser = getCurrentUser();

    if (isAuthenticated() && currentUser?.organization?.onboarding_status === "completed") {
        return <Navigate to={DefaultPath} />
    }

    return (
        <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
            <Stack spacing={4} sx={{ flexBasis: "100%" }}>
                <Typography
                    color="secondary"
                    fontSize={{ xs: 28, lg: 32 }}
                    lineHeight={{ xs: "38px", lg: "44px" }}
                    mb={2}
                >
                    The onboarding process has not been completed yet by the primary admin.
                </Typography>
                <Typography color="secondary.800" fontSize={20}>
                    Please wait until the onboarding process has completed.
                </Typography>
            </Stack>
        </Box>
    )
}

export default InCompleteOnBoardingProcess;