import React from "react";
import {
    Modal,
    Box,
    Grid,
    Typography,
    Stack,
    Divider,
} from "@mui/material";
import Button from "../Button";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
};

const InformationModal = ({ isOpen, title, subList = {}, handleClose, buttonName = "Ok" }) => {
    return (
        <>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid item py={2} px={3}>
                        <Typography variant="h6" color={"dark.800"} fontSize={20} fontWeight={500} lineHeight={"32px"} letterSpacing={"0.15px"}>{title}</Typography>
                    </Grid>
                    <Divider />
                    <Grid item p={3}>
                        <Stack spacing={2}>
                            <Typography
                                color="dark.800"
                                fontSize={16}
                                fontWeight={400}
                                lineHeight={"26px"}
                                letterSpacing={"0.15px"}
                            >
                                {subList}
                            </Typography>
                        </Stack>
                    </Grid>
                    <Divider />
                    <Grid item py={2} px={3}>
                        <Stack spacing={1} direction="row">
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                fontWeight="500"
                                sx={{ color: "white", textTransform: "none", boxShadow: 'none !important' }}
                                onClick={handleClose}
                            >
                                {buttonName}
                            </Button>
                        </Stack>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default InformationModal;