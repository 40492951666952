import { configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import userReducer from './slices/userSlice';
import notificationReducer from './slices/notificationSlice';
import leaveDataSlice from './slices/leaveDataSlice';
import nightModeSlice from './slices/nightModeSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        notification: notificationReducer,
        leaveData: leaveDataSlice,
        nightMode: nightModeSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
