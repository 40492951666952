import React, { useState } from "react";
import { usePageTitle } from "../../../../hook/useTitle";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Breadcrumb from "../../../../components/Breadcrumb";
import { useNavigate } from "react-router-dom";
import URLS from "../../../../routes/urls";
import Input from "../../../../components/Input";
import { getFormattedDate, getInputId, titleCase } from "../../../../utils";
import Button from "../../../../components/Button";
import SingleDatePicker from "../../../../components/SingleDatePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  PROJECT_STATUS,
} from "../../../../constants/default-values";
import API from "../../../../axios/api";
import useAuthentication from "../../../../hook/useAuthentication";
import axiosInstance from "../../../../axios";
import { useAlert } from "../../../../hook/useAlert";
import { checkProjectUniqueName } from "../../../../axios/service";

const CustomDatePicker = ({
  value,
  onChange,
  label,
  minDate,
  maxDate,
  onOpen,
  onClose,
  error = null,
  ...rest
}) => {
  return (
    <Grid
      flex={1}
      sx={{
        input: {
          width: "100%",
          height: 30,
          border: "none !important",
          boxShadow: "none !important",
          borderRadius: "0px",
          paddingBottom: "6px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.42) !important",
          margin: "0",
          position: "relative",
          background: "transparent",
          color: "dark.800",
          fontSize: 16,
          fontWeight: 400,
          lineHeight: "24px",
          letterSpacing: "0.15px",
          paddingLeft: "0px",
        },
        "input:hover": {
          borderBottomColor: "#333333 !important",
        },
        "input:focus": {
          borderBottomColor: "#047FE0 !important",
        },
        ".rmdp-container": {
          minWidth: "100px",
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          right: "0px",
          top: "20px",
          marginTop: "0px",
        }}
      >
        <CalendarTodayIcon
          sx={{
            fontSize: 20,
            marginBottom: "-5px",
            marginLeft: "2px",
            color: "rgba(0, 0, 0, 0.54)",
          }}
        />
      </div>
      <Typography
        fontSize={12}
        fontWeight={400}
        color="dark.600"
        sx={{
          width: "100%",
          letterSpacing: "0.15px",
          marginTop: "-3px",
          marginBottom: "2px",
        }}
      >
        {titleCase(label)}
      </Typography>
      <SingleDatePicker
        value={value}
        onChange={onChange}
        isMultiple={false}
        onOpen={onOpen}
        minDate={minDate}
        maxDate={maxDate}
        onClose={onClose}
        {...rest}
      />
      {!!error ? (
        <Typography sx={{ color: "red", fontSize: "12px" }}>{error}</Typography>
      ) : null}
    </Grid>
  );
};

const CreateProjects = () => {
  const { setPageTitle } = usePageTitle();
  const showAlert = useAlert();
  const { getCurrentUser } = useAuthentication();
  const currentUser = getCurrentUser();
  setPageTitle("Projects");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState({
    formSubmitting: false,
  });

  const [projectData, setProjectData] = useState({
    title: "",
    type: "fixed",
    status: "not-started",
    start_date: null,
    short_code: "",
    is_private: true,
    description: "",
  });

  const validateProjectData = (projectData) => {
    let error = { ...errors };
    if (!projectData?.title?.length) {
      error["title"] = "Required";
    }
    if (!projectData?.start_date && PROJECT_STATUS['Not Started'] !== projectData?.status) {
      error["start_date"] = "Required";
    }
    if (!projectData?.short_code?.length) {
      error["short_code"] = "Required";
    }
    if (!projectData?.description?.length) {
      error["description"] = "Required";
    }
    setErrors(error);
    return Object?.values(error)?.some((value) => value?.length);
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    if (name === "type") {
      setProjectData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (name === "short_code") {
      if (value.length < 2) {
        setErrors((prev) => ({
          ...prev,
          [name]: "Short code must be at least 2 characters.",
        }));
      } else if (value.length > 6) {
        setErrors((prev) => ({
          ...prev,
          [name]: "Short code must not exceed 6 characters.",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
      setProjectData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    else {
      setProjectData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: !value ? "Required" : "",
        start_date: name === 'status' ? PROJECT_STATUS['Not Started'] === value ? '' : prev?.start_date ?? '' : prev?.start_date ?? ''
      }));
    }
  };

  const handleBlur = async (e) => {
    let { name, value } = e.target;
    if (name === "short_code") {
      if (value.length < 2) {
        setErrors((prev) => ({
          ...prev,
          [name]: "Short code must be at least 2 characters.",
        }));
      } else if (value.length > 6) {
        setErrors((prev) => ({
          ...prev,
          [name]: "Short code must not exceed 6 characters.",
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          [name]: "",
        }));
      }
    } else {
      setErrors((prev) => ({
        ...prev,
        [name]: !value ? "Required" : "",
      }));
    }
  };

  const isValidDateFormat = (date) => {
    return new Date(date).toString() !== "Invalid date";
  };

  const handleChangeProjectstart_date = (date, name) => {
    setProjectData((prev) => ({
      ...prev,
      [name]: isValidDateFormat(date) ? new Date(date) : null,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: !isValidDateFormat(date) && PROJECT_STATUS['Not Started'] !== projectData?.status ? "Required" : "",
    }));
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (!validateProjectData(projectData)) {
        setIsLoading((prev) => ({ ...prev, formSubmitting: true }));
        let payload = {
          ...projectData,
          org_id: currentUser?.organization_id,
          start_date: getFormattedDate(projectData?.start_date, "YYYY-MM-DD"),
        };
        if (PROJECT_STATUS['Not Started'] === projectData?.status) delete payload["start_date"]
        const response = await axiosInstance.post(
          API.createProject(currentUser?.organization_id),
          payload
        );

        if (response?.status === 200) {
          showAlert(response.data?.message);
          navigate(
            `${URLS.UpdateProject}/${response?.data?.data?.id}?tabValue=members`
          );
        }
        setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      }
    } catch (error) {
      setIsLoading((prev) => ({ ...prev, formSubmitting: false }));
      console.error(error);
    }
  };

  const handleCheckUniqueProjectName = async (title) => {
    try {
      const response = await checkProjectUniqueName(title);
      if (response?.isExist) {
        setErrors((errors) => ({
          ...errors,
          title: "Project name is already exist, choose another name.",
        }));
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box width="100%" bgcolor="white" display="flex" flexDirection="column">
      <React.Fragment>
        <Breadcrumb
          isBack={true}
          onBackClick={() => navigate(-1)}
          pageTitle={"New Project"}
          title={"Projects"}
        />
        <Box
          p={{ xs: 3, lg: 4, xl: 4 }}
          py={{ xs: 3, lg: 4, xl: 4 }}
          sx={{ background: "#ffffff" }}
          overflow="auto"
          flex={1}
        >
          <Grid
            container
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            mb={2}
          >
            <Grid>
              <Typography
                variant="h6"
                fontSize={20}
                fontWeight={400}
                color="dark.800"
                sx={{ lineHeight: "30px", letterSpacing: "0.15px" }}
              >
                Project details
              </Typography>
            </Grid>
          </Grid>
          <form onSubmit={handleSubmit} className="step-form-1">
            <Box
              direction="row"
              display="flex"
              flexWrap="wrap"
              width={780}
              maxWidth="100%"
            >
              <Box
                sx={{
                  display: "grid",
                  gap: 4,
                  gridTemplateColumns: "repeat(2, 1fr)",
                }}
                mb={4}
                width={"100%"}
              >
                <Grid>
                  <Input
                    id="project-name"
                    label="Project Name"
                    variant="standard"
                    placeholder="Project Name"
                    type="text"
                    name={"title"}
                    fullWidth
                    required={false}
                    value={titleCase(projectData?.title)}
                    onBlur={(e) => { handleBlur(e); handleCheckUniqueProjectName(e.target.value) }}
                    onChange={handleChange}
                    error={!!errors?.title}
                    helperText={errors?.title}
                  // disabled={!isEditable}
                  />
                </Grid>

                <Grid>
                  <Input
                    id="short_code"
                    label="Short Code"
                    variant="standard"
                    placeholder="Short Code"
                    type="text"
                    name={"short_code"}
                    fullWidth
                    required={false}
                    value={titleCase(projectData?.short_code)}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!errors?.short_code}
                    helperText={errors?.short_code}
                  // disabled={!isEditable}
                  />
                </Grid>

                <Grid>
                  <Input
                    id={getInputId("status")}
                    name={"status"}
                    select
                    type="select"
                    label="Status"
                    defaultValue="In-Office"
                    variant="standard"
                    sx={{ width: "100%" }}
                    onChange={handleChange}
                    value={projectData?.status}
                    required={false}
                  >
                    {Object.entries(PROJECT_STATUS)?.map((option) => (
                      <MenuItem key={option[0]} value={option[1]}>
                        {option[0]}
                      </MenuItem>
                    ))}
                  </Input>
                </Grid>
                <Grid>
                  <Grid width={"100%"} position={"relative"}>
                    <CustomDatePicker
                      value={projectData?.start_date || null}
                      onChange={(date) =>
                        handleChangeProjectstart_date(date, "start_date")
                      }
                      label={PROJECT_STATUS['Not Started'] === projectData?.status ? "Start Date (optional)" : "Start Date"}
                      error={errors?.start_date}
                      placeholder={'Select date'}
                    />
                  </Grid>
                </Grid>

                <Grid>
                  <Typography
                    color="text.dark"
                    fontSize={14}
                    sx={{ lineHeight: "24px" }}
                  >
                    Type
                  </Typography>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="type"
                      defaultValue="fixed"
                      value={projectData?.type}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="fixed"
                        control={<Radio size="small" />}
                        label="Fixed"
                        sx={{ color: "dark.800" }}
                      />
                      <FormControlLabel
                        value="dedicated"
                        control={<Radio size="small" />}
                        label="Dedicated"
                        sx={{ color: "dark.800" }}
                      />
                    </RadioGroup>
                    <FormHelperText
                      error={false}
                      sx={{ p: 0, m: 0 }}
                    ></FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item>
                  <Typography
                    color="text.dark"
                    fontSize={14}
                    sx={{ lineHeight: "24px" }}
                  >
                    Access
                  </Typography>
                  <Box display={"flex"} alignItems={"center"}>
                    <FormControlLabel
                      id={getInputId("is_private")}
                      name="is_private"
                      value="end"
                      control={
                        <Checkbox
                          checked={projectData?.is_private}
                          size="small"
                        />
                      }
                      label="Private"
                      labelPlacement="end"
                      sx={{
                        ".MuiCheckbox-sizeMedium": {
                          minWidth: "42px",
                        },
                      }}
                      // disabled={!isEditable || userData?.is_general_user || userData?.is_primary || isDisabledCheckBox(userData, "is_admin") || false}
                      onChange={(event, checked) =>
                        setProjectData((prev) => ({
                          ...prev,
                          is_private: checked,
                        }))
                      }
                    />
                    <Typography
                      color="text.dark"
                      fontSize={12}
                      fontWeight={400}
                      lineHeight={"18px"}
                      letterSpacing={"0.15px"}
                    >
                      (Access only to assigned team members)
                    </Typography>
                  </Box>
                </Grid>
              </Box>
              <Grid item width={"100%"} maxWidth={"100%"}>
                <Input
                  id={getInputId("description", 0)}
                  name="description"
                  label="Description"
                  variant="standard"
                  placeholder="description"
                  type="text"
                  fullWidth
                  required={false}
                  onChange={(e) => handleChange(e, "description")}
                  onBlur={handleBlur}
                  multiline={true}
                  value={projectData?.description}
                  error={!!errors?.["description"]}
                  helperText={errors?.["description"]}
                  InputProps={{
                    id: getInputId("description", 0),
                    name: "description",
                  }}
                  InputLabelProps={{
                    htmlFor: getInputId("description", 0),
                  }}
                />
              </Grid>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={6}
            >
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexBasis="100%"
                gap={2}
              >
                <Button
                  variant="contained"
                  size="large"
                  type="submit"
                  sx={{
                    boxShadow: "0",
                    fontSize: "15px",
                    textTransform: "none",
                    color: "white",
                    "&:hover": { boxShadow: "0" },
                  }}
                  disabled={isLoading.formSubmitting || Boolean(
                    Object.values(errors)?.some((value) => !!value?.length)
                  )}
                  isButtonLoading={isLoading.formSubmitting}
                >
                  Create
                </Button>
                <Button
                  variant="text"
                  color="secondary"
                  sx={{ textTransform: "none" }}
                  size="medium"
                  fontWeight="500"
                  onClick={() => navigate(-1)}
                  disabled={isLoading.pageLoading}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </React.Fragment>
    </Box>
  );
};

export default CreateProjects;
