import React from 'react'
import { LocalizationProvider, TimeField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import moment from 'moment';
import dayjs from 'dayjs';
import { secondsToTime } from '../../utils';

const CustomTimePicker = ({ error, value, onChange, label, id, name, onBlur, ...rest }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimeField', 'TimeField', 'TimeField']}>
        <TimeField
          variant='standard'
          label={label}
          format="hh:mm"
          value={value ? dayjs(moment(secondsToTime(value), "hh:mm")) : null}
          onChange={onChange}
          onBlur={onBlur}
          id={id}
          name={name}
          error={!!error}
          helperText={error ? error : null}
          {...rest}
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}

export default CustomTimePicker