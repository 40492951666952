/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, IconButton, MenuItem, Skeleton, Stack, Typography } from '@mui/material'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import axiosInstance from '../../../../axios';
import API from '../../../../axios/api';
import SingleDatePicker from '../../../../components/SingleDatePicker';
import Input from '../../../../components/Input';
import { PROJECT_PHASE_STATUS } from '../../../../constants/default-values';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import { useAlert } from '../../../../hook/useAlert';
import { generateUniqueId, sortArrayByKey, titleCase } from '../../../../utils';
import { Delete } from '@mui/icons-material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import EmptyPage from '../../../../components/EmptyPage';

const Phase = () => {
  const [isLoading, setIsLoading] = React.useState({
    projectPhase: true,
    editProjectPhase: false,
    addProjectPhaseHours: false,
    deleteProjectPhaseHours: false,
    projectDetails: true,
    deleteProjectPhase: false,
    editProjectPhaseHours: false,
    saveProjectPhase: false,
  });
  const params = useParams();
  const showAlert = useAlert();

  const initialProjectPhaseHours = {
    id: null,
    hours: null,
  }

  const initialProjectPhase = {
    id: null,
    start_date: null,
    end_date: null,
    status: null,
    ProjectPhaseHours: []
  }

  const [projectData, setProjectData] = useState(null);
  const [projectPhases, setProjectPhases] = useState([]);
  const [optionalProjectPhases, setOptionalProjectPhases] = useState([]);
  const [isEditPhase, setIsEditPhase] = useState(null);
  const [isEditPhaseHours, setIsEditPhaseHours] = useState(null);

  const getProjectPhaseData = async () => {
    getProjectData();
    try {
      let response = await axiosInstance.get(
        API.getProjectPhases(params?.projectId)
      );
      if (response?.status === 200) {
        let updatedProjectPhases = response?.data?.data?.map((phase) => {
          let _ProjectPhaseHours = phase?.ProjectPhaseHours?.length > 0 ? phase?.ProjectPhaseHours?.map((hour) => ({ ...hour, uniqueId: generateUniqueId() })) : [];
          if (_ProjectPhaseHours?.length === 0) {
            _ProjectPhaseHours = [{ ...initialProjectPhaseHours, uniqueId: generateUniqueId() }]
          }
          return {
            ...phase,
            ProjectPhaseHours: sortArrayByKey(_ProjectPhaseHours, "asc", "id"),
            uniqueId: generateUniqueId()
          }
        })
        setProjectPhases(sortArrayByKey(updatedProjectPhases, "desc", 'id'));
        setOptionalProjectPhases(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading((prev) => ({ ...prev, projectPhase: false }));
    }
  }

  const getProjectData = async () => {
    try {
      setIsLoading((prev) => ({ ...prev, projectDetails: false }));
      const response = await axiosInstance.get(
        API.getProjectDetailsById(params?.projectId)
      );
      if (response?.status === 200) {
        setProjectData(response?.data?.data);
      }

    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading((prev) => ({ ...prev, projectDetails: false }));
    }
  };

  const handleDateChange = (field, value, id) => {
    let data = projectPhases?.map((phase) => {
      if (phase?.id === id) {
        return {
          ...phase,
          [field]: moment(new Date(value)).format("YYYY-MM-DD"),
        };
      }
      return phase;
    });
    setProjectPhases(data);
  };


  const handleStatusChange = (e, id) => {
    let data = projectPhases?.map((phase) => {
      if (phase?.id === id) {
        return {
          ...phase,
          status: e.target.value
        }
      } else {
        return phase
      }
    });
    setProjectPhases(data);
  };

  const handlePhaseHoursChange = (e, projectId, uniqueId) => {
    let data = projectPhases?.map((phase) => {
      if (phase?.id === projectId) {
        return {
          ...phase,
          ProjectPhaseHours: phase?.ProjectPhaseHours?.map((hour) => {
            if (hour?.uniqueId === uniqueId) {
              return {
                ...hour,
                hours: e.target.value
              }
            } else {
              return hour
            }
          })
        }
      } else {
        return phase
      }
    });
    setProjectPhases(data);
  }

  const handleEditPhase = (uniqueId) => {
    setIsEditPhase(uniqueId);
  }

  const handleEditPhaseHours = (uniqueId) => {
    setIsEditPhaseHours(uniqueId);
  }

  const handleCancelPhaseHours = (phaseId) => {
    let data = projectPhases?.map((phase) => {
      if (phase?.id === phaseId) {
        return {
          ...phase,
          ProjectPhaseHours: optionalProjectPhases?.find((phase) => phase?.id === phaseId)?.ProjectPhaseHours?.map((hour) => ({ ...hour, uniqueId: generateUniqueId() })),
          uniqueId: generateUniqueId()
        }
      } else {
        return phase;
      }
    });
    setProjectPhases(data);
    setIsEditPhaseHours(null);
    setIsEditPhase(null);
  }

  const handleDeletePhase = async (id) => {
    try {
      setIsLoading((prev) => ({ ...prev, deleteProjectPhase: true }));
      let response = await axiosInstance.delete(API.deleteProjectPhase(id));
      if (response?.status === 200) {
        showAlert(response?.data?.message);
      } else {
        showAlert(response?.data?.message, "error");
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      getProjectPhaseData();
      setIsLoading((prev) => ({ ...prev, deleteProjectPhase: false }));
    }
  }

  const handleDeletePhaseHours = async (phaseHoursId, phaseId) => {
    try {
      setIsLoading((prev) => ({ ...prev, deleteProjectPhaseHours: true }));
      let phase = projectPhases?.find((phase) => phase?.id === phaseId);
      let hours = phase?.ProjectPhaseHours?.find((hour) => hour?.id === phaseHoursId);
      let payload = {
        "total_hours": Number.parseFloat(projectData?.total_hours),
        "hours": hours?.hours,
        "project_id": Number.parseInt(params?.projectId),
      };
      let response = await axiosInstance.put(API.deleteProjectPhaseHours(phaseHoursId), payload);
      if (response?.status === 200) {
        showAlert(response?.data?.message);
      } else {
        showAlert(response?.data?.message, "error");
      }
    } catch (error) {
      console.error(error);
    } finally {
      getProjectPhaseData();
      setIsLoading((prev) => ({ ...prev, deleteProjectPhaseHours: false }));
    }
  }

  const handleSavePhaseHours = async (phaseHoursId, phaseId) => {
    try {
      setIsLoading((prev) => ({ ...prev, editProjectPhaseHours: true }));
      let payload = {
        "project_id": Number.parseInt(params?.projectId),
        "total_hours": Number.parseFloat(projectData?.total_hours),
        "old_project_phase_hours": Number.parseFloat(optionalProjectPhases?.find((phase) => phase?.id === phaseId)?.ProjectPhaseHours?.find((hour) => hour?.id === phaseHoursId)?.hours),
        "hours": Number.parseFloat(projectPhases?.find((phase) => phase?.id === phaseId)?.ProjectPhaseHours?.find((hour) => hour?.id === phaseHoursId)?.hours)
      };
      let response = await axiosInstance.put(API.updateProjectPhaseHours(phaseHoursId), payload);
      if (response?.status === 200) {
        showAlert(response?.data?.message);
      } else {
        showAlert(response?.data?.message, "error");
      }
    } catch (error) {
      console.error(error);
    } finally {
      getProjectPhaseData();
      setIsEditPhaseHours(null);
      setIsEditPhase(null);
      setIsLoading((prev) => ({ ...prev, editProjectPhaseHours: false }));
    }
  }

  const handleSavePhase = async (uniqueId, type = "update") => {
    try {
      if (type === "update") {
        setIsLoading((prev) => ({ ...prev, editProjectPhase: true }));
        let phase = projectPhases?.find((phase) => phase?.id === uniqueId);
        let payload = {
          "start_date": phase?.start_date,
          "end_date": phase?.end_date,
          "status": phase?.status
        };
        let response = await axiosInstance.put(API.updateProjectPhase(phase?.id), payload);
        if (response?.status === 200) {
          showAlert(response?.data?.message);
        } else {
          showAlert(response?.data?.message, "error");
        }
      } else {
        setIsLoading((prev) => ({ ...prev, saveProjectPhase: true }));
        let phase = projectPhases?.find((phase) => phase?.uniqueId === uniqueId);
        let payload = {
          "project_id": Number.parseInt(params?.projectId),
          "start_date": phase?.start_date,
          "end_date": phase?.end_date,
          "status": phase?.status,
          "project_status": projectData?.status,
        };
        let response = await axiosInstance.post(API.createProjectPhase, payload);
        if (response?.status === 200) {
          showAlert(response?.data?.message);
        } else {
          showAlert(response?.data?.message, "error");
        }
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsEditPhase(null);
      setIsEditPhaseHours(null);
      getProjectPhaseData();
      setIsLoading((prev) => ({ ...prev, editProjectPhase: false }));
      setIsLoading((prev) => ({ ...prev, saveProjectPhase: false }));

    }
  }

  const handleAddPhaseHours = async (phaseId, uniqueId) => {
    setIsLoading((prev) => ({ ...prev, addProjectPhaseHours: true }));
    let phase = projectPhases?.find((phase) => phase?.id === phaseId);
    let hour = phase?.ProjectPhaseHours?.find((hour) => hour?.uniqueId === uniqueId);
    let payload = {
      "phase_id": phase?.id,
      "hours": Number.parseFloat(hour?.hours) || 0,
      "project_id": Number.parseInt(params?.projectId),
      "old_total_hours": Number.parseFloat(projectData?.total_hours) || 0,
    };
    try {
      let response = await axiosInstance.post(API.addProjectPhaseHours, payload);
      if (response?.status === 200) {
        showAlert(response?.data?.message);
      } else {
        showAlert(response?.data?.message, "error");
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading((prev) => ({ ...prev, addProjectPhaseHours: false }));
      getProjectPhaseData();
      setIsEditPhaseHours(null);
      setIsEditPhase(null);
    }
  }

  const handleCancelPhase = (uniqueId) => {
    let id = projectPhases?.find((phase) => phase?.uniqueId === uniqueId)?.id;
    if (!!id) {
      let actualData = optionalProjectPhases?.find((phase) => phase?.id === id);
      let updatedData = projectPhases?.map((phase) => {
        if (phase?.id === id) {
          return { ...actualData, uniqueId: generateUniqueId(), ProjectPhaseHours: phase?.ProjectPhaseHours?.length > 0 ? phase?.ProjectPhaseHours?.map((hour) => ({ ...hour, uniqueId: generateUniqueId() })) : [{ ...initialProjectPhaseHours, uniqueId: generateUniqueId() }] };
        }
        return phase;
      });
      setProjectPhases(updatedData);
    } else {
      let updatedData = projectPhases?.filter((phase) => phase?.uniqueId !== uniqueId);
      setProjectPhases(updatedData);
    }
    setIsEditPhaseHours(null);
    setIsEditPhase(null);
  }

  const handleAddPhase = () => {
    let _initialProjectPhase = {
      ...initialProjectPhase,
      uniqueId: generateUniqueId(),
      ProjectPhaseHours: [],
      number: projectPhases?.length + 1,
    };
    let data = [_initialProjectPhase, ...projectPhases];
    setProjectPhases(data);
    setIsEditPhase(_initialProjectPhase?.uniqueId);
  }

  const handleAddEmptyPhaseHours = (phaseId) => {
    let emptyPhaseHours = { ...initialProjectPhaseHours, uniqueId: generateUniqueId() };
    let data = projectPhases?.map((phase) => {
      if (phase?.id === phaseId) {
        return { ...phase, ProjectPhaseHours: [...phase?.ProjectPhaseHours, emptyPhaseHours] };
      }
      return phase;
    })
    setProjectPhases(data);
    setIsEditPhaseHours(emptyPhaseHours?.uniqueId);
  }

  const getProjectPhaseHours = (phaseId) => {
    let phase = optionalProjectPhases?.find((phase) => phase?.id === phaseId);
    if (!phase?.ProjectPhaseHours?.length) {
      return 0;
    }
    else {
      return phase?.ProjectPhaseHours?.filter((hour) => !!hour?.id)?.map((hour) => Number.parseFloat(hour?.hours ?? 0)).reduce((a, b) => a + b, 0);
    }
  }

  useEffect(() => {
    getProjectPhaseData();
  }, []);

  const isDisableAddPhaseButton = projectData?.status === "completed";

  return (
    <Box>
      {(isLoading.projectPhase || isLoading.projectDetails) ?
        <Box mb={1}>
          <Skeleton animation="wave" variant="rounded" width={"100%"} height={200} />
        </Box> :
        <React.Fragment>
          <Box>
            <Stack display={"flex"} direction={"row"} alignItems={"center"} justifyContent="space-between" mb={2}>
              <Typography color={"dark.800"} fontSize={20} fontWeight={400} lineHeight={"30px"} letterSpacing={"0.15px"}>Project Phases</Typography>
              <Button disabled={!!isEditPhase || !!isEditPhaseHours || isDisableAddPhaseButton} onClick={handleAddPhase} sx={{ textTransform: 'none', boxShadow: 'none !important' }}>Add Phases</Button>
            </Stack>
            <Box>
              {projectPhases?.length > 0 ?
                projectPhases?.map((phase, index, phaseArray) => {
                  return (
                    <Box key={index}>
                      <Stack display={"flex"} direction="row" justifyContent="space-between" mb={2}>
                        <Box width={50} maxWidth={50} pt={"20px"}>
                          <Typography width={30} textAlign={"right"} color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"}>{phase?.number || index + 1}</Typography>
                        </Box>
                        <Box bgcolor={"#F8F8F8"} width={'100%'} >
                          <Box padding={"30px"} width={'100%'} display={"flex"} justifyContent={"space-between"}>
                            <Box
                              maxWidth={890}
                              sx={{
                                display: "grid",
                                gap: "30px",
                                gridTemplateColumns: isEditPhase && isEditPhase === phase?.uniqueId ? "repeat(3, 1fr)" : "repeat(4, 1fr)",
                              }}
                            >
                              <Box maxWidth={{ xs: 150, lg: 180, xl: 200 }}>
                                <Typography color={"dark.600"} fontSize={12} fontWeight={400} lineHeight={"12px"} letterSpacing={"0.15px"}>{`Phase starts ${phase?.status === PROJECT_PHASE_STATUS['Not started'] ? '(optional)' : ''}`}</Typography>
                                <Box className="calendar-date-picker"
                                  sx={{
                                    'input': {
                                      width: '100%',
                                      height: 35,
                                      border: 'none !important',
                                      boxShadow: 'none !important',
                                      borderRadius: '0px',
                                      paddingBottom: '0px',
                                      paddingLeft: '28px',
                                      borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                                      margin: '0',
                                      position: 'relative',
                                      background: 'transparent',
                                      color: 'dark.800',
                                      fontSize: 16,
                                      fontWeight: 400,
                                      lineHeight: '24px',
                                      letterSpacing: '0.15px'
                                    },
                                    'input:disabled': {
                                      color: 'rgb(170, 170, 170)',
                                      borderColor: 'rgba(118, 118, 118, 0.3) !important',
                                    },
                                    'input:disabled:hover': {
                                      color: 'rgb(170, 170, 170)',
                                      borderColor: 'rgba(118, 118, 118, 0.3) !important',
                                    },
                                    'input:hover': {
                                      borderBottomColor: '#333333 !important',
                                    },
                                    'input:focus': {
                                      borderBottomColor: '#047FE0 !important',
                                    },
                                    '.rmdp-container': {
                                      minWidth: '100px',
                                    },
                                  }}
                                >
                                  <div style={{ height: 1 }}>
                                    <CalendarTodayIcon
                                      sx={{
                                        fontSize: 20,
                                        marginBottom: '-12px',
                                        marginLeft: '2px',
                                        color: isEditPhase === null ? "#0000008A" : "rgba(0, 0, 0, 0.54)",
                                      }}
                                    />
                                  </div>
                                  <SingleDatePicker
                                    value={moment(phase?.start_date, "YYYY-MM-DD").toDate() || ""}
                                    onChange={(value) => handleDateChange("start_date", value, phase?.id)}
                                    isMultiple={false}
                                    minDate={moment(phaseArray[index + 1]?.end_date ? phaseArray[index + 1]?.end_date : projectData?.start_date, "YYYY-MM-DD").set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toDate()}
                                    maxDate={moment(phase?.end_date, "YYYY-MM-DD").set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).subtract(1, "seconds").toDate()}
                                    disabled={isEditPhase === null ? true : isEditPhase === phase?.uniqueId ? false : true}
                                  />
                                </Box>
                              </Box>
                              <Box maxWidth={{ xs: 150, lg: 180, xl: 200 }}>
                                <Typography color={"dark.600"} fontSize={12} fontWeight={400} lineHeight={"12px"} letterSpacing={"0.15px"}>Phase ends (optional) </Typography>
                                <Box className="calendar-date-picker"
                                  sx={{
                                    'input': {
                                      width: '100%',
                                      height: 35,
                                      border: 'none !important',
                                      boxShadow: 'none !important',
                                      borderRadius: '0px',
                                      paddingBottom: '0px',
                                      paddingLeft: '28px',
                                      borderBottom: '1px solid rgba(0, 0, 0, 0.42) !important',
                                      margin: '0',
                                      position: 'relative',
                                      background: 'transparent',
                                      color: 'dark.800',
                                      fontSize: 16,
                                      fontWeight: 400,
                                      lineHeight: '24px',
                                      letterSpacing: '0.15px'
                                    },
                                    'input:disabled': {
                                      color: 'rgb(170, 170, 170)',
                                      borderColor: 'rgba(118, 118, 118, 0.3) !important',
                                    },
                                    'input:disabled:hover': {
                                      color: 'rgb(170, 170, 170)',
                                      borderColor: 'rgba(118, 118, 118, 0.3) !important',
                                    },
                                    'input:hover': {
                                      borderBottomColor: '#333333 !important',
                                    },
                                    'input:focus': {
                                      borderBottomColor: '#047FE0 !important',
                                    },
                                    '.rmdp-container': {
                                      minWidth: '100px',
                                    },
                                  }}
                                >
                                  <div style={{ height: 1 }}>
                                    <CalendarTodayIcon
                                      sx={{
                                        fontSize: 20,
                                        marginBottom: '-12px',
                                        marginLeft: '2px',
                                        color: isEditPhase === null ? "#0000008A" : "rgba(0, 0, 0, 0.54)",
                                      }}
                                    />
                                  </div>
                                  <SingleDatePicker
                                    value={moment(phase?.end_date, "YYYY-MM-DD").toDate() || ""}
                                    onChange={(value) => handleDateChange("end_date", value, phase?.id)}
                                    isMultiple={false}
                                    minDate={moment(phaseArray[index + 1]?.end_date ? phaseArray[index + 1]?.end_date : phaseArray[index]?.start_date, "YYYY-MM-DD").set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).toDate()}
                                    maxDate={moment(phaseArray[index - 1]?.start_date, "YYYY-MM-DD").set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }).subtract(1, "seconds").toDate()}
                                    disabled={isEditPhase === null ? true : isEditPhase === phase?.uniqueId ? false : true}
                                  />
                                </Box>
                              </Box>
                              <Box maxWidth={{ xs: 150, lg: 180, xl: 200 }}>
                                <Input
                                  id="status"
                                  name={"status"}
                                  select
                                  type="select"
                                  label="Status"
                                  defaultValue="pending"
                                  variant="standard"
                                  sx={{ width: "100%" }}
                                  onChange={(e) => handleStatusChange(e, phase?.id)}
                                  value={phase?.status}
                                  required={false}
                                  disabled={isEditPhase === null ? true : isEditPhase === phase?.uniqueId ? false : true}
                                >
                                  {Object.entries(PROJECT_PHASE_STATUS)?.map((option) => (
                                    <MenuItem key={option[0]} value={option[1]} disabled={['on-hold', 'stopped', 'completed'].includes(projectData?.status) && (option[1] === "in-progress")}>
                                      {titleCase(option[0])}
                                    </MenuItem>
                                  ))}
                                </Input>
                              </Box>
                              {!!phase?.id && !isEditPhase ?
                                <Box>
                                  <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"21px"} letterSpacing={"0.15px"} mb={"3px"}>{!!phase?.updated_by_user ? <b>Updated By</b> : <b>By</b>} {!!phase?.updated_by_user ? phase?.updated_by_user?.name : phase?.created_by_user?.name}</Typography>
                                  <Typography color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.15px"} sx={{ opacity: 0.6 }}>{!!phase?.updated_by_user ? moment(phase?.updatedAt).format("DD/MM/YYYY") : moment(phase?.createdAt).format("DD/MM/YYYY")}</Typography>
                                </Box>
                                : isEditPhase !== phase?.uniqueId ? (
                                  <Box>
                                    <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"21px"} letterSpacing={"0.15px"} mb={"3px"}>{!!phase?.updated_by_user ? <b>Updated By</b> : <b>By</b>} {!!phase?.updated_by_user ? phase?.updated_by_user?.name : phase?.created_by_user?.name}</Typography>
                                    <Typography color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.15px"} sx={{ opacity: 0.6 }}>{!!phase?.updated_by_user ? moment(phase?.updatedAt).format("DD/MM/YYYY") : moment(phase?.createdAt).format("DD/MM/YYYY")}</Typography>
                                  </Box>
                                ) : null}
                            </Box>
                            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                              {isEditPhase === phase?.uniqueId ? (
                                <Box display={"flex"} gap={'10px'}>
                                  <Button
                                    disabled={(!phase?.start_date && phase?.status !== PROJECT_PHASE_STATUS['Not started']) || !phase?.status}
                                    onClick={() => handleSavePhase(phase.id || phase.uniqueId, phase.id ? "update" : "save")}
                                    sx={{ textTransform: 'none', boxShadow: 'none !important' }}
                                    isButtonLoading={isLoading?.editProjectPhase || isLoading?.saveProjectPhase}
                                  >
                                    {phase.id ? "Update Phase" : "Save"}
                                  </Button>
                                  <Button disabled={isLoading?.editProjectPhase || isLoading?.saveProjectPhase} variant="text" color='secondary' onClick={() => handleCancelPhase(phase.uniqueId)} sx={{ textTransform: 'none', boxShadow: 'none !important' }}>Cancel</Button>
                                </Box>
                              ) : (
                                <Stack direction="row" display={"flex"} alignItems={"center"} justifyContent={"flex-end"} spacing={1} width={88}>
                                  <IconButton size="medium"
                                    sx={{
                                      width: "40px",
                                      height: "40px",
                                    }}
                                    disabled={isEditPhase !== null || isEditPhaseHours !== null || isLoading?.deleteProjectPhase}
                                    onClick={() => handleEditPhase(phase.uniqueId)}>
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                  {index === 0 && phaseArray?.length > 1 ?
                                    <IconButton size="medium"
                                      sx={{
                                        width: "40px",
                                        height: "40px",
                                      }}
                                      disabled={isEditPhase !== null || isEditPhaseHours !== null || isLoading?.deleteProjectPhase}
                                      onClick={() => handleDeletePhase(phase.id)}
                                    >
                                      <Delete fontSize="small" />
                                    </IconButton>
                                    : null}
                                </Stack>
                              )}
                            </Box>
                          </Box>
                          {phase.id ? <>
                            <Divider />
                            <Box padding={"30px"} width={'100%'}>
                              <Box>
                                {phase?.ProjectPhaseHours?.map((phaseHours, index, phaseHoursArray) => {
                                  return (
                                    <Stack key={index} display={"flex"} direction="row" gap={"30px"} mb={4}>
                                      <Stack direction={"row"} gap={"30px"}>
                                        <Box maxWidth={{ xs: 150, lg: 180, xl: 200 }} display={"flex"}>
                                          <Box width={{ xs: 50, xl: 60 }} maxWidth={{ xs: 50, xl: 60 }} minWidth={{ xs: 50, xl: 60 }} pt={1.5}>
                                            <Typography width={30} textAlign={"right"} color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"24px"} letterSpacing={"0.15px"}>{index + 1}</Typography>
                                          </Box>
                                          <Box>
                                            <Input
                                              id="phase-hours"
                                              name="hours"
                                              label="Hours"
                                              variant="standard"
                                              type="number"
                                              sx={{ width: "100%" }}
                                              onChange={(e) => handlePhaseHoursChange(e, phase?.id, phaseHours?.uniqueId)}
                                              onBlur={(e) => handlePhaseHoursChange(e, phase?.id, phaseHours?.uniqueId)}
                                              value={(phase?.status === PROJECT_PHASE_STATUS['Done'] && !phaseHours?.id) ? null : phaseHours?.hours || null}
                                              disabled={(!phaseHours?.id ? false : isEditPhaseHours === null ? true : isEditPhaseHours === phaseHours?.uniqueId ? false : true) || phase?.status === "done"}
                                            />
                                          </Box>
                                        </Box>
                                        {phaseHours?.id ?
                                          <Box maxWidth={{ xs: 150, lg: 180, xl: 200 }}>
                                            <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"21px"} letterSpacing={"0.15px"} mb={"3px"}>{phaseHours?.updated_by_user_hours ? <b>Updated By</b> : <b>By</b>} {!!phaseHours?.updated_by_user_hours ? `${phaseHours?.updated_by_user_hours?.name}` : `${phaseHours?.created_by_user_hours?.name}`}</Typography>
                                            <Typography color={"dark.800"} fontSize={12} fontWeight={500} lineHeight={"18px"} letterSpacing={"0.15px"} sx={{ opacity: 0.6 }}>{phaseHours?.updated_by_user_hours ? moment(phaseHours?.updatedAt).format("DD/MM/YYYY") : moment(phaseHours?.createdAt).format("DD/MM/YYYY")}</Typography>
                                          </Box>
                                          : null}
                                      </Stack>
                                      <Box>
                                        {phaseHours?.id ?
                                          isEditPhaseHours === null && index === phaseHoursArray?.length - 1 ?
                                            <Stack direction="row" spacing={1}>
                                              <IconButton
                                                size="small"
                                                sx={{
                                                  width: "40px",
                                                  height: "40px",
                                                }}
                                                disabled={isEditPhase !== null || isEditPhaseHours !== null}
                                                onClick={() => handleEditPhaseHours(phaseHours?.uniqueId)}
                                              >
                                                <EditIcon fontSize="small" />
                                              </IconButton>
                                              <IconButton
                                                size="small"
                                                sx={{
                                                  width: "40px",
                                                  height: "40px",
                                                }}
                                                disabled={isEditPhase !== null || isEditPhaseHours !== null}
                                                onClick={() => handleDeletePhaseHours(phaseHours?.id, phase?.id)}
                                              >
                                                <Delete fontSize="small" />
                                              </IconButton>
                                            </Stack> :
                                            isEditPhaseHours === phaseHours?.uniqueId ?
                                              <Stack direction="row" spacing={1}>
                                                <Button
                                                  color='secondary'
                                                  onClick={() => handleSavePhaseHours(phaseHours?.id, phase?.id)}
                                                  sx={{ textTransform: 'none', boxShadow: 'none !important' }}
                                                  isButtonLoading={isLoading?.editProjectPhaseHours}
                                                >
                                                  Update Hours
                                                </Button>
                                                <Button
                                                  variant='text'
                                                  color='secondary'
                                                  onClick={() => handleCancelPhaseHours(phase?.id)}
                                                  sx={{ textTransform: 'none', boxShadow: 'none !important' }}
                                                  disabled={isLoading?.editProjectPhaseHours}
                                                >
                                                  Cancel
                                                </Button>
                                              </Stack> :
                                              index === phaseHoursArray?.length - 1 ?
                                                <Stack direction="row" spacing={1}>
                                                  <IconButton
                                                    size="small"
                                                    sx={{
                                                      width: "40px",
                                                      height: "40px",
                                                    }}
                                                    disabled={isEditPhase !== null || isEditPhaseHours !== null}
                                                    onClick={() => handleEditPhaseHours(phaseHours?.uniqueId)}
                                                  >
                                                    <EditIcon fontSize="small" />
                                                  </IconButton>
                                                  <IconButton
                                                    size="small"
                                                    sx={{
                                                      width: "40px",
                                                      height: "40px",
                                                    }}
                                                    disabled={isEditPhase !== null || isEditPhaseHours !== null}
                                                    onClick={() => handleDeletePhaseHours(phaseHours?.id, phase?.id)}
                                                  >
                                                    <Delete fontSize="small" />
                                                  </IconButton>
                                                </Stack> : null :
                                          <Stack direction={"row"} gap={"10px"}>
                                            <Button size="medium"
                                              isButtonLoading={isLoading?.addProjectPhaseHours}
                                              disabled={!phaseHours?.hours || phase?.status === PROJECT_PHASE_STATUS['Done']}
                                              onClick={() => handleAddPhaseHours(phase?.id, phaseHours?.uniqueId)}
                                              sx={{ textTransform: 'none', boxShadow: 'none !important' }}
                                            >
                                              Save
                                            </Button>
                                            {phaseHoursArray?.length > 1 &&
                                              <Button
                                                disabled={isLoading?.addProjectPhaseHours}
                                                size="medium"
                                                color='secondary'
                                                onClick={() => handleCancelPhaseHours(phase?.id)}
                                                variant='text'
                                                sx={{ textTransform: 'none', boxShadow: 'none !important' }}
                                              >
                                                Cancel
                                              </Button>}
                                          </Stack>
                                        }
                                      </Box>
                                    </Stack>
                                  )
                                })}
                              </Box>
                              {!!phase?.id ?
                                <Stack direction="row" justifyContent="space-between" alignItems={"center"} pl={"60px"}>
                                  <Button
                                    color='secondary'
                                    disabled={(phase?.ProjectPhaseHours[phase?.ProjectPhaseHours?.length - 1]?.id ? false : true) ||
                                      (isEditPhase !== null) ||
                                      (isEditPhaseHours !== null) || phase?.status === "done"}
                                    onClick={() => handleAddEmptyPhaseHours(phase?.id)}
                                    sx={{ textTransform: 'none', boxShadow: 'none !important' }}
                                  >
                                    Add Hours
                                  </Button>
                                  <Box display={"flex"} gap={0.5} alignItems={"center"}>
                                    <Typography color={"dark.main"} fontSize={14} fontWeight={500} lineHeight={"21px"} letterSpacing={"0.15px"}>{phase?.title} -</Typography>
                                    <Typography color={"dark.main"} fontSize={20} fontWeight={700} lineHeight={"30px"} letterSpacing={"0.15px"}>{getProjectPhaseHours(phase?.id)}</Typography>
                                    <Typography color={"dark.800"} fontSize={14} fontWeight={500} lineHeight={"21px"} letterSpacing={"0.15px"} sx={{ opacity: 0.7 }}>hrs</Typography>
                                  </Box>
                                </Stack> : null}
                            </Box>
                          </>
                            : null
                          }
                        </Box>
                      </Stack>
                    </Box>
                  )
                }) : <EmptyPage title="No Phase created." isButtonShow={false} />}
            </Box>
          </Box>
        </React.Fragment>
      }
    </Box>
  )
}

export default Phase;