/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Skeleton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FIXED_PROJECT_TABS } from "../../../../constants/default-values";
import Breadcrumb from "../../../../components/Breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { titleCase } from "../../../../utils";
import ProjectDetails from "./ProjectDetails";
import TeamMembers from "./TeamMembers";
import useQuery from "../../../../hook/useQuery";
import axiosInstance from "../../../../axios";
import API from "../../../../axios/api";
import Phase from "./Phase";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const UpdateProject = () => {
  const { getQueryParams, setQueryParams } = useQuery();
  const params = useParams();
  const navigate = useNavigate();
  let tabValue = getQueryParams("tabValue") || Object.keys(FIXED_PROJECT_TABS)[1];
  const [isLoading, setIsLoading] = useState({
    pageLoading: true,
  });
  const [projectData, setProjectData] = useState(null);
  const handleTabChange = (event, newValue) => {
    setQueryParams("tabValue", newValue);
  };

  const getProjectData = async () => {
    try {
      const response = await axiosInstance.get(
        API.getProjectDetailsById(params?.projectId)
      );
      if (response?.status === 200) {
        setProjectData(response?.data?.data);
      }
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    } catch (error) {
      setIsLoading((prev) => ({ ...prev, pageLoading: false }));
    }
  };

  useEffect(() => {
    getProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (

    <Box width="100%" bgcolor="white" display="flex" flexDirection="column" flex={1}>
      <Breadcrumb
        isBack={true}
        onBackClick={() => navigate(-1)}
        pageTitle={titleCase(projectData?.title)}
        title={"Projects"}
        isLoading={isLoading.pageLoading}
      />
      <Box
        p={{ xs: 3, lg: 4, xl: 4 }}
        py={{ xs: 3, lg: 4, xl: 4 }}
        sx={{ background: "#ffffff", flex: 1 }}
        overflow="auto"
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            {Object.entries(FIXED_PROJECT_TABS).map((project, index) => (
              <Tab label={project[1]} value={project[0]} key={index} />
            ))}
          </Tabs>
        </Box>
        <Box
          bgcolor="secondary"
          sx={{
            ".tabs-detail > .MuiBox-root": {
              padding: "0px",
            },
            ".project-detail-box": {
              padding: '0px',
            }
          }}
        >
          <CustomTabPanel
            value={tabValue}
            index={Object.keys(FIXED_PROJECT_TABS)[0]}
            className="tabs-detail"
            key={0}
          >
            {isLoading.pageLoading ?
              <Box>
                <Skeleton animation="wave" variant="rounded" width={"100%"} height={200} />
              </Box> :
              <ProjectDetails projectDetail={projectData} setProjectDetails={setProjectData} />
            }
          </CustomTabPanel>
          <CustomTabPanel
            value={tabValue}
            index={Object.keys(FIXED_PROJECT_TABS)[1]}
            className="tabs-detail"
            key={1}
          >
            <TeamMembers />
          </CustomTabPanel>
          <CustomTabPanel
            value={tabValue}
            index={Object.keys(FIXED_PROJECT_TABS)[2]}
            className="tabs-detail"
            key={2}
          >
            <Phase
              projectDetail={projectData}
              setProjectDetails={setProjectData}
            />
          </CustomTabPanel>
        </Box>
      </Box>
    </Box>
  );
};

export default UpdateProject;
