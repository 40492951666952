import React, { useEffect, useRef, useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { trimValue } from "../../utils";

const Input = ({
  label,
  value,
  onChange,
  isUpperCase = false,
  id,
  name,
  type = "text",
  required = true,
  placeholder,
  onBlur = function () { },
  disabled,
  isDays = false,
  isCapitalizeFirstLatter = false,
  isOnlyCapitalFirstLatter = false,
  parseFunction = parseInt,
  zeroAllowed = false,
  isAllowedAutoFloat = false,
  isOnlyAlphabetAllowed = false,
  fixedDecimal = false,
  error=false,
  helperText = "",
  isAllowedAutoFocus = false,
  ...rest
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const quantityInputRef = useRef(null);
  const inputRef = useRef(null);
  const [isShrink, setIsShrink] = useState(!!value ? true : false);

  useEffect(() => {
    if (quantityInputRef?.current) {
      const ignoreScroll = (e) => {
        e.preventDefault();
      };
      quantityInputRef?.current && quantityInputRef?.current.addEventListener("wheel", ignoreScroll);
    }
  }, [quantityInputRef]);

  useEffect(() => {
    setIsShrink(!!value || (type === "number" && value === 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useEffect(() => {
    if (isAllowedAutoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAllowedAutoFocus]);

  const getValue = (type, value) => {
    if (["text"].includes(type)) {
      let Value = value?.toString();
      if (isOnlyAlphabetAllowed) {
        Value = Value?.replace(/[^a-zA-Z-]/g, " ");
      }
      let withSpaceValue = isUpperCase ? trimValue(Value).toUpperCase().replace(/[^a-zA-Z0-9. ]/g, "") : isOnlyCapitalFirstLatter ? trimValue(Value)?.slice(0, 1).toUpperCase() + trimValue(Value)?.slice(1).toLowerCase() : isCapitalizeFirstLatter ? trimValue(Value).split(" ").map(Value => Value?.slice(0, 1)?.toUpperCase() + Value?.slice(1)).join(" ") : trimValue(Value);
      return withSpaceValue.replace("  ", " ");
    }
    if (['number'].includes(type)) {
      if (isNaN(parseFunction(value)))
        return null;
      else {
        if (fixedDecimal) {
          const numericValue = parseFloat(value);
          return isNaN(numericValue) ? null : value?.includes('.') ? (Math.floor(numericValue * 10) / 10) : parseFloat(value); // Format to one decimal
        }
        if (isDays && value > rest?.max) {
          return rest?.max;
        } else if (isDays && value < rest?.min) {
          return null;
        } else {
          // return isAllowedAutoFloat ? value : parseFunction(value) < 0 ? null : parseFunction(value);
          return parseFunction(value) < 0 ? zeroAllowed ? 0 : null : zeroAllowed ? parseFunction(value) > 365 ? 365 : parseFunction(value) : parseFunction(value);
        }
      }
    }
    if (["email"].includes(type)) {
      let Value = value?.toString();
      let withSpaceValue = isUpperCase ? trimValue(Value).toUpperCase().replace(/[^a-zA-Z0-9. ]/g, "") : isOnlyCapitalFirstLatter ? trimValue(Value)?.slice(0, 1).toUpperCase() + trimValue(Value)?.slice(1).toLowerCase() : isCapitalizeFirstLatter ? trimValue(Value).split(" ").map(Value => Value?.slice(0, 1)?.toUpperCase() + Value?.slice(1)).join(" ") : trimValue(Value);
      return withSpaceValue.replace("  ", " ").toLowerCase();
    }

    return value;
  }

  const handleChange = (e) => {
    // let modifiedValue = ["text", "email"].includes(e.target.type) ? trimValue(e.target.value) : ['number'].includes(e.target.type) ? isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value) < 0 ? 0 : parseInt(e.target.value) : e.target.value;
    let modifiedValue = getValue(type, e?.target?.value);
    setIsShrink((!!modifiedValue || (type === "number" && modifiedValue === 0) || zeroAllowed || isAllowedAutoFloat) ? true : false)
    onChange({ ...e, target: { id, name, value: modifiedValue } });
  };

  const handleTogglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  const handleFocus = () => {
    setIsShrink(true)
  }

  const handlekeyDown = (e) => {
    const allowedKeys = ['Backspace', 'Delete', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', '.'];
    if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  }

  const handleBlur = (e) => {
    let modifiedValue = getValue(type, e?.target?.value);
    setIsShrink((!!modifiedValue || (type === "number" && modifiedValue === 0) || zeroAllowed) ? true : false)
    if (zeroAllowed) onChange({ ...e, target: { id, name, value: modifiedValue || 0 } });
    onBlur(e)
  }

  return (
    <TextField
      autoComplete="off"
      ref={quantityInputRef}
      label={label}
      value={type === "number" && (value === null || value === "null" || value === "" || value === undefined) ? "" : value ?? ""}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyDown={isAllowedAutoFloat ? handlekeyDown : null}
      name={name}
      placeholder={placeholder?.length ? `Enter ${placeholder}` : ""}
      required={required}
      type={passwordVisible ? "text" : type}
      disabled={disabled}
      InputLabelProps={{
        shrink: isShrink,
        htmlFor: id,
        id: `label-${id}`,
        name: `label-${id}`,
      }}
      InputProps={{
        name: name,
        id: id,
        endAdornment: type === "password" && (
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePasswordVisibility} edge="end" sx={{ marginRight: '2px' }}>
              {passwordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputRef={inputRef}
      error={error}
      helperText={helperText}
      {...rest}
    />
  );
};

export default Input;