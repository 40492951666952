import { Autocomplete, Box, createFilterOptions, Grid, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Button from '../../../../components/Button';
import { titleCase } from '../../../../utils';

const ChooseWorkTypeActivity = ({ setInputActivityValue, otherWorkTypeActivity, setIsNewActivityOpen, handleModalClose, handleChooseActivity, setAllSelectedActivity, currentWorkTypeData, loading }) => {
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event, newValue) => {
    setSelectedActivity(newValue);
    setAllSelectedActivity(newValue)
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    setInputActivityValue(newInputValue)
  };

  const handleNonExistingOptionClick = () => {
    setInputValue('');
    setIsNewActivityOpen(true);
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.title,
  });

  const handleOptionChange = (e, newValue) => {
    setSelectedActivity((prev) => prev?.find(data => data?.id === newValue?.id) ? prev?.filter(data => data?.id !== newValue?.id) : [...prev, newValue])
    setAllSelectedActivity((prev) => prev?.find(data => data?.id === newValue?.id) ? prev?.filter(data => data?.id !== newValue?.id) : [...prev, newValue])
  }

  return (
    <Box>
      <Grid item py={2} px={3} borderBottom="1px solid #E2E4EC">
        <Typography color={"dark.800"} fontSize={20} fontWeight={500} lineHeight={'32px'} letterSpacing={'0.15px'} variant="h6">Choose Activity</Typography>
      </Grid>
      <Grid item p={3} pb={5}>
        <Typography color={"dark.800"} fontSize={16} fontWeight={400} lineHeight={'26px'} letterSpacing={'0.15px'} variant='body1' mb={4}>{`Choose or Create new activity for the  ${titleCase(currentWorkTypeData?.name)}  work type`}</Typography>
        <Autocomplete
          multiple
          freeSolo
          options={otherWorkTypeActivity}
          disableCloseOnSelect
          getOptionLabel={(option) => typeof option === 'string' ? option : option.title}
          filterOptions={(options, params) => {
            const filtered = filterOptions(options, params);
            if (params.inputValue !== '' && !!currentWorkTypeData?.id) {
              filtered.push({
                inputValue: titleCase(params.inputValue),
                title: `Add "${titleCase(params.inputValue)}"`,
              });
            }
            return filtered;
          }}

          renderOption={(props, option, { selected }) => (
            <li {...props} key={props.key} onClick={option.inputValue ? handleNonExistingOptionClick : undefined} sx={{ py: 0 }}>
              {!option.inputValue && (
                <>
                  <Checkbox style={{ marginRight: 8 }} checked={selected} onChange={() => handleOptionChange(null, option)} />
                  <ListItemText primary={option.title} onClick={() => handleOptionChange(null, option)} sx={{ height: '100%', minHeight: '40px', alignContent: 'center', margin: '0' }} />
                </>
              )}
              {option.inputValue && <ListItemText primary={option.title} />}
            </li>
          )}
          style={{ width: 320 }}
          renderInput={(params) => (
            <TextField {...params} label="Activity" variant="standard" placeholder="Search options" p={0} />
          )}
          value={selectedActivity}
          onInputChange={handleInputChange}
          onChange={handleChange}
          inputValue={inputValue}
        />
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        borderTop="1px solid #E2E4EC"
        gap={2}
        py={2}
        px={3}
      >
        <Button
          variant="contained"
          size="large"
          sx={{
            boxShadow: "0",
            fontSize: "15px",
            textTransform: "none",
            color: "white",
            "&:hover": { boxShadow: "0" },
          }}
          onClick={() => handleChooseActivity()}
          disabled={loading.updateActivity}
          isButtonLoading={loading.updateActivity}
        >
          Choose
        </Button>
        <Button
          variant="text"
          color="secondary"
          size="large"
          sx={{
            boxShadow: "0",
            fontSize: "15px",
            textTransform: "none",
            "&:hover": { boxShadow: "0" },
          }}
          onClick={() => handleModalClose()}
          disabled={loading.updateActivity}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  )
}

export default ChooseWorkTypeActivity;
