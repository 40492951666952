import React, { useState } from 'react';
import { Box, Grid, List, ListItem, Stack, Typography } from '@mui/material';
import Button from '../../components/Button';
import PublicFooter from '../../components/PublicFooter';
import Input from '../../components/Input';
import { useAlert } from '../../hook/useAlert';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../../axios';
import API from '../../axios/api';
import URLS from '../../routes/urls';
import ICONS from '../../constants/icons';
import { usePageTitle } from '../../hook/useTitle';

const ResetPassword = () => {
    const showAlert = useAlert();
    const navigate = useNavigate();
    let { reset_password_token } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { setPageTitle } = usePageTitle();
    const [errors, setErrors] = useState([]);

    const validatePassword = (password) => {
        const errors = [];
        if (password.length < 8) {
            errors.push("Must be at least 8 characters.");
        }
        if (!/[A-Z]/.test(password)) {
            errors.push("Must include at least one uppercase letter.");
        }
        if (!/[a-z]/.test(password)) {
            errors.push("Must include at least one lowercase letter.");
        }
        if (!/[0-9]/.test(password)) {
            errors.push("Must include at least one number.");
        }
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.push("Must include at least one special character like @, #, $, etc.");
        }
        setErrors(errors);
        return errors;
    };

    setPageTitle("Reset Password");

    const formik = useFormik({
        initialValues: {
            password: '',
            confirm_password: '',
            pwdResetToken: reset_password_token,
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .required('Required')
                .test('is-valid-password', 'Password is not strong enough', (value) => {
                    const errors = validatePassword(value);
                    return errors.length === 0;
                }),
            confirm_password: Yup.string()
                .required('Required')
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        }),
        onSubmit: async (values) => {
            try {
                setIsLoading(true);
                const response = await axiosInstance.post(API.resetPassword, values);
                if (response.status === 200) {
                    setIsLoading(false);
                    showAlert(response.data.message);
                    navigate({ pathname: URLS.Login }, {
                        replace: true
                    });
                } else {
                    setIsLoading(false);
                    showAlert(response.data.message, 'error');
                    navigate({ pathname: URLS.Login }, {
                        replace: true
                    });
                }
            } catch (error) {
                console.error('Error:', error);
                if (error?.response?.status) {
                    showAlert(error?.response?.data?.message, 'error');
                    navigate({ pathname: URLS.Login }, {
                        replace: true
                    });
                }
                setIsLoading(false);
            }
        },
    });

    return (
        <Box sx={{ minHeight: '100vh', position: 'relative', background: '#FAFBFF' }}>
            <Box sx={{ position: 'absolute', filter: 'blur(18px)', bottom: 0, top: 0, display: 'flex', alignItems: 'center' }}>
                <i style={{ height: "calc(100vh - 175px)", display: 'flex', alignItems: 'center' }}>{ICONS.LoginBg}</i>
            </Box>
            <Grid
                container
                direction="column"
                justifyContent="flex-end"
                position="relative"
                zIndex="1"
                sx={{ minHeight: '100vh' }}>
                <Grid sx={{ flex: 1, display: 'flex', paddingTop: '50px' }}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <Grid item sx={{ width: '100%' }}>
                            <Box
                                sx={{
                                    maxWidth: { xs: 400, lg: 500 },
                                    width: '100%',
                                    margin: '55px auto',
                                    boxShadow: 1,
                                    borderRadius: '4px',
                                    border: '1px solid #F0F0F0',
                                    background: 'white',
                                }}
                            >
                                <Box sx={{ p: { xs: 2, sm: 3, md: 4, xl: 5 }, width: '100%' }} boxShadow={1}>
                                    <form onSubmit={formik.handleSubmit}>
                                        <Stack spacing={4} alignItems="flex-start">
                                            <Typography variant="h5" fontWeight="bold" color="secondary">Reset Password</Typography>
                                            <Box width="100%">
                                                <Input
                                                    id="password"
                                                    name="password"
                                                    label="Password"
                                                    variant="standard"
                                                    type="password"
                                                    sx={{ width: '100%' }}
                                                    {...formik.getFieldProps('password')}
                                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                                    helperText={formik.touched.password && formik.errors.password === "Required" ? "Required" : null}
                                                    required={false}
                                                />
                                                {typeof errors !== "string" && errors?.length > 0 ?
                                                    <List sx={{ padding: 0, margin: 0, color: "red", fontSize: "12px", mt: "3px" }}>
                                                        {errors?.map((error, index) => (
                                                            <ListItem sx={{ padding: 0, margin: 0 }} key={index}>{error}</ListItem>
                                                        ))}
                                                    </List>
                                                    : null}
                                            </Box>
                                            <Input
                                                id="confirm_password"
                                                name="confirm_password"
                                                label="Confirm Password"
                                                variant="standard"
                                                type="password"
                                                sx={{ width: '100%' }}
                                                {...formik.getFieldProps('confirm_password')}
                                                error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
                                                helperText={formik.touched.confirm_password && formik.errors.confirm_password}
                                                required={false}
                                            />
                                            <Button
                                                type="submit"
                                                sx={{ color: '#ffffff', width: '100%' }}
                                                isButtonLoading={isLoading}
                                            >Reset</Button>
                                        </Stack>
                                    </form>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <PublicFooter />
            </Grid>
        </Box>
    );
};

export default ResetPassword;
